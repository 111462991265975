export const WEB_URL = "https://capios.ai";
export const API_URL = "https://api.capios.ai";
export const REDIRECT_URL = "https://capios.ai/dashboard";
export const SENDER_NAME = "Capios send you a mail.";
export const SENDER_EMAIL = "support@capios.ai";
export const LIMIT_OF_SCREENSHOTS = 30;
export const LIMIT_OF_VIDEOS = 15;
export const localStoragePrefix = 'OCR_';
export const GOOGLE_CLIENT_ID = "72659317015-nenq7l6cibgn7gc25ps6red950mnlnvj.apps.googleusercontent.com";
export const GOOGLE_CLIENT_SECRET = "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";
export const FACEBOOK_APP_ID = "1871784006632033";
export const LINKED_IN_CLIENT_ID = "770lg3auun6mvh";
export const LINKEDIN_SECRET_ID = "46LgxOUjQZmrVp6S";
export const LINKED_IN_REDIRECT_URL = "https://capios.ai/auth/linkedin";
export const TWITTER_CLIENT_ID = "HoOBXK2dGmtUmrnlaEpOtZ1CU";