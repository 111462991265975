import axios from "axios";
import { GET_ALL_PRICINGPLAN, GET_PAYMENT } from "./type";
import { API_URL } from "../../utils/config";

// withCredentials
axios.withCredentials = false;

export const getAllPricingPlan = (userID, tokenAuth) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: tokenAuth
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${API_URL}/pricingPlans/get`, data, config);
      if (res?.data?.status === true) {
        dispatch({
          type: GET_ALL_PRICINGPLAN,
          payload: res?.data,
        });
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const getPayment = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        amount: payload?.amount,
        payment_method: payload?.payment_method,
        customer: payload?.customer_id,
        description: payload?.description,
        return_url: "http://localhost:3001/success",
      });

      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(`${API_URL}/stripe/payment`, data, config);

      if (res?.data?.status === "success") {
        dispatch({
          type: GET_PAYMENT,
          payload: res?.data,
        });
        callBack('success', res?.data)
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};
