import React, { useState } from "react";
import closeIcon from "../../assets/images/quix-close.png";
import {
  manageScreenShots,
  manageStarredScreenShorts,
  renameScreenShot,
} from "../../redux/actions/manageScreenshots";
import { useDispatch } from "react-redux";
import {
  manageStarredVideos,
  manageVideos,
  renameVideos,
} from "../../redux/actions/manageVideos";
import { CapitalizeText, failureMessagePopup, successMessagePopup } from "../../helper/helper";
import { localStoragePrefix } from "../../utils/config";

const RenameCard = ({
  showModal,
  closeModal,
  itemName,
  itemID,
  itemType,
  userID,
}) => {
  let namearr = itemName && itemName.split(".");
  let name = namearr && namearr?.[0];
  let fileExtension = namearr && namearr?.[namearr.length - 1];
  let type = itemType;
  let id = itemID;
  const [renameText, setRenameText] = useState(name);
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");
  const handleRenameTitle = () => {
    const payload = {
      id: id,
      name: renameText.trim() + "." + fileExtension,
      user_id: Number(userID),
      access_token: accessToken,
    };
    if (renameText.trim().length > 0) {
      if (type === "image") {
        // Dispatch action to rename the screenshot
        dispatch(
          renameScreenShot(payload, (type, res) => {
            if (type === "success") {
              successMessagePopup("Success message", "Updated successfully!");
              // Refresh the list of screenshots and starred screenshots
              dispatch(manageScreenShots(userID, accessToken));
              dispatch(manageStarredScreenShorts(userID, accessToken));
            } else if (type === "fail") {
              failureMessagePopup("Error message", res?.message);
            }
          })
        );
      } else {
        dispatch(
          renameVideos(payload, (type, res) => {
            if (type === "success") {
              successMessagePopup("Success message", "Updated successfully!");
              dispatch(manageVideos(userID, accessToken));
              dispatch(manageStarredVideos(userID, accessToken));
            } else if (type === "fail") {
              failureMessagePopup("Error message", res?.message);
            }
          })
        );
      }
    } else {
      failureMessagePopup("Error message", `${CapitalizeText(type)} name cannot be empty!`);
    }
  };
return (
  <>
    <div
      id="quix-dashboard-overlay"
      style={{
        display: !showModal ? "none" : "block",
      }}
    ></div>
    <div id="quix-dashboard-popup">
      <div className="quix-dashboard-popup-inner">
        <div className="quix-dashboard-popup-title">
          <div className="quix-dashboard-popup-title-inner">
            <span>Rename Screenshot</span>
          </div>
        </div>
        <div className="quix-dashboard-popup-body">
          <div className="quix-dashboard-popup-body-inner">
            <input
              id="input-rename-video"
              type="text"
              defaultValue={name}
              onChange={(e) => setRenameText(e.target.value)}
              placeholder="Enter Screenshot Name"
              maxLength={255}
            />
          </div>
        </div>
        <div className="quix-dashboard-popup-footer">
          <div className="quix-dashboard-popup-footer-inner">
            <button
              onClick={() => handleRenameTitle(closeModal(false))}
              className="save-popup"
            >
              Save
            </button>
            <button
              className="cancel-popup"
              onClick={() => closeModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
        <div
          className="quix-dashboard-close"
          onClick={() => closeModal(false)}
        >
          <img src={closeIcon} />
        </div>
      </div>
    </div>
  </>
);
};

export default RenameCard;
