import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { connect } from "react-redux";
import { dashboardOCRreportAdmin } from "../../../redux/actions/admin/dashboardReport";

const ExtansionsReport = ({ ocr_report }) => {
    const ocrReportData = ocr_report?.data || [];
    const ocrReportDataList = ocrReportData.length > 0 ? ocrReportData?.slice(0, 7) : [];
    const firstDateKey = ocrReportDataList.length > 0 ? Object.keys(ocrReportDataList[0])[0] : '';
    const labels = ocrReportDataList.length > 0 ? Object.keys(ocrReportDataList[0]?.[firstDateKey] || {}) : [];
    const dates = ocrReportDataList.map(item => Object.keys(item)[0]);

    const colorMapping = {
        200: {
            backgroundColor: "rgba(20, 90, 50, 0.2)",
            borderColor: "rgb(20, 90, 50)"
        },
        300: {
            backgroundColor: "rgb(255, 165, 0, 0.2)",
            borderColor: "rgb(255, 165, 0)"
        },
        400: {
            backgroundColor: "rgba(192, 57, 43, 0.2)",
            borderColor: "rgb(192, 57, 43)"
        },
        404: {
            backgroundColor: "rgba(255, 87, 34, 0.2)",
            borderColor: "rgb(255, 87, 34)"
        },
        500: {
            backgroundColor: "rgb(211, 47, 47, 0.2)",
            borderColor: "rgb(211, 47, 47)"
        },
        503: {
            backgroundColor: "rgb(211, 211, 211, 0.2)",
            borderColor: "rgb(211, 211, 211)"
        }
    };
    
    const dataSets = labels.map(statusCode => ({
        label: `Status ${statusCode}`,
        data: ocrReportDataList.map(dateData => dateData[Object.keys(dateData)[0]][statusCode]),
        backgroundColor: colorMapping[statusCode]?.backgroundColor,
        borderColor: colorMapping[statusCode]?.borderColor,
        borderWidth: 1.2,
    }));

    const data = {
        labels: dates,
        datasets: dataSets,
    };

    return (
        <div className="extension-reports">
            <div className="card">
                <div className="card-header">
                    <h5 className="m-0 p-2">OCR Report</h5>
                </div>
                <div className="card-body">
                    <Bar
                        data={data}
                        options={{
                            responsive: true,
                            scales: {
                                x: { stacked: false },
                                y: { stacked: false },
                            },
                            plugins: {
                                legend: {
                                    position: 'top',
                                    labels: {
                                        color: '#000',
                                        font: {
                                            size: 13,
                                        },
                                        boxWidth: 15,
                                        boxHeight: 15,
                                        padding: 10,
                                        borderRadius:5,
                                        pointBorderRadius: 5,
                                    }
                                },
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    ocr_report: state?.dashboard_report?.ocr_report,
});

export default connect(mapStateToProps, { dashboardOCRreportAdmin })(ExtansionsReport);
