import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { getPayment } from "../../../redux/actions/managePricingPlan";
import { useDispatch } from "react-redux";

const StripeForm = ({ customerID, paymentAmount, paymentFailed, handlePaymentResponse }) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const elements = useElements();
  const [error, setError] = useState(null);
  const customer_ID = customerID && customerID;
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElements = elements.getElement(CardElement);
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElements,
      });

      if (error) {
        setError(error.message);
      } else {
        const payload = {
          amount: paymentAmount,
          payment_method: paymentMethod?.id,
          customer_id: customer_ID,
          description: "Testing..",
        };
        dispatch(
          getPayment(payload, (type, res) => {
            if(res.result && res.result.transaction_id)
            {
              handlePaymentResponse(res.result.transaction_id);
            }
            else
            {
              paymentFailed(res);
            }
          })
        );
      }
    } catch (error) {
      console.error("Error creating payment method:", error);
      setError("Error creating payment method. Please try again.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button
        className="btn btn-success"
        type="submit"
        disabled={!stripe || !elements}
      >
        Pay
      </button>
      {error && <div>{error}</div>}
    </form>
  );
};
export default StripeForm;
