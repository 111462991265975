import React from "react";
import bxsCheckCircleIcon from "../../../assets/images/home/ocr-bxs-check-circle.png";
import { Link } from "react-router-dom";
import chromeIcon from '../../../assets/images/home/chrome-icon.png';

const DrumrollSection = () => {
  return (
    <div className="container-fluid features_in_beta">
      <div className="container" id="pricing-plans-tab">
        <div className="row">
          <div className="section_title">
            <h2>Drumroll please! <br /><span className="heading-badge-text">It's our beta launch</span></h2>
            <p>
              Ready to make it to the next level? Soon will be launching our
              paid plans, perfect for those looking to scale their use
            </p>
          </div>
          <div className="features_in_beta_plan">
            <span className="features_in_beta_plan_span">
              <h5>Features in Beta</h5>
              <p>3 free announcements / Month</p>
              <ul>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>Unlimited Local Captures &amp; Recording</span>
                </li>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>15 Screen Recordings on Cloud</span>
                </li>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>30 Screenshots on Cloud</span>
                </li>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>Editors for both Images and Videos</span>
                </li>
                <li>
                  <img src={bxsCheckCircleIcon} />
                  <span>Share them infinitely</span>
                </li>
              </ul>
              <Link
                className="btn btn-primary chrome-thisbtn"
                to="https://chromewebstore.google.com/detail/capios-ocr-screenshot-vid/dmfjonjeonmaoehpjaonkjgfdjanapbe"
              >
                <img className="chrome-icon" src={chromeIcon} />
                Add to Chrome
                <span>It’s free!</span>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrumrollSection;
