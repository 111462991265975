import React, { useEffect, useState } from "react";
import closeIcon from "../../assets/images/quix-close.png";
import { connect, useDispatch } from "react-redux";
import { addTags, getAllTags } from "../../redux/actions/manageTags";
import { isEmptyObject } from "jquery";
import Multiselect from "multiselect-react-dropdown";
import { failureMessagePopup, successMessagePopup } from "../../helper/helper";
import { manageVideos, renameVideos } from "../../redux/actions/manageVideos";
import {
    manageScreenShots,
    renameScreenShot,
} from "../../redux/actions/manageScreenshots";
import { localStoragePrefix } from "../../utils/config";

const EditTags = ({ showModal, closeModal, allTags, cardData, cardType }) => {
    const userID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );
    const accessToken = localStorage.getItem(
        localStoragePrefix + "screenGeniusAuthToken"
    );
    const [tags, setTags] = useState("");
    const editCardDetails = cardData && cardData;
    const editCardTags = editCardDetails?.tags
        ? editCardDetails?.tags.split(",")
        : [];
    const [allTagsList, setAllTagsList] = useState([]);
    let tagsList = allTags?.data && allTags?.data;
    const dispatch = useDispatch();
    useEffect(() => {
        if (accessToken && userID) {
            // If both accessToken and userID are available, it dispatches the getAllTags action
            dispatch(getAllTags(userID, accessToken));
        }
    }, [userID, accessToken]);

    useEffect(() => {
        if (tagsList) {
            setAllTagsList(tagsList);
        }
    }, [tagsList]);

    const [selectTags, setSelectTags] = useState([]);
    const tagsOptions =
        allTagsList &&
        allTagsList?.map((item) => {
            return {
                key: item?.id,
                cat: item?.name,
            };
        });
    const selectedTagsOptions = selectTags?.map((item) => {
        return {
            key: item?.key,
            cat: item?.cat,
        };
    });

    const tagsArray = editCardTags
        ?.map(Number)
        .map((item) => {
            const foundTag = allTagsList?.find((el) => el?.id === item);
            return foundTag && { key: foundTag.id, cat: foundTag.name };
        })
        .filter(Boolean);
    useEffect(() => {
        if (isEmptyObject(selectTags)) {
            setSelectTags(tagsArray, [...selectTags]);
        }
    }, [allTagsList]);

    // Handles the addition of a new custom tag.
    const handleAddCustom = () => {
        const payload = {
            user_id: Number(userID),
            access_token: accessToken,
            name: tags,
        };
        // Dispatches the addTags action with the payload and a callback to handle the response.
        dispatch(
            addTags(payload, (type, res) => {
                if (type === "success") {
                    const lastTagId = res?.data?.id;
                    const lastTagName = res?.data?.name;
                    setSelectTags([...selectTags, { key: lastTagId, cat: lastTagName }]);
                    // Fetch the updated list of tags
                    dispatch(getAllTags(userID, accessToken));
                    setTags("");
                } else if (type === "failed") {
                    failureMessagePopup("Error Message", res?.message);
                }
            })
        );
    };
    // Handles the submission of updates for either screenshots or videos.
    const handleSubmit = () => {
        const payload = {
            tags: selectTags?.map((el) => el?.key)?.join(","),
            id: editCardDetails?.id,
            user_id: Number(userID),
            access_token: accessToken,
        };
        if (cardType === "screenshot") {
            // Dispatches an action to rename a screenshot with the provided payload,
            dispatch(
                renameScreenShot(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success message", "Updated successfully!");
                        dispatch(getAllTags(userID, accessToken)); // Refresh tags List
                        dispatch(manageScreenShots(userID, accessToken)); // Refresh Screenshots List
                        closeModal(false);
                    } else if (type === "fail") {
                        failureMessagePopup("Error message", res?.message);
                        closeModal(false);
                    }
                })
            );
        } else if (cardType === "video") {
            // Dispatches an action to rename a video with the provided payload
            dispatch(
                renameVideos(payload, (type, res) => {
                    if (type === "success") {
                        successMessagePopup("Success message", "Updated successfully!");
                        dispatch(getAllTags(userID, accessToken)); // Refresh tags List
                        dispatch(manageVideos(userID, accessToken)); // Refresh Videos List
                        closeModal(false);
                    } else if (type === "fail") {
                        failureMessagePopup("Error message", res?.message);
                        closeModal(false);
                    }
                })
            );
        }
    };

    return (
        <>
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: !showModal ? "none" : "block",
                }}
            ></div>
            <div id="quix-dashboard-popup">
                <div className="quix-dashboard-popup-inner">
                    <div className="quix-dashboard-popup-title">
                        <div className="quix-dashboard-popup-title-inner">
                            <span>Edit Tags</span>
                        </div>
                    </div>
                    <div className="quix-dashboard-popup-body">
                        <div className="quix-dashboard-popup-body-inner">
                            <div className="quix-upload-outer edit-tag-outer text-center">
                                {!isEmptyObject(allTagsList) && (
                                    <>
                                        <div className="text-start mb-2">
                                            <span className="text-start">Select Tags</span>
                                        </div>
                                        <div className="col-md-12 mb-1 text-start tags-item-outer">
                                            <Multiselect
                                                name="tags"
                                                id="tags"
                                                isObject={true}
                                                onSearch={function noRefCheck() { }}
                                                options={tagsOptions}
                                                displayValue={"cat"}
                                                onSelect={(val) => setSelectTags(val)}
                                                placeholder="select tags"
                                                selectedValues={selectedTagsOptions}
                                                defaultValue={selectedTagsOptions}
                                                onRemove={(val) => setSelectTags(val)}
                                            />
                                        </div>
                                    </>
                                )}
                                <div className="col-md-12 mb-1 text-start">
                                    <label className="col-form-label">Add Custom Tag</label>
                                    <div className="quix-add-cutom-tags">
                                        <input
                                            type="text"
                                            name="summary"
                                            className="form-control"
                                            placeholder="add custom tag"
                                            onChange={(e) => setTags(e?.target?.value)}
                                            value={tags}
                                            maxLength={255}
                                        ></input>
                                        <button
                                            onClick={() => handleAddCustom()}
                                            className="btn add-custom-tag-btn"
                                        >
                                            Add custom
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-12 mt-4">
                                    <button
                                        onClick={() => closeModal(false)}
                                        className="btn form-action-button form-cancel-button"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={() => handleSubmit()}
                                        className="btn form-action-button form-save-button"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="quix-dashboard-close"
                        onClick={() => closeModal(false)}
                    >
                        <img src={closeIcon} />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    allTags: state?.tag?.list,
});

export default connect(mapStateToProps, { getAllTags })(EditTags);
