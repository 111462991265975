import {
    LOADING,
    TAG_LIST
} from "../actions/type";

const INITIAL_STATE = {
    list: [{}],
    is_loading: false,
    not_found: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING:
            return { ...state, is_loading: true, not_found: false };
        case TAG_LIST:
            return { ...state, list: action.payload, is_loading: false, not_found: false };
        default:
            return state;
    }
};