import { combineReducers } from "redux";
import manageScreenShorts from "./manageScreenShots";
import manageVideos from "./manageVideos";
import authentication from "./authentication";
import manageMembership from "./manageMembership";
import managePricingPlan from "./managePricingPlan";
import adminMembership from "./admin/membership";
import adminPricingplans from "./admin/pricingPlans";
import manageTags from "./manageTags";
import adminTags from "./admin/tags";
import adminAuth from "./admin/authentication";
import adminUsers from "./admin/users";
import adminVideos from "./admin/videos";
import adminScreenshot from "./admin/screenshots";
import adminStatistics from "./admin/statistics";
import manageSavedSnippets from "./manageSavedSnippets";
import extension from "./admin/extension";
import generalSettings from "./admin/generalSettings";
import dashboardReport from "./admin/dashboardReport";

const rootReducers = combineReducers({
  screen_shorts: manageScreenShorts,
  videos: manageVideos,
  auth: authentication,
  membership: manageMembership,
  pricingplan: managePricingPlan,
  users: adminUsers,
  saved_snippets: manageSavedSnippets,
  admin_screenshots: adminScreenshot,
  admin_videos: adminVideos,
  admin_membership: adminMembership,
  admin_pricingplan: adminPricingplans,
  tag: manageTags,
  admin_tags: adminTags,
  admin_auth: adminAuth,
  admin_statistics: adminStatistics,
  extension: extension,
  general_setting: generalSettings,
  dashboard_report: dashboardReport
});

export default rootReducers;
