import React, { useEffect, useState } from "react";
import AdminLayout from "../../layout/adminLayout";
import { connect, useDispatch } from "react-redux";
import {
  getGeneralSettingDetailsAdmin,
  updateGeneralSettingsAdmin,
} from "../../../redux/actions/admin/generalSettings";
import { localStoragePrefix } from "../../../utils/config";
import {
  errorMessageModal,
  successMessageModal,
} from "../../components/Modal/MessagesModal";

const ManageGeneralSettings = ({ generalSettinDetails }) => {
  const dispatch = useDispatch();
  const adminID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );

  useEffect(() => {
    dispatch(getGeneralSettingDetailsAdmin(Number(adminID)));
  }, []);

  const initialValues = {
    facebook: {
      facebook_client_id: "",
      facebook_client_secret: "",
      facebook_callback: "",
    },
    google: {
      google_client_id: "",
      google_client_secret: "",
      google_client_callback: "",
    },
    linkedin: {
      linkedin_client_id: "",
      linkedin_secret_key: "",
      linkedin_callback: "",
    },
    mail: {
      web_mail_user: "",
      web_mail_password: "",
      mail_url: "",
      mail_header: "",
      mail_name: "",
      image_url: "",
    },
    notion: {
      notion_api_key: "",
    },
    stripe: {
      stripe_api_key: "",
      stripe_api_secret: "",
    },
    twitter: {
      twitter_client_id: "",
      twitter_client_secret: "",
      twitter_callback: ""
    },
    bard: {
      bard_api_key: "",
    },
    chat_gpt: {
      chat_gpt_api_key: "",
    },
  };

  const [formData, setFormData] = useState(initialValues);
  const generalSettingData =
    generalSettinDetails?.data && generalSettinDetails?.data?.[0];
  const bardDetails =
    generalSettingData?.bard && JSON.parse(generalSettingData?.bard);
  const chatGptDetails =
    generalSettingData?.chatGpt && JSON.parse(generalSettingData?.chatGpt);
  const facebookDetails =
    generalSettingData?.facebook && JSON.parse(generalSettingData?.facebook);
  const googleDetails =
    generalSettingData?.google && JSON.parse(generalSettingData?.google);
  const linkedinDetails =
    generalSettingData?.linkedin && JSON.parse(generalSettingData?.linkedin);
  const mailDetails =
    generalSettingData?.mail && JSON.parse(generalSettingData?.mail);
  const notionDetails =
    generalSettingData?.notion && JSON.parse(generalSettingData?.notion);
  const stripeDetails =
    generalSettingData?.stripe && JSON.parse(generalSettingData?.stripe);
  const twitterDetails =
    generalSettingData?.twitter && JSON.parse(generalSettingData?.twitter);

  const setDefaultSettings = () => {
    setFormData({
      facebook: {
        facebook_client_id: facebookDetails?.facebook_client_id || "",
        facebook_client_secret: facebookDetails?.facebook_client_secret || "",
        facebook_callback: facebookDetails?.facebook_callback || "",
      },
      google: {
        google_client_id: googleDetails?.google_client_id || "",
        google_client_secret: googleDetails?.google_client_secret || "",
        google_client_callback: googleDetails?.google_client_callback,
      },
      linkedin: {
        linkedin_client_id: linkedinDetails?.linkedin_client_id || "",
        linkedin_secret_key: linkedinDetails?.linkedin_secret_key || "",
        linkedin_callback: linkedinDetails?.linkedin_callback || "",
      },
      mail: {
        web_mail_user: mailDetails?.web_mail_user || "",
        web_mail_password: mailDetails?.web_mail_password || "",
        mail_url: mailDetails?.mail_url || "",
        mail_header: mailDetails?.mail_header || "",
        mail_name: mailDetails?.mail_name || "",
        image_url: mailDetails?.image_url || "",
      },
      notion: {
        notion_api_key: notionDetails?.notion_api_key || "",
      },
      stripe: {
        stripe_api_key: stripeDetails?.stripe_api_key || "",
        stripe_api_secret: stripeDetails?.stripe_api_secret || "",
      },
      twitter: {
        twitter_client_id: twitterDetails?.twitter_client_id || "",
        twitter_client_secret: twitterDetails?.twitter_client_secret || "",
        twitter_callback: twitterDetails?.twitter_callback || ""
      },
      bard: {
        bard_api_key: bardDetails?.bard_api_key || "",
      },
      chat_gpt: {
        chat_gpt_api_key: chatGptDetails?.chat_gpt_api_key || "",
      },
    });
  };

  useEffect(() => {
    setDefaultSettings();
  }, [generalSettingData]);

  const handleSubmit = () => {
    const payload = {
      ...formData,
      admin_id: Number(adminID),
    };
    dispatch(
      updateGeneralSettingsAdmin(payload, (type, res) => {
        if (type === "success") {
          successMessageModal(
            "General Settings Updated Successfully",
            "General Settings has been Updated Successfully"
          );
        } else if (type === "failed") {
          errorMessageModal("General Settings Updated", res?.message);
        }
      })
    );
  };

  const handleCancel = () => {
    setDefaultSettings();
  };

  return (
    <div className="manage-general-settings">
      <AdminLayout pageTitle={"general-settings"}>
        <div className="extension-settings-content settings-content">
          <div className="table-management-section d-flex justify-content-between col-md-10">
            <div className="management-header mb-5">
              <h4>General Settings</h4>
              <p>
                You can manage basic settings like video or screenshot
                resolutions & video <br />
                recording delay settings.
              </p>
            </div>
            <div className="ext-setting-btns">
              <button
                className="btn theme-blue-btn me-2"
                onClick={() => handleSubmit()}
              >
                Save & Continue
              </button>
              <button
                className="btn theme-cancel-btn"
                onClick={() => handleCancel()}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="general-settings-outer">
            <div className="card-column">
              {/* google card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header">
                  <h5 className="title">Google</h5>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="googleClientID"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Google Client Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="googleClientID"
                          id="googleClientID"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              google: {
                                ...formData?.google,
                                google_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.google?.google_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="googleClientSecret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Google Client Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="googleClientSecret"
                          id="googleClientSecret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              google: {
                                ...formData?.google,
                                google_client_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.google?.google_client_secret}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="googleClientCallBack"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Google Client CallBack
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="googleClientCallBack"
                          id="googleClientCallBack"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              google: {
                                ...formData?.google,
                                google_client_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.google?.google_client_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* facebook card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header">
                  <h5 className="title">Facebook</h5>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="facebook_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Facebook Client Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="facebook_client_id"
                          id="facebook_client_id"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              facebook: {
                                ...formData?.facebook,
                                facebook_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.facebook?.facebook_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="facebook_client_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Facebook Client Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="facebook_client_secret"
                          id="facebook_client_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              facebook: {
                                ...formData?.facebook,
                                facebook_client_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.facebook?.facebook_client_secret}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="facebook_callback"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Facebook Callback
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="facebook_callback"
                          id="facebook_callback"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              facebook: {
                                ...formData?.facebook,
                                facebook_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.facebook?.facebook_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* linkedin card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header">
                  <h5 className="title">Linkedin</h5>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="linkedin_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Linkedin Client Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="linkedin_client_id"
                          id="linkedin_client_id"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              linkedin: {
                                ...formData?.linkedin,
                                linkedin_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.linkedin?.linkedin_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="linkedin_secret_key"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Linkedin Secret Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="linkedin_secret_key"
                          id="linkedin_secret_key"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              linkedin: {
                                ...formData?.linkedin,
                                linkedin_secret_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.linkedin?.linkedin_secret_key}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="linkedin_callback"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Linkedin Callback
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="linkedin_callback"
                          id="linkedin_callback"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              linkedin: {
                                ...formData?.linkedin,
                                linkedin_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.linkedin?.linkedin_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* chatGtp card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header">
                  <h5 className="title">Chat GPT</h5>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="chat_gpt_api_key"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Chat GPT Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="chat_gpt_api_key"
                          id="chat_gpt_api_key"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              chat_gpt: {
                                ...formData?.chat_gpt,
                                chat_gpt_api_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.chat_gpt?.chat_gpt_api_key}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* bard card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header">
                  <h5 className="title">Bard</h5>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="linbard_api_keykedin_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Bard Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="bard_api_key"
                          id="bard_api_key"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bard: {
                                ...formData?.bard,
                                bard_api_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.bard?.bard_api_key}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-column">
              {/* mail card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header">
                  <h5 className="title">Mail</h5>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="web_mail_user"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Mail User Email
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="web_mail_user"
                          id="web_mail_user"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                web_mail_user: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.web_mail_user}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="twitter_client_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Mail Password
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="web_mail_password"
                          id="web_mail_password"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                web_mail_password: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.web_mail_password}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="mail_url"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Mail Url
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="mail_url"
                          id="mail_url"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                mail_url: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.mail_url}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="mail_header"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Mail Header
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="mail_header"
                          id="mail_header"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                mail_header: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.mail_header}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="mail_name"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Mail Name
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="mail_name"
                          id="mail_name"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                mail_name: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.mail_name}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="image_url"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Image Url
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="image_url"
                          id="image_url"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              mail: {
                                ...formData?.mail,
                                image_url: e.target.value,
                              },
                            })
                          }
                          value={formData?.mail?.image_url}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* stripe card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header">
                  <h5 className="title">Stripe</h5>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="stripeApikey"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Stripe Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="stripeApikey"
                          id="stripeApikey"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              stripe: {
                                ...formData?.stripe,
                                stripe_api_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.stripe?.stripe_api_key}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="stripe_api_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Stripe Api Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="stripe_api_secret"
                          id="stripe_api_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              stripe: {
                                ...formData?.stripe,
                                stripe_api_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.stripe?.stripe_api_secret}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* twitter card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header">
                  <h5 className="title">Twitter</h5>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="twitter_client_id"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Twitter Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="twitter_client_id"
                          id="twitter_client_id"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              twitter: {
                                ...formData?.twitter,
                                twitter_client_id: e.target.value,
                              },
                            })
                          }
                          value={formData?.twitter?.twitter_client_id}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="twitter_client_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Twitter Api Secret
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="twitter_client_secret"
                          id="twitter_client_secret"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              twitter: {
                                ...formData?.twitter,
                                twitter_client_secret: e.target.value,
                              },
                            })
                          }
                          value={formData?.twitter?.twitter_client_secret}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="twitter_client_secret"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Twitter Callback
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="twitter_callback"
                          id="twitter_callback"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              twitter: {
                                ...formData?.twitter,
                                twitter_callback: e.target.value,
                              },
                            })
                          }
                          value={formData?.twitter?.twitter_callback}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* notion card */}
              <div className="general-settings-card settings-content-card card mb-3">
                <div className="card-header">
                  <h5 className="title">Notion</h5>
                </div>
                <div className="card-body card-fileds">
                  <div className="row d-block">
                    <div className="col-md-12 mb-3">
                      <label
                        htmlFor="notion_api_key"
                        className="col-sm-12 col-form-label text-start"
                      >
                        Notion Api Key
                      </label>
                      <div className="col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="notion_api_key"
                          id="notion_api_key"
                          maxLength={255}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              notion: {
                                ...formData?.notion,
                                notion_api_key: e.target.value,
                              },
                            })
                          }
                          value={formData?.notion?.notion_api_key}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </div>
  );
};

// generalSettinDetails

const mapStateToProps = (state) => ({
  generalSettinDetails: state?.general_setting?.details,
});

// export default ManageGeneralSettings;

export default connect(mapStateToProps, { getGeneralSettingDetailsAdmin })(
  ManageGeneralSettings
);
