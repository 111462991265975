import React from "react";
import {
  Elements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const Stripe = ({children}) => {
  const stripePromise = loadStripe('pk_test_51MZbNXKGSi84BedGcQmKP0CMF89QRIsuFvIHMxMF6xrG3lpiBtyjO0EZXUnFu6KoJT18QhyXAVEHn0cu3oD2lRU500TpbBVrfL');
  const options = {
    mode: 'subscription',
    amount: 1099,
    currency: 'usd',
    appearance: {
      /*...*/
    },
  };
  return <>
    <Elements stripe={stripePromise} options={options}>
      {children}
  </Elements>
  </>;
};

export default Stripe;
