import React from "react";
import closeIcon from "../../../assets/images/quix-close.png";
import loaderIcon from "../../assets/images/light-loader.gif";

const ConfirmationModal = ({
  isShowModal,
  closeModal,
  title,
  icon,
  messageTitle,
  description,
  callBack,
  btnText,
  isLoading,
  isDisabled
}) => {
  return (
    <div>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !isShowModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup" className="quix-edit-user admin-confirm-popup">
        <div className="quix-dashboard-popup-inner quix-edit-user-outer">
          <div className="quix-dashboard-popup-title">
            <div className="quix-dashboard-popup-title-inner">
              <span>{title}</span>
            </div>
            {icon && (
              <div className="confirm-box-icon">
                <img src={icon} />
              </div>
            )}
          </div>
          <div className="quix-dashboard-popup-body">
            <p>{messageTitle}</p>
            <span>{description}</span>
            <div className="quix-form-filed d-flex justify-content-center mt-4">
              <button
                className="btn quix-cancel-edit"
                onClick={() => closeModal(false)}
              >
                Cancel
              </button>
              <button
                className="btn edit-user-detail-btn"
                onClick={() => callBack(true)}
                disabled={isDisabled}
              >
                {btnText}
              </button>
              <img
                style={{ display: !isLoading ? "none" : "block" }}
                className="btn-loader-icons"
                src={loaderIcon}
              />
            </div>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
