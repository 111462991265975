import React from 'react';

const TopBar = () => {
    return (
        <div id="quix-important-message" style={{ background: "#2F51B5" }}>
            <span className="quix-important-message">
                Hi Capios user! We have just updated the tool to Version 3.1.1
                for better experience and usability. Thanks for your support.
            </span>
        </div>
    );
};

export default TopBar;