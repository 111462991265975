import React, { useEffect, useRef, useState } from "react";
import newItemIcon from "../../assets/images/quix-dash-add-icon.png";
import closeIcon from "../../assets/images/quix-close.png";
import CustomSelect from "../CustomSelect/CustomSelect";
import {
  manageScreenShots,
  manageStarredScreenShorts,
  shortingScreenShot,
  shortingStarredScreenShot,
  updateStarredScreenShot,
  multiRemoveScreenShot,
  manageSearchScreenShots,
  manageSearchStarredScreenShots,
} from "../../redux/actions/manageScreenshots";
import { connect, useDispatch } from "react-redux";
import {
  manageSearchStarredVideos,
  manageSearchVideos,
  manageStarredVideos,
  manageVideos,
  multiRemoveVideo,
  shortingStarredVideos,
  shortingVideos,
  updateStarredVideo,
} from "../../redux/actions/manageVideos";
import { manageUserProgress } from "../../redux/actions/authentication";
import FileUploader from "../Modals/FileUploader";
import {
  failureMessagePopup,
  sq_profile_progress,
  successMessagePopup,
} from "../../helper/helper";
import AvailableStorageBar from "../Modals/AvailableStorage/AvailableStorageBar";
import { useLocation } from "react-router-dom";
import { localStoragePrefix } from "../../utils/config";
import {
  getAllSavedSnippets,
  multiRemoveSnippets,
} from "../../redux/actions/manageSavedSnippets";

const TabsLayout = ({
  selectedScreenshotIDs,
  selectedVideoIDs,
  selectedSnippetIDs,
  children,
  isActivePage,
  progressDetails,
  isActiveTab,
}) => {
  const [showNewItemsPopup, setShowNewItemsPopup] = useState(false);
  const dispatch = useDispatch();
  const tabRef = useRef();
  const [getActiveTab, setActiveTab] = useState("quix-tab-images");
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const accessToken = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );
  const { hash } = useLocation();

  useEffect(() => {
    setActiveTab(
      tabRef.current.querySelector(".quix-tab.active").getAttribute("id")
    );
    isActiveTab(
      tabRef.current.querySelector(".quix-tab.active").getAttribute("id")
    );
    handleClearSearch();
  }, [hash]);

  // shorted items
  const handleSortSelectedItems = (action) => {
    const type = action?.toLowerCase();
    if (isActivePage === "starred") {
      if (getActiveTab === "quix-tab-videos") {
        dispatch(shortingStarredVideos(userID, accessToken, type));
      } else {
        dispatch(shortingStarredScreenShot(userID, accessToken, type));
      }
    } else {
      if (getActiveTab === "quix-tab-videos") {
        dispatch(shortingVideos(userID, accessToken, type));
      } else if (getActiveTab === "quix-tab-save-snippets") {
        const payload = {
          user_id: Number(userID),
          access_token: accessToken,
          sort: type,
        };
        dispatch(getAllSavedSnippets(payload));
      } else {
        dispatch(shortingScreenShot(userID, accessToken, type));
      }
    }
  };

  const handleActionSelectedItems = (action) => {
    const starredType = isActivePage === "starred" ? 0 : 1;
    if (getActiveTab === "quix-tab-images") {
      if (action === "Starred" || action === "Remove Starred") {
        const payload = {
          status: starredType,
          id: selectedScreenshotIDs,
          access_token: accessToken,
          user_id: Number(userID),
        };
        if (selectedScreenshotIDs?.length > 0) {
          dispatch(
            updateStarredScreenShot(payload, (type, res) => {
              if (type === "success") {
                dispatch(manageStarredScreenShorts(userID, accessToken));
                dispatch(manageScreenShots(userID, accessToken));
              } else if (type === "fail") {
                failureMessagePopup("Error message", res?.message);
              }
            })
          );
        }
      }
      if (action === "Delete") {
        const payload = {
          id: selectedScreenshotIDs,
          access_token: accessToken,
          user_id: userID,
        };
        if (selectedScreenshotIDs?.length > 0) {
          dispatch(
            multiRemoveScreenShot(payload, (type, res) => {
              if (type === "success") {
                successMessagePopup("Success message", "Removed successfully!");
                dispatch(manageStarredScreenShorts(userID, accessToken));
                dispatch(manageScreenShots(userID, accessToken));
                dispatch(
                  manageUserProgress(userID, accessToken, function () { })
                );
              } else if (type === "fail") {
                failureMessagePopup("Error message", res?.message);
              }
            })
          );
        }
      }
    }
    if (getActiveTab === "quix-tab-videos") {
      if (action === "Starred" || action === "Remove Starred") {
        const payload = {
          status: starredType,
          id: selectedVideoIDs,
          access_token: accessToken,
          user_id: userID,
        };
        if (selectedVideoIDs?.length > 0) {
          dispatch(
            updateStarredVideo(payload, (type, res) => {
              if (type === "success") {
                dispatch(manageStarredVideos(userID, accessToken));
                dispatch(manageVideos(userID, accessToken));
              } else if (type === "fail") {
                failureMessagePopup("Error message", res?.message);
              }
            })
          );
        }
      }
      if (action === "Delete") {
        const payload = {
          id: selectedVideoIDs,
          access_token: accessToken,
          user_id: userID,
        };
        if (selectedVideoIDs?.length > 0) {
          dispatch(
            multiRemoveVideo(payload, (type, res) => {
              if (type === "success") {
                successMessagePopup("Success message", "Removed successfully!");
                dispatch(manageStarredVideos(userID, accessToken));
                dispatch(manageVideos(userID, accessToken));
                dispatch(
                  manageUserProgress(userID, accessToken, function () { })
                );
              } else if (type === "fail") {
                failureMessagePopup("Error message", res?.message);
              }
            })
          );
        }
      }
    }
    if (getActiveTab === "quix-tab-save-snippets") {
      if (action === "Delete") {
        const payload = {
          ids: selectedSnippetIDs,
          user_id: Number(userID),
          access_token: accessToken,
        };
        if (selectedSnippetIDs?.length > 0) {
          dispatch(
            multiRemoveSnippets(payload, (type, res) => {
              if (type === "success") {
                successMessagePopup("Success message", "Removed successfully!");
                const payload = {
                  user_id: Number(userID),
                  access_token: accessToken,
                };
                dispatch(getAllSavedSnippets(payload));
              } else if (type === "failed") {
                failureMessagePopup("Error message", res?.message);
              }
            })
          );
        }
      }
    }
  };

  const [progressStatus, setProgressStatus] = useState();
  useEffect(() => {
    let { screenshots, videos } = progressDetails;
    let userProgressDetails =
      screenshots || videos
        ? sq_profile_progress(screenshots, videos)
        : progressStatus;
    setProgressStatus(userProgressDetails);
  }, [progressDetails]);
  // show storage modal
  const [showAvailbleStorageModal, setShowAvailbleStorageModal] =
    useState(true);
  useEffect(() => {
    setShowAvailbleStorageModal(true);
  }, [getActiveTab]);

  // search
  const [inputSearch, setInputSearch] = useState("");

  const handleSubmitSearch = () => {
    if (inputSearch) {
      const payload = {
        user_id: Number(userID),
        access_token: accessToken,
        search_text: inputSearch,
      };
      if (isActivePage === "starred") {
        if (getActiveTab === "quix-tab-videos") {
          dispatch(manageSearchStarredVideos(payload));
        } else {
          dispatch(manageSearchStarredScreenShots(payload));
        }
      } else {
        if (getActiveTab === "quix-tab-videos") {
          dispatch(manageSearchVideos(payload));
        } else {
          dispatch(manageSearchScreenShots(payload));
        }
      }
    }
  };

  const handleClearSearch = () => {
    setInputSearch("");
    if (document.querySelector(".quix-search-box input")) {
      document.querySelector(".quix-search-box input").value = "";
    }
    const payload = {
      user_id: Number(userID),
      access_token: accessToken,
      search_text: "",
    };
    if (isActivePage === "starred") {
      if (getActiveTab === "quix-tab-videos") {
        dispatch(manageStarredVideos(userID, accessToken));
      } else {
        dispatch(manageStarredScreenShorts(userID, accessToken));
        dispatch(manageScreenShots(userID, accessToken));
      }
    } else {
      if (getActiveTab === "quix-tab-videos") {
        dispatch(manageVideos(userID, accessToken));
      } else {
        dispatch(manageScreenShots(userID, accessToken));
      }
    }
  };

  return (
    <>
      {/* Tabs actions navigations */}
      <div className="quix-dashboard-content-actions">
        <div className="quix-dashboard-content-actions-inner">
          {getActiveTab === "quix-tab-save-snippets" ? (
            <>
              <CustomSelect
                dropDownTitle={"Choose Action"}
                dropDownOptions={["Delete"]}
                handleActions={handleActionSelectedItems}
              />
              <CustomSelect
                dropDownTitle={"Sort By"}
                dropDownOptions={["Type", "Date"]}
                handleActions={handleSortSelectedItems}
              />
            </>
          ) : (
            <>
              <div className="quix-search-box d-flex">
                <input
                  className="form-control rounded-0"
                  onChange={(e) => setInputSearch(e.target.value)}
                  type="text"
                  name="search"
                  value={inputSearch}
                  placeholder="search card"
                  maxLength={255}
                />
                <button
                  className="quix-search-btn"
                  onClick={() => handleSubmitSearch()}
                >
                  Search
                </button>
                <button
                  className="quix-search-cancelbtn"
                  onClick={() => handleClearSearch()}
                >
                  Clear
                </button>
              </div>
              <div
                className="quix-dashboard-new-item-button dashboard-button"
                onClick={() => setShowNewItemsPopup(!showNewItemsPopup)}
              >
                <img src={newItemIcon} />
                <span>New Item</span>
              </div>
              <CustomSelect
                dropDownTitle={"Choose Action"}
                dropDownOptions={[
                  isActivePage === "starred" ? "Remove Starred" : "Starred",
                  "Delete",
                ]}
                handleActions={handleActionSelectedItems}
              />
              <CustomSelect
                dropDownTitle={"Sort By"}
                dropDownOptions={["Name", "Size", "Date"]}
                handleActions={handleSortSelectedItems}
              />
            </>
          )}
        </div>
      </div>
      {/* Handle Dashboard Popup */}
      {showNewItemsPopup && (
        <FileUploader
          showModal={showNewItemsPopup}
          closeModal={setShowNewItemsPopup}
          userID={userID}
          progressDetails={progressDetails}
        />
      )}
      {/* Tabs navigations  */}
      <div id="quix-dashboard-content-tabs">
        <div className="quix-dashboard-content-tabs-inner">
          <ul
            id="quix-dashboard-content-tabs"
            className="nav"
            ref={tabRef}
            role="tablist"
          >
            <a href="#screenshots" className="nav-item" role="presentation">
              <div
                className={`quix-tab quix-tab-images ${hash !== "#videos" && hash !== "#ocr-snippets" ? "active" : ""
                  }`}
                id="quix-tab-images"
                data-bs-toggle="tab"
                data-bs-target="#screen-genius-images"
                type="button"
                role="tab"
                aria-controls="screen-genius-images"
                aria-selected="true"
              >
                My Screenshots
              </div>
            </a>
            <a href="#videos" className="nav-item" role="presentation">
              <div
                className={`quix-tab quix-tab-videos ${hash === "#videos" ? "active" : ""
                  }`}
                style={{ borderRight: isActivePage === 'starred' ? `none` : '1px solid #ffff' }}
                id="quix-tab-videos"
                data-bs-toggle="tab"
                data-bs-target="#screen-genius-videos"
                type="button"
                role="tab"
                aria-controls="screen-genius-videos"
                aria-selected="false"
              >
                My Screen Recordings
              </div>
            </a>
            {isActivePage !== 'starred' && (
              <a href="#ocr-snippets" className="nav-item" role="presentation">
                <div
                  className={`quix-tab quix-tab-save-snippets ${hash === "#ocr-snippets" ? "active" : ""
                    }`}
                  id="quix-tab-save-snippets"
                  data-bs-toggle="tab"
                  data-bs-target="#ocr-save-snippets"
                  type="button"
                  role="tab"
                  aria-controls="ocr-save-snippets"
                  aria-selected="true"
                >
                  OCR Snippets
                </div>
              </a>
            )}
          </ul>
        </div>
      </div>
      {/* Tabs content */}
      <div className="tab-content" id="quix-dashboard-content-tabs-content">
        {isActivePage === "dashboard" &&
          showAvailbleStorageModal &&
          getActiveTab === "quix-tab-images" && (
            <AvailableStorageBar
              showModal={showAvailbleStorageModal}
              closeModal={setShowAvailbleStorageModal}
              typeOfItems={"Image"}
              addedItems={progressDetails?.screenshots}
              progress={progressStatus}
            />
          )}
        {isActivePage === "dashboard" &&
          showAvailbleStorageModal &&
          getActiveTab === "quix-tab-videos" && (
            <AvailableStorageBar
              showModal={showAvailbleStorageModal}
              closeModal={setShowAvailbleStorageModal}
              typeOfItems={"Video"}
              addedItems={progressDetails?.videos}
              progress={progressStatus}
            />
          )}
        {children}
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  progressDetails: state?.auth?.progress,
});

export default connect(mapStateToProps, { manageUserProgress })(TabsLayout);
