import React from "react";
import { BrowserRouter } from "react-router-dom";
import CustomRoutes from "./routes/customeRoutes";
import { Provider } from "react-redux";
import store from "./store.js";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <CustomRoutes />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
