import axios from "axios";
import {
  ADMIN_LOADING_TAGS,
  ADMIN_TAG_LIST,
  NOT_FOUND_ADMIN_TAG,
  ADMIN_LOADING_USERS_TAGS,
  ADMIN_USERS_TAG_LIST,
  NOT_FOUND_ADMIN_USERS_TAG,
} from "../../actions/type";
import { API_URL } from "../../../utils/config";

export const getAllTagsListAdmin = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_LOADING_TAGS,
      });
      let data = JSON.stringify({
        admin_id: id,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/admin/tag?sort=admin`,
        data,
        config
      );
      if (res?.data?.status) {
        if (res?.data?.data?.length <= 0) {
          dispatch({
            type: NOT_FOUND_ADMIN_TAG,
          });
        } else {
          dispatch({
            type: ADMIN_TAG_LIST,
            payload: res?.data,
          });
        }
      }
    } catch (error) {
      console.log("---error---");
    }
  };
};

export const getAllTagsListAdminUsers = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_LOADING_USERS_TAGS,
      });
      let data = JSON.stringify({
        admin_id: id,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/admin/tag?sort=user`,
        data,
        config
      );
      if (res?.data?.status) {
        if (res?.data?.data?.length <= 0) {
          dispatch({
            type: NOT_FOUND_ADMIN_USERS_TAG,
          });
        } else {
          dispatch({
            type: ADMIN_USERS_TAG_LIST,
            payload: res?.data,
          });
        }
      }
    } catch (error) {
      console.log("---error---");
    }
  };
};

// Delete admin tag
export const deleteTagAdmin = (payload, callBack) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_LOADING_TAGS,
      });
      let data = JSON.stringify({
        admin_id: payload?.admin_id,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      const res = await axios.delete(
        `${API_URL}/admin/tag/delete/${payload?.id}`,
        config
      );
      if (res?.data?.status === true) {
        callBack("success", res?.data);
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("fail", message);
      console.log("---error---", error);
    }
  };
};

// edit admin tag
export const editTagAdmin = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: payload?.user_id,
        admin_id: payload?.admin_id,
        name: payload?.name,
        status: payload?.status,
        id: payload?.id,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(`${API_URL}/admin/tag/edit`, data, config);
      if (res?.data?.status === true) {
        callBack("success", res?.data);
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("failed", message);
      console.log("---error---", error);
    }
  };
};

//admin add tag
export const addTagAdmin = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        admin_id: payload?.admin_id,
        name: payload?.name,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(`${API_URL}/admin/tag/add`, data, config);
      if (res?.data?.status === true) {
        callBack("success", res?.data);
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("failed", message);
      console.log("---error---", error);
    }
  };
};

// search tags by name, user name and email
export const SearchTagsAdmin = (payload) => {
  return async (dispatch) => {
    // try {
    //   let data = JSON.stringify({
    //     admin_id: payload?.admin_id,
    //   });

    //   let config = {
    //     maxBodyLength: Infinity,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   };

    //   const res = await axios.post(
    //     `${API_URL}/admin/tag?search=${payload?.text}`,
    //     data,
    //     config
    //   );
    //   if (res?.data?.status === true) {
    //     dispatch({
    //       type: ADMIN_TAG_LIST,
    //       payload: res?.data,
    //     });
    //   }
    // } catch (error) {
    //   const message = error?.response?.data;
    //   // callBack("fail", message);
    //   console.log("---error---", error);
    // }

    try {
      let data = JSON.stringify({
        admin_id: payload?.admin_id,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      let reqURL = "";
      if (payload?.query === "admin") {
        reqURL += `search?type=admin&search=${payload?.text}`;
      } else {
        reqURL += `search?type=user&search=${payload?.text}`;
      }
      const res = await axios.post(
        `${API_URL}/admin/tag/${reqURL}`,
        data,
        config
      );
      if (res?.data?.status === true) {
        if (payload?.query === "admin") {
          if (res?.data?.data?.length <= 0) {
            dispatch({
              type: NOT_FOUND_ADMIN_TAG,
            });
          } else {
            dispatch({
              type: ADMIN_TAG_LIST,
              payload: res?.data,
            });
          }
        } else {
          if (res?.data?.data?.length <= 0) {
            dispatch({
              type: NOT_FOUND_ADMIN_USERS_TAG,
            });
          } else {
            dispatch({
              type: ADMIN_USERS_TAG_LIST,
              payload: res?.data,
            });
          }
        }
      }
    } catch (error) {
      const message = error?.response?.data;
      // callBack("fail", message);
      console.log("---error---", error);
    }
  };
};
