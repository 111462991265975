import { ADMIN_LOGIN, LOADING_USERS } from "../../actions/type";

const INITIAL_STATE = {
    admin_user: [{}],
    is_loading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING_USERS:
            return { ...state, is_loading: true };
        case ADMIN_LOGIN:
            return { ...state, admin_user: action.payload, is_loading: false };
        default:
            return state;
    }
};
