import React from "react";
import closeIcon from "../../../assets/images/quix-close.png";
import PricingPlans from "./PricingPlans";

const PricingModal = ({ showModal, closeModal }) => {
  return (
    <div>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup" className="quix-pricing-popup">
        <div className="quix-dashboard-popup-inner">
          <div className="quix-dashboard-popup-title">
            <div className="quix-dashboard-popup-title-inner">
              <span>Choose Your Plan</span>
              <p>Lorem ipsum dolor sit amet consectetur. Tortor non praesent nibh <br /> mauris quis tellus in nulla.</p>
            </div>
          </div>
          <div className="quix-dashboard-popup-body">
            <div className="quix-dashboard-popup-body-inner">
              <PricingPlans />
            </div>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;
