import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { checkEmpty } from "../../../../helper/helper";
import loaderIcon from "../../../../assets/images/light-loader.gif";
import { localStoragePrefix } from "../../../../utils/config";
import {
  editTagAdmin,
  getAllTagsListAdmin,
  getAllTagsListAdminUsers,
} from "../../../../redux/actions/admin/tags";
import {
  errorMessageModal,
  successMessageModal,
} from "../../../components/Modal/MessagesModal";
import { adminCreateTagSachema } from "./Validations";

const EditTags = ({ cardData }) => {
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [formData, setFormData] = useState();
  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const tagData = cardData ?? [];
  useEffect(() => {
    setFormData({
      name: tagData?.name,
    });
  }, [cardData]);

  const [formErrors, setFormErrors] = useState("");
  const handleSubmit = () => {
    const payload = {
      user_id: tagData?.user_id,
      admin_id: Number(userID),
      name: checkEmpty(formData?.name),
      id: tagData?.id,
      status: tagData?.status,
    };

    if (adminCreateTagSachema({ formData: payload, setFormErrors })) {
      setLoading(true);
      dispatch(
        editTagAdmin(payload, (type, res) => {
          if (type === "success") {
            successMessageModal(
              "Tag Successfully Updated.",
              "Tag details have been updated successfully."
            );
            setLoading(false);
            dispatch(getAllTagsListAdmin(Number(userID)));
            dispatch(getAllTagsListAdminUsers(Number(userID)));
          } else if (type === "failed") {
            console.log(res, "res");
            errorMessageModal("Error Message", res?.message);
            setLoading(false);
          }
        })
      );
    }
  };

  return (
    <div className="quix-edit-tag-outer quix-data-table card">
      <div className="quix-dashboard-popup-title text-start card-header m-0">
        <h5 className="title m-0">Edit tag</h5>
      </div>
      <div className="quix-dashboard-popup-body">
        <div className="quix-tag-edit-details px-4">
          <div className="quix-form-outer">
            <div className="quix-edit-tag">
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label
                    htmlFor="edit-name"
                    className="col-sm-12 col-form-label text-start"
                  >
                    Tag name
                  </label>
                  <div className="col-sm-12">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      value={checkEmpty(formData?.name)}
                      id="edit-name"
                      onChange={(e) => handleChange(e)}
                      maxLength={255}
                    />
                    {formErrors?.nameError && (
                      <p className="text-danger account-dec m-0">
                        {formErrors?.nameError}
                      </p>
                    )}
                  </div>
                </div>
                <div className="quix-form-btn-outer d-flex mt-2 gap-2">
                  <button
                    className="btn theme-cancel-btn"
                    onClick={() => {
                      setFormData({ name: "" });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn theme-blue-btn"
                    onClick={() => handleSubmit()}
                    disabled={loading}
                  >
                    Change
                  </button>
                  <img
                    style={{ display: !loading ? "none" : "block" }}
                    className="btn-loader-icons"
                    src={loaderIcon}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTags;
