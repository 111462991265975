import React, { useEffect, useState, useRef } from "react";
import logoImg from "../../../assets/images/home/ocr-logo.png";
import loadingIcon from "../../../assets/images/light-loader.gif";
import sucessMessageImg from "../../../assets/images/quix-success.png";
import failureImg from "../../../assets/images/quix-failure.png";
import { Link, useLocation } from "react-router-dom";
import {
    resendVerifyEmailToken,
    verifyEmailToken,
} from "../../../redux/actions/authentication";
import { useDispatch } from "react-redux";
import {
    failureMessagePopup,
    successMessagePopup,
} from "../../../helper/helper";

const VerifyEmail = () => {
    const [message, setMessage] = useState(null);
    const [messageStatus, setMessageStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const [hideMessageBox, setHideMessageBox] = useState(false);
    const searchToken = location?.search?.replace("?=", "");
    const requestSent = useRef(false);

    const manageTokenVerification = (status) => {
        switch (status) {
            case 200:
                return setMessageStatus(200);
            case 300:
                return setMessageStatus(300);
            case 303:
                return setMessageStatus(303);
            default:
                return setMessageStatus(300);
        }
    };

    const handleSubmit = (payload) => {
        if (payload?.token !== "") {
            setLoading(true);
            setTimeout(() => {
                dispatch(
                    verifyEmailToken(payload, (type, res) => {
                        if (type === "success") {
                            const status = res?.status;
                            const message = res?.data?.message;
                            manageTokenVerification(status);
                            setMessage(message);
                            setLoading(false);
                        } else if (type === "failed") {
                            const status = res?.status;
                            const message = res?.data?.message;
                            manageTokenVerification(status);
                            setMessage(message);
                            setLoading(false);
                        }
                    })
                );
            }, 2000);
        }
    };

    useEffect(() => {
        if (!searchToken || requestSent.current) return;
        requestSent.current = true;

        const payload = {
            token: searchToken,
        };
        handleSubmit(payload);
    }, [searchToken]);

    const resendToken = () => {
        setLoading(true);
        const payload = {
            token: searchToken,
        };
        setDisabled(true);
        dispatch(
            resendVerifyEmailToken(payload, (type, res) => {
                if (type === "success") {
                    setDisabled(false);
                    successMessagePopup("Success Message", res?.message);
                    setLoading(false);
                    setMessage(res?.message);
                    setHideMessageBox(true);
                } else if (type === "failed") {
                    setDisabled(false);
                    setLoading(false);
                    failureMessagePopup("Error Message", res?.message);
                    setHideMessageBox(true);
                }
            })
        );
    };

    return (
        <div className="login-page">
            <div id="quix-dashboard-wrapper">
                <div id="quix-dashboard-wrapper">
                    <div id="quix-dashboard-inner">
                        <div className="login-logo-outer">
                            <div className="login-logo-inner">
                                <Link to={"/"}>
                                    <img src={logoImg} />
                                </Link>
                            </div>
                        </div>
                        <div className="login-form-outer">
                            <div className="login-form-inner">
                                <span className="form-title">Verify Your Account!</span>
                                <span className="form-subtitle">
                                    To get started, please verify.
                                </span>
                            </div>
                            {!loading && !hideMessageBox ? (
                                <>
                                    {messageStatus === 200 && (
                                        <>
                                            <div className="message-box card mb-2">
                                                <div className="card-body">
                                                    <img src={sucessMessageImg} />{" "}
                                                    <span>{message}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {messageStatus === 300 && (
                                        <>
                                            <div className="message-box card mb-4">
                                                <div className="card-body">
                                                    <img src={failureImg} />{" "}
                                                    <span>{message}</span>
                                                </div>
                                            </div>
                                            <div className="resend-request text-center">
                                                <div className="text-center mb-3">
                                                    <span className="resend-title">
                                                        Need a new verification token?
                                                    </span>
                                                    <span className="resend-text">
                                                        Please resend the token as previous one is expired.
                                                    </span>
                                                </div>
                                                <button
                                                    onClick={() => resendToken()}
                                                    className="btn token-resend-btn"
                                                    disabled={disabled}
                                                >
                                                    Resend Token
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {messageStatus === 303 && (
                                        <>
                                            <div className="message-box card mb-4">
                                                <div className="card-body">
                                                    <img src={failureImg} />{" "}
                                                    <span>{message}</span>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) :
                                hideMessageBox ? <>
                                    <div className="message-box card mb-2">
                                        <div className="card-body">
                                            <img src={sucessMessageImg} />{" "}
                                            <span>{message}</span>
                                        </div>
                                    </div>
                                </> : (
                                    <div className="text-center mb-5">
                                        <img src={loadingIcon} height={'40px'} />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VerifyEmail;
