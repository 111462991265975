export const editUserSachema = ({ formData, setFormErrors }) => {
    const { name, email, oldPassword, password, confirmPassword } = formData;
    let valid = true;
    const errors = {
        nameError: "",
        emailError: "",
        oldPasswordError: "",
        passwordError: "",
        confirmPasswordError: "",
    };

    if (!name) {
        errors.nameError = "Name cannot be empty!";
        valid = false;
    }
    if (!email) {
        errors.emailError = "Email cannot be empty!";
        valid = false;
    }
    if (password || confirmPassword || oldPassword) {
        if (!oldPassword) {
            errors.oldPasswordError = "Old Password cannot be empty!";
            valid = false;
        }
        if (!password) {
            errors.passwordError = "Password cannot be empty!";
            valid = false;
        }
        if (!confirmPassword) {
            errors.confirmPasswordError = "Confirm Password cannot be empty!";
            valid = false;
        } else if (password !== confirmPassword) {
            errors.confirmPasswordError = "Passwords does not match!";
            valid = false;
        } else if (password.length < 8 || password.length > 20) {
            errors.passwordError =
                "Password must be between 8 and 20 characters long!";
            valid = false;
        } else if (/\s/.test(password)) {
            errors.passwordError = "Password cannot contain spaces!";
            valid = false;
        } else if (
            !/(?=.*[A-Z])/.test(password) ||
            !/(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~])/.test(password)
        ) {
            errors.passwordError =
                "Password must include at least one uppercase letter and one special character!";
            valid = false;
        } else if (!/(?=.*\d)/.test(password)) {
            errors.passwordError =
                "Password must include at least one numeric character!";
            valid = false;
        } else if (!/(?=.*[a-zA-Z])/.test(password)) {
            errors.passwordError =
                "Password must include at least one alphabetic character!";
            valid = false;
        }
    }

    setFormErrors(errors);
    return valid;
};
