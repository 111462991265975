import React from "react";
import cameraIcon from "../../../assets/images/home/ocr-cam-camra.png";
import activatityImg from "../../../assets/images/home/ocr-activatity.png";
import editIcon from "../../../assets/images/home/ocr-edit_icon.png";
import shareIcon from "../../../assets/images/home/ocr-share.png";
import menuIcon from "../../../assets/images/home/ocr-menu.png";

const ScreenshotSmarter = () => {
  return (
    <div className="container" id="screenshot-smarter">
      <div className="row">
        <div className="section_title">
          <h2><span className="heading-badge-text">Screenshot Smarter.</span> Not Harder.</h2>
          <p>Spark Inspiration, Share Ideas, &amp; Ignite Conversations</p>
        </div>
        <div className="col-lg-6 d-flex flex-column justify-content-center conversations-mid screenshots">
          <img src={activatityImg} />
        </div>
        <div className="col-lg-6 conversations-mid">
          <span className="screenshot_smarter">
            <img src={cameraIcon} />
            <div className="conversations-descriptions">
              <h5>Capture screen like a boss!</h5>
              <p>Snap Full Screen Area, Visible Area Or Select As You Wish</p>
            </div>
          </span>
          <span className="screenshot_smarter">
            <img src={editIcon} />
            <div className="conversations-descriptions">
              <h5>Edit & Annotate your heart's content</h5>
              <p>
                Crop It, Add Text, Highlights, Blur & Other Effects With Ease.
              </p>
            </div>
          </span>
          <span className="screenshot_smarter">
            <img src={shareIcon} />
            <div className="conversations-descriptions">
              <h5>Be ready to share a flick</h5>
              <p>Upload To Cloud, Share Via Link Or By Email</p>
            </div>
          </span>
          <span className="screenshot_smarter">
            <img src={menuIcon} />
            <div className="conversations-descriptions">
              <h5>All media roads lead to dashboard!</h5>
              <p>View, Access, Manage Screenshots Through Dashboard</p>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ScreenshotSmarter;
