import React from "react";
import chromeIcon from "../../../assets/images/home/ocr-chrome-icon-white.png";
import { Link } from "react-router-dom";
import elevateImage from '../../../assets/images/home/ocr-elevate-img.png';
import cameraIcon from "../../../assets/images/home/ocr-cam-camra.png";
import vidCameraIcon from "../../../assets/images/home/ocr-vid-camra.png";
import listIcon from "../../../assets/images/home/ocr-list.png";


const ElevateYourCapture = () => {
  return (
    <div className="elevate-your-capture-sec" id="elevate-capture">
      <div className="elevate-your-capture-inner container">
        <div className="elevate-your-capture-column col-md-6">
          <div className="elevate-your-capture-column-content d-flex flex-column">
            <h2>Elevate your capture <br /> with Capios today!</h2>
            <Link
              className="btn btn-primary chrome-thisbtn"
              to="https://chromewebstore.google.com/detail/capios-ocr-screenshot-vid/dmfjonjeonmaoehpjaonkjgfdjanapbe"
            >
              <img className="chrome-icon" src={chromeIcon} />
              Add to Chrome
              <span>It’s free!</span>
            </Link>
          </div>
        </div>
        <div className="elevate-your-capture-column col-lg-6 d-flex flex-column justify-content-center conversations-mid screenshots">
          <div className="elevate-your-capture-column-img">
            <img className="elevate-activity-image" src={elevateImage} />
            <img className="elevate-badge elevate-video-img" src={vidCameraIcon} />
            <img className="elevate-badge elevate-camer-img" src={listIcon} />
            <img className="elevate-badge elevate-list-img" src={cameraIcon} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElevateYourCapture;
