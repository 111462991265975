import React, { useEffect, useRef } from "react";
import closeIcon from "../../assets/images/quix-close.png";
import { checkServerURL } from "../../../helper/helper";
import { API_URL } from "../../../utils/config";
import dateFormat from "dateformat";

const VideoCard = ({ closeModal, cardData }) => {

  const cardRef = useRef();
  const itemTitle = cardData?.title && cardData?.title;
  const itemImage =
    cardData?.thumbnail && checkServerURL(API_URL, cardData?.thumbnail);
  const itemDuration = cardData?.file_duration && cardData?.file_duration;
  const itemCreatedDate = cardData?.created_at && dateFormat(cardData?.created_at, "dd/mm/yyyy hh:MM TT");

  useEffect(() => {
    const handleClickOutside = (event) => {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
            closeModal(false);
        }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal]);

  return (
    <div id="video-card" className="media-card admin-popup-modal" ref={cardRef}>
      <div className="close-admin-popup" onClick={() => closeModal(false)}>
        <img src={closeIcon} />
      </div>
      <div className="media-card-header">
        <h5 className="title text-clip">{itemTitle}</h5>
      </div>
      <div className="media-card-content">
        <img className="media-item" src={itemImage} />
      </div>
      <div className="media-card-footer">
        <p className="video-details-lable">Video details</p>
        <div className="video-details">
          <div className="video-details-column">
            <span>Video title</span>
            <h6 className="text-clip">{itemTitle}</h6>
          </div>
          <div className="d-flex justify-content-between">
            <div className="video-details-column">
              <span>Video Duration</span>
              <h6>{itemDuration} min</h6>
            </div>
            <div className="video-details-column">
              <span>Video recording date</span>
              <h6>{itemCreatedDate}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
