import React from "react";
import arrowIcon from "../../../assets/images/arrow-down.png";
import deleteIcon from "../../../assets/images/trash-icon.png";
import { Link } from "react-router-dom";

const ProductPlanCard = ({ extraClass, planIcon, planLabel, planTitle, onClick, editBtnClick, deleteBtnClick, isLoading }) => {
    return (
        <div className={`card pricing-product-card d-flex align-items-center ${extraClass}`} onClick={() => onClick()}>
            <div className="pricing-product-card-bnnr">
                {planIcon && (
                    <img src={planIcon} height={"100%"} width={"100%"} />
                )}
            </div>
            <div className="pricing-product-card-details d-flex justify-content-between">
                <div className="pricing-product-price-details">
                    <label>{planLabel}</label>
                    <h6>{planTitle}</h6>
                </div>
                <div className="d-flex gap-2">
                    <button onClick={editBtnClick} className="btn product-plan-edit-btn d-flex align-items-center gap-2">
                        Edit payment plan
                        <img src={arrowIcon} />
                    </button>
                    <button onClick={deleteBtnClick} disabled={isLoading} className="btn product-plan-edit-btn">
                        <img src={deleteIcon} />
                    </button>
                </div>
            </div>
        </div>
    );
};

ProductPlanCard.defaultProps = {
    extraClass: "",
    planIcon: null,
    planLabel: '',
    planTitle: '',
    onClick: function () { },
    editBtnClick: function () { },
    deleteBtnClick: function () { }
}


export default ProductPlanCard;
