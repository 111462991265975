import {
  GET_STARRED_VIDEOS,
  GET_VIDEOS,
  LOADING_VIDEOS,
  NOT_FOUND_VIDEO,
  RENAME_CARD,
  UPLOAD_VIDEO,
  SHORT_VIDEOS,
  SHORT_STARRED_VIDEOS,
  SEND_BY_EMAIL,
  NOT_FOUND_STARRED_VIDEO,
  EDITED_VIDEO_DETAILS,
  EDIT_VIDEO,
  LOADING_STARRED_VIDEOS,
  SEARCH_VIDEOS,
  SEARCH_STARRED_VIDEOS,
} from "../actions/type";

const INITIAL_STATE = {
  list: [],
  starred_list: [],
  is_loading: false,
  is_loading_started_videos: false,
  not_found: false,
  starred_not_found: false,
  rename_video: null,
  edited_video: [],
  uploaded_video: [],
  modified_video: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_VIDEOS:
      return { ...state, is_loading: true, not_found: false };
    case LOADING_STARRED_VIDEOS:
      return {
        ...state,
        is_loading_started_videos: true,
      };
    case GET_VIDEOS:
      return {
        ...state,
        list: action.payload,
        is_loading: false,
        not_found: false,
      };
    case GET_STARRED_VIDEOS:
      return {
        ...state,
        starred_list: action.payload,
        is_loading_started_videos: false,
        starred_not_found: false,
      };
    case EDITED_VIDEO_DETAILS:
      return {
        ...state,
        edited_video: action.payload,
        is_loading: false,
        not_found: false,
      };
    case SHORT_VIDEOS:
      return {
        ...state,
        list: action.payload,
        is_loading: false,
        not_found: false,
      };
    case SEARCH_VIDEOS:
      return {
        ...state,
        list: action.payload,
        is_loading: false,
        not_found: false,
      };
    case SHORT_STARRED_VIDEOS:
      return {
        ...state,
        starred_list: action.payload,
        is_loading_started_videos: false,
        not_found: false,
      };
    case SEARCH_STARRED_VIDEOS:
      return {
        ...state,
        starred_list: action.payload,
        is_loading_started_videos: false,
        starred_not_found: false,
      };

    case SEND_BY_EMAIL:
      return { ...state, is_loading: false };
    case UPLOAD_VIDEO:
      return { ...state, uploaded_video: action.payload, is_loading: false };
    case RENAME_CARD:
      return { ...state, rename_video: action.payload, is_loading: false };
    case EDIT_VIDEO:
      return { ...state, modified_video: action.payload, is_loading: false };
    case NOT_FOUND_VIDEO:
      return { ...state, list: [], not_found: true, is_loading: false };
    case NOT_FOUND_STARRED_VIDEO:
      return {
        ...state,
        starred_not_found: true,
        is_loading_started_videos: false,
      };
    default:
      return state;
  }
};
