import {
  LOADING,
  GET_PROGRESS,
  Get_USER_DETAIL_BY_ID,
  LOGIN,
  SIGN_UP,
  EDIT_USER_DETAIL,
  GOOGLE_TOKEN,
} from "../actions/type";

const INITIAL_STATE = {
  is_loading: false,
  progress: [],
  user_details: [],
  edit_user_details: [],
  user: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, is_loading: true };
    case Get_USER_DETAIL_BY_ID:
      return { ...state, user_details: action.payload, is_loading: false };
    case EDIT_USER_DETAIL:
      return { ...state, edit_user_details: action.payload, is_loading: false };
    case GET_PROGRESS:
      return { ...state, progress: action.payload, is_loading: false };
    case LOGIN:
      return { ...state, user: action.payload, is_loading: false };
    case SIGN_UP:
        return { ...state, user: action.payload, is_loading: false };
    default:
      return state;
  }
};
