import React, { useState } from 'react';
import closeIcon from "../../assets/images/quix-close.png";
import loaderIcon from "../../assets/images/light-loader.gif";
import { useDispatch } from 'react-redux';
import { manageUrlSummary } from '../../redux/actions/manageScreenshots';
import { failureMessagePopup } from '../../helper/helper';
import { useRef } from 'react';

const UrlSummary = ({ showModal, closeModal, cardData }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [summaryURL, setSummaryURL] = useState(null);
    const [summaryResData, setSummaryResData] = useState(null);
    const summaryText = summaryResData?.choices && summaryResData?.choices?.[0]?.message?.content;

    const handleSubmit = () => {
        if (!summaryURL) {
            alert('URl is not allow to empty!');
        } else {
            const payload = {
                url: summaryURL,
            }
            setLoading(true);
            // Dispatch the action to manage the URL summary
            dispatch(manageUrlSummary(payload, (type, res) => {
                if (type === 'success') {
                    setSummaryResData(res?.data);
                    setLoading(false);
                }
                else if (type === 'failed') {
                    failureMessagePopup('Error Message!', res?.message);
                    setLoading(false);
                }
            }));
        }
    }

    const textRef = useRef(null);
    // copy summary text on click
    const handleCopySummaryText = () => {
        if (textRef.current) {
            const textToCopy = textRef.current.innerText;
            if (textToCopy) {
                navigator.clipboard.writeText(textToCopy);
                closeModal(false);
            }
        }
    }

    return (
        <div>
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: !showModal ? "none" : "block",
                }}
            ></div>
            <div id="quix-dashboard-popup">
                <div className="quix-dashboard-popup-inner">
                    <div className="quix-dashboard-popup-title">
                        <div className="quix-dashboard-popup-title-inner">
                            <span>URL Summary</span>
                        </div>
                    </div>
                    <div className="quix-dashboard-popup-body">
                        <div className="quix-dashboard-popup-body-inner">
                            <div className="get-summary-wrapper">
                                <div className="get-summary">
                                    <input type="text" className="form-control get-summary-input" maxLength={255} onChange={(e) => setSummaryURL(e.target.value)} defaultValue={summaryURL}
                                        name="url-summary" placeholder="Enter URL" />
                                    <button disabled={loading} className="get-summary-button" onClick={() => handleSubmit()}>Get Summary</button>
                                    <img
                                        style={{ display: !loading ? "none" : "block" }}
                                        className="loader-icon"
                                        src={loaderIcon}
                                    />
                                </div>
                                <div className="copy-summary-url">
                                    <div className="summary-text form-control" id="summary-text" ref={textRef}>{summaryText}</div>
                                    <button onClick={() => handleCopySummaryText()} className="copy-summary-button">Copy Summary</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="quix-dashboard-close"
                        onClick={() => closeModal(false)}
                    >
                        <img src={closeIcon} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UrlSummary;