import React from 'react';
import closeIcon from '../../assets/images/quix-close.png';
import QRCode from "react-qr-code";

const QrGenrateCard = ({ showModal, closeModal, path }) => {
    const itemUrl = path && path;

    return (
        <div className='qr-code-popup'>
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: !showModal ? "none" : "block",
                }}
            ></div>
            <div id="quix-dashboard-popup">
                <div className="quix-dashboard-popup-inner">
                    <div className="quix-dashboard-popup-title">
                        <div className="quix-dashboard-popup-title-inner">
                            <span>Share with Qr Code</span>
                        </div>
                    </div>
                    <div className="quix-dashboard-popup-body">
                        <div className="quix-dashboard-popup-body-inner">
                            <QRCode
                                size={120}
                                style={{ height: "auto", maxWidth: "120px", width: "120px" }}
                                value={itemUrl}
                                viewBox={`0 0 120 120`}
                            />
                        </div>
                    </div>
                    <div
                        className="quix-dashboard-close"
                        onClick={() => closeModal(false)}
                    >
                        <img src={closeIcon} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QrGenrateCard;