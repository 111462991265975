// Edit user validations
export const adminUserEditSachema = ({ formData, setFormErrors }) => {
    const { name, email } = formData;
    let regex =
        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let valid = true;

    const errors = {
        nameError: "",
        emailError: "",
    };

    if (!name) {
        errors.nameError = "Full name cannot be empty!";
        valid = false;
    }

    if (!email) {
        errors.emailError = "Email cannot be empty!";
        valid = false;
    } else if (!regex.test(email)) {
        errors.emailError = "Invalid email address!";
        valid = false;
    }

    setFormErrors(errors);
    return valid;
};
