import React, { useState, useEffect } from "react";
import AdminLayout from "../../layout/adminLayout";
import { connect, useDispatch } from "react-redux";
import Table from "../../components/Table/Table";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import dateFormat from "dateformat";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import {
  checkServerURL,
  failureMessagePopup,
  successMessagePopup,
} from "../../../helper/helper";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import {
  getAllMembershipListAdmin,
  updateMembershipAdmin,
} from "../../../redux/actions/admin/membership";
import UserProfileCard from "../../components/Cards/UserProfileCard";
import actionIcon from "../../assets/images/action-icon.png";
import suspendIcon from "../../assets/images/slash-icon.png";
import EditActionModal from "../../components/Modal/EditActionModal";
import {
  errorMessageModal,
  successMessageModal,
} from "../../components/Modal/MessagesModal";

const MembershipList = ({ membershipList, isLoading, notFound }) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmMembershipModal, setShowConfirmMembershipModal] =
    useState(false);
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );

  // get all membership
  const membershipData = membershipList?.data ? membershipList?.data : [];
  useEffect(() => {
    if (userID) {
      dispatch(getAllMembershipListAdmin(Number(userID)));
    }
  }, []);

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return { text: "Active", class: "active-status" };
      case 0:
        return { text: "Inactive", class: "inactive-status" };
      default:
        return { text: "Inactive", class: "inactive-status" };
    }
  };

  const columns = [
    { Header: "#", accessor: (row, i) => i + 1 },
    { Header: "Priceplan Id", accessor: "priceplan_id" },
    {
      Header: "User name",
      Cell: (row) => (
        <UserProfileCard
          src={
            !row?.row?.original?.userDetails?.picture ||
              row?.row?.original?.userDetails?.picture === "null" ||
              row?.row?.original?.userDetails?.picture === null
              ? dummyUserIcon
              : checkServerURL(
                API_URL,
                `${row?.row?.original?.userDetails?.picture}`
              )
          }
          name={row?.row?.original?.userDetails?.name}
        />
      ),
    },
    {
      Header: "Price",
      Cell: (row) => (
        <span>
          {Number(row?.row?.original?.pricePlanDetails?.price) !== "" ||
            Number(row?.row?.original?.pricePlanDetails?.price) !== null
            ? `$${Number(row?.row?.original?.pricePlanDetails?.price)}`
            : "N/A"}
        </span>
      ),
    },
    {
      Header: "Price String",
      Cell: (row) => (
        <span>
          {row?.row?.original?.pricePlanDetails?.priceName !== "" ||
            row?.row?.original?.pricePlanDetails?.priceName !== null
            ? `${row?.row?.original?.pricePlanDetails?.priceName}`
            : "N/A"}
        </span>
      ),
    },
    {
      Header: "Start Date",
      Cell: (row) => {
        return (
          <span>
            {dateFormat(row?.row?.original?.start_date, "dd/mm/yyyy hh:MM TT")}
          </span>
        );
      },
    },
    {
      Header: "End Date",
      Cell: (row) => {
        return (
          <span>
            {dateFormat(row?.row?.original?.end_date, "dd/mm/yyyy hh:MM TT")}
          </span>
        );
      },
    },
    {
      Header: "Status",
      Cell: (row) => (
        <>
          <button
            className={`quix-table-action-btn 
              ${getStatus(row?.row?.original?.status)?.class}`}
          >
            {getStatus(row?.row?.original?.status)?.text}
          </button>
        </>
      ),
    },
    {
      Header: "Action",
      Cell: (row) => {
        return (
          <div className="action-cell">
            <div className="edit-action-cell">
              <img className="action-img" src={actionIcon} />
              <EditActionModal>
                {row?.row?.original.status === 1 ? (
                  <div
                    className="column-action gap-2"
                    onClick={() => {
                      setShowConfirmMembershipModal(true);
                      setUserData(row?.row?.original);
                    }}
                  >
                    <img src={suspendIcon} />
                    <span>Deactivate</span>
                  </div>
                ) : (
                  <div
                    className="column-action gap-2"
                    onClick={() => {
                      setShowConfirmMembershipModal(true);
                      setUserData(row?.row?.original);
                    }}
                  >
                    <img src={suspendIcon} />
                    <span>Activate</span>
                  </div>
                )}
              </EditActionModal>
            </div>
          </div>
        );
      },
    },
  ];

  const [updateLoading, setUpdateLoading] = useState(false);
  const handleUpdateMembership = (action) => {
    if (action === true) {
      setUpdateLoading(true);
      const payload = {
        id: userData?.id,
        admin_id: Number(userID),
        user_id: userData?.user_id,
        priceplan_id: userData?.priceplan_id,
        recurrence: userData?.recurrence,
        end_date: userData?.end_date,
        status: userData?.status == 1 ? 0 : 1,
      };
      dispatch(
        updateMembershipAdmin(payload, (type, res) => {
          if (type === "success") {
            setUpdateLoading(false);
            setShowConfirmMembershipModal(false);
            const membershipStatus = res?.data?.status;
            if (membershipStatus === 1) {
              successMessageModal(
                "Membership Activated Successfully.",
                "Membership has been activated successfully."
              );
            } else {
              successMessageModal(
                "Membership Deactivated Successfully.",
                "Membership has been deactivated."
              );
            }
            dispatch(getAllMembershipListAdmin(Number(userID)));
          } else if (type === "fail") {
            setUpdateLoading(false);
            setShowConfirmMembershipModal(false);
            errorMessageModal("Edit Membership", res?.message);
          }
        })
      );
    }
  };

  // handle search all users csv data
  const membershipDataCSVFormate =
    membershipData &&
    membershipData?.map((item) => {
      return {
        ID: item?.id ? item?.id : "N/A",
        Name: item?.userDetails?.name ? item?.userDetails?.name : "N/A",
        Picture: item?.userDetails?.picture
          ? checkServerURL(API_URL, item?.userDetails?.picture)
          : "N/A",
        PlanString: item?.pricePlanDetails?.priceName
          ? item?.pricePlanDetails?.priceName
          : "N/A",
        PriceplanID: item?.priceplan_id ? item?.priceplan_id : "N/A",
        TransactionID: item?.transaction_id ? item?.transaction_id : "N/A",
        Status: item?.status ? getStatus(item?.status).text : "N/A",
        Created_at: item?.created_at,
        Updated_at: item?.updated_at,
      };
    });
  let membershipDataCSV = {
    filename: "membership-list",
    data: membershipDataCSVFormate,
  };

  return (
    <div>
      <AdminLayout pageTitle={"membership"}>
        <div className="quix-membership-content">
          <div className="table-management-section">
            <div className="management-header mb-5">
              <h4>Membership Management</h4>
              <p>You can activate and inactive membership.</p>
            </div>
          </div>
          <Table
            tableData={membershipData}
            tableColumns={columns}
            csvData={membershipDataCSV}
            isLoading={isLoading}
            notFound={notFound}
            tableType={'membership'}
          />
          {showConfirmModal && (
            <ConfirmationModal
              isShowModal={showConfirmModal}
              closeModal={setShowConfirmModal}
              title={"Confirm Deletion"}
              btnText={"Delete"}
              callBack={function () { }}
              isLoading={false}
              isDisabled={false}
              message={
                <>
                  Are you sure you want to <br /> permanently delete this
                  Membership record?
                </>
              }
            />
          )}
          {showConfirmMembershipModal && (
            <ConfirmationModal
              isShowModal={showConfirmMembershipModal}
              closeModal={setShowConfirmMembershipModal}
              title={"Confirm Change Membership"}
              btnText={"Change"}
              callBack={(res) => handleUpdateMembership(res)}
              isLoading={updateLoading}
              isDisabled={updateLoading}
              messageTitle={
                <>
                  Are you sure you want to <br /> change Membership status?
                </>
              }
            />
          )}
        </div>
      </AdminLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state?.admin_membership?.is_loading,
  membershipList: state?.admin_membership?.list,
  notFound: state?.admin_membership?.not_found,
});

export default connect(mapStateToProps, { getAllMembershipListAdmin })(
  MembershipList
);
