import React, { useEffect, useState } from "react";
import jQuery from "jquery";
import closeIcon from "../../assets/images/quix-close.png";
import fbShareIcon from "../../assets/images/facebook-icon.png";
import linkedInShareIcon from "../../assets/images/linkedin-icon.png";
import twitterShareIcon from "../../assets/images/twitter-x-icon.png";
import instagramShareIcon from "../../assets/images/instagram-icon.png";
import emailShareIcon from "../../assets/images/email-share-icon.png";
import qrcodeShareIcon from "../../assets/images/scan-qr-code-icon.png";
import youTubeShareIcon from "../../assets/images/quix-youtube-icon.png";
import googleDriveShareIcon from "../../assets/images/quix-google-drive-icon.png";
import ShareByEmail from "../Modals/ShareByEmail";
import {
  sg_share_link,
  shareOnMedia,
  successMessagePopup,
} from "../../helper/helper";
import { localStoragePrefix } from "../../utils/config";
import { uploadVideoOnGoogleDrive } from "../../redux/actions/manageVideos";
import { connect, useDispatch } from "react-redux";
import { LoginSocialGoogle } from "reactjs-social-login";
import QrGenrateCard from "./QrGenrateCard";
import { getGeneralSettingcredentials } from "../../redux/actions/configServices";

const ShareLink = ({
  showModal,
  closeModal,
  path,
  cardType,
  googleToken,
  cardData,
}) => {
  const dispatch = useDispatch();
  const [shareWithQr, setShareWithQr] = useState(false);
  const [showShareByEmail, setShareByEmailModal] = useState(false);
  const [configObj, setConfigObj] = useState({});
  const [isCopyText, setCopyText] = useState(false);

  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const itemID = cardData?.id && cardData?.id;

  useEffect(() => {
    getGeneralSettingcredentials((res) => {
      const googleDetails = res?.google && res?.google;
      setConfigObj({
        google: googleDetails,
      });
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCopyText(false);
    }, 1000);
  }, [isCopyText]);

  const shareViaSocialMedia = (type, URL) => {
    // Check if the sharing type is Facebook
    if (type === "facebook") {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          URL
        )}`,
        "facebookpopup",
        "popup"
      );
      closeModal(false);
    }
    // Check if the sharing type is LinkedIn
    if (type === "linkedIn") {
      const shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
        path
      )}`;
      window.open(shareUrl, "linkedInpopup", "popup");
      closeModal(false);
    }
    // Check if the sharing type is Twitter
    if (type === "twitter") {
      window.open(
        `https://twitter.com/intent/tweet?url=${encodeURIComponent(path)}`,
        "twitterpopup",
        "popup"
      );
      closeModal(false);
    }
  };

  const handleUpload = (e) => {
    jQuery(".google-uploader-outer .share-on-media").removeClass("active");
    jQuery(e.currentTarget).addClass("active");
  };

  const handleResponse = (response, videoFile) => {
    const uploadType = jQuery(
      ".google-uploader-outer .share-on-media.active"
    ).attr("action-type");
    let authToken = response?.profile?.access_token;
    const fileObj = new File([videoFile], "video.mp4", { type: "video/mp4" });
    let scope = "";
    if (uploadType === "google") {
      scope = "https://www.googleapis.com/auth/drive";
    } else if (uploadType === "youtube") {
      scope = "https://www.googleapis.com/auth/youtube.upload";
    }

    const payload = {
      user_id: Number(userID),
      token: `access_token=${authToken}&token_type=Bearer&expires_in=3599&scope=${scope}`,
      file: fileObj,
      uploadType: uploadType,
    };
    // Dispatch the upload action to upload the video
    dispatch(
      uploadVideoOnGoogleDrive(payload, (type, res) => {
        if (type === "success") {
          closeModal(false);
          successMessagePopup(
            "Uploaded Successfully!",
            "Video uploaded successfully."
          );
        }
      })
    );
  };

  return (
    <>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup">
        <div className="quix-dashboard-popup-inner">
          <div className="quix-dashboard-popup-title">
            <div className="quix-dashboard-popup-title-inner">
              <span>Share with Anyone</span>
            </div>
          </div>
          <div className="quix-dashboard-popup-body">
            <div className="quix-dashboard-popup-body-inner">
              <div className="quix-email-share-heading">
                <span>Share on social media</span>
              </div>
              <div className="quix-social-share-form">
                {cardType === "video" ? (
                  <div
                    className="share-on-media share-facebook"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={fbShareIcon} title="Coming Soon" />
                  </div>
                ) : (
                  <div
                    className="share-on-media share-facebook"
                    onClick={() => shareViaSocialMedia("facebook", path)}
                  >
                    <img src={fbShareIcon} title="Facebook" />
                  </div>
                )}
                {cardType === "video" ? (
                  <div
                    className="share-on-media share-linkedIn"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={linkedInShareIcon} title="Coming Soon" />
                  </div>
                ) : (
                  <div
                    className="share-on-media share-linkedIn"
                    onClick={() => shareViaSocialMedia("linkedIn", path)}
                  >
                    <img src={linkedInShareIcon} title="LinkedIn" />
                  </div>
                )}
                {cardType === "video" ? (
                  <div
                    className="share-on-media share-Twitter"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={twitterShareIcon} title="Coming Soon" />
                  </div>
                ) : (
                  <div
                    className="share-on-media share-Twitter"
                    onClick={() => shareViaSocialMedia("twitter", path)}
                  >
                    <img src={twitterShareIcon} title="Twitter(X)" />
                  </div>
                )}
                {cardType === "video" ? (
                  <div
                    className="share-on-media share-instagram"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={instagramShareIcon} title="Coming Soon" />
                  </div>
                ) : (
                  <div
                    className="share-on-media share-instagram"
                    onClick={() => shareOnMedia(path)}
                  >
                    <img src={instagramShareIcon} title="Instagram" />
                  </div>
                )}
                {/* setShareWithQr */}
                <div
                  className="share-on-media share-email"
                  onClick={() => setShareByEmailModal(!showShareByEmail)}
                >
                  <img src={emailShareIcon} title="Email" />
                </div>
                <div
                  className="share-on-media share-qr-code"
                  onClick={() => {
                    setShareWithQr(true);
                  }}
                >
                  <img src={qrcodeShareIcon} title="QR Code" />
                </div>
                {cardType === "image" && (
                  <>
                    {configObj?.google?.google_client_id ? (
                      <LoginSocialGoogle
                        false
                        client_id={configObj?.google?.google_client_id}
                        onResolve={({ provider, data }) => {
                          handleResponse({ provider, profile: data }, path);
                        }}
                        cookiePolicy={"single_host_origin"}
                        scope={
                          "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/youtube.upload"
                        }
                        isOnlyGetToken={true}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <div className="google-uploader-outer">
                          <div
                            className="share-on-media google-uploader"
                            onClick={(e) => handleUpload(e)}
                            action-type={"google"}
                          >
                            <img src={googleDriveShareIcon} title="Drive" />
                          </div>
                        </div>
                      </LoginSocialGoogle>
                    ) : (
                      <div className="google-uploader-outer">
                        <div
                          className="share-on-media google-uploader"
                          action-type={"google"}
                          style={{ opacity: "0.5" }}
                        >
                          <img src={googleDriveShareIcon} title="Coming Soon" />
                        </div>
                      </div>
                    )}
                  </>
                )}
                {cardType === "video" && (
                  <>
                    {configObj?.google?.google_client_id ? (
                      <LoginSocialGoogle
                        false
                        client_id={configObj?.google?.google_client_id}
                        onResolve={({ provider, data }) => {
                          handleResponse({ provider, profile: data }, path);
                        }}
                        cookiePolicy={"single_host_origin"}
                        scope={
                          "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/youtube.upload"
                        }
                        isOnlyGetToken={true}
                        onReject={(err) => {
                          console.log(err);
                        }}
                      >
                        <div className="google-uploader-outer">
                          <div
                            className="share-on-media google-uploader"
                            onClick={(e) => handleUpload(e)}
                            action-type={"google"}
                          >
                            <img src={googleDriveShareIcon} title="Drive" />
                          </div>
                          <div
                            className="share-on-media google-uploader"
                            onClick={(e) => handleUpload(e)}
                            action-type={"youtube"}
                          >
                            <img src={youTubeShareIcon} title="Youtube" />
                          </div>
                        </div>
                      </LoginSocialGoogle>
                    ) : (
                      <div className="google-uploader-outer">
                        <div
                          className="share-on-media google-uploader"
                          action-type={"google"}
                          style={{ opacity: "0.5" }}
                        >
                          <img src={googleDriveShareIcon} title="Coming Soon" />
                        </div>
                        <div
                          className="share-on-media google-uploader"
                          action-type={"youtube"}
                          style={{ opacity: "0.5" }}
                        >
                          <img src={youTubeShareIcon} title="Coming Soon" />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>

              <span>Share link</span>
              <div className="quix-email-share-form">
                <input
                  defaultValue={path}
                  type="text"
                  name="share-Link"
                  id="share-screenshot-link"
                  maxLength={255}
                />
                <button
                  className="send-link-share"
                  onClick={() => sg_share_link(path, setCopyText(true))}
                >
                  {/* Copy */}
                  {isCopyText ? "Copied" : "Copy"}
                </button>
                <button
                  className="close-link-share"
                  onClick={() => closeModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          <div
            className="quix-dashboard-close"
            onClick={() => closeModal(false)}
          >
            <img src={closeIcon} />
          </div>
        </div>
        {shareWithQr && (
          <QrGenrateCard
            showModal={shareWithQr}
            closeModal={setShareWithQr}
            path={path}
          />
        )}
        {showShareByEmail && (
          <ShareByEmail
            showModal={showShareByEmail}
            closeModal={setShareByEmailModal}
            itemType={cardType}
            itemID={itemID}
            userID={userID}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  googleToken: state?.auth?.google_token,
});

export default connect(mapStateToProps, { uploadVideoOnGoogleDrive })(
  ShareLink
);
