import axios from "axios";
import { API_URL } from "../../utils/config";
import { TAG_LIST, ADD_TAG } from "./type";

export const getAllTags = (userID, authToken) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: userID,
        access_token: authToken,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/tag/list`,
        data,
        config
      );
      if (res?.data?.status) {
        dispatch({
          type: TAG_LIST,
          payload: res?.data
        })
      }
    } catch (error) {
      console.log('---error---');
    }
  }
}

export const addTags = (payload, callback) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: payload?.user_id,
        access_token: payload?.access_token,
        name: payload?.name
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/tag/add`,
        data,
        config
      );
      if (res?.data?.status) {
        dispatch({
          type: ADD_TAG,
          payload: res?.data
        })
        callback('success', res?.data);
      }
    } catch (error) {
      const message = error?.response?.data
      callback('failed', message);
    }
  }
}