import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import cameraIcon from "../../assets/images/home/camra.png";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import { editUserDetails, getUserDetailsByID } from "../../redux/actions/authentication";
import loaderIcon from "../../assets/images/light-loader.gif";
import {
  checkServerURL,
  failureMessagePopup,
  successMessagePopup,
} from "../../helper/helper";
// import { userAuthChecker } from "../../helper/userAuthChecker";
import { API_URL, localStoragePrefix } from "../../utils/config";
import { editUserSachema } from "./components/Validation";
import showPassIcon from "../../assets/images/eye-pass-icon.png";
import hidePassIcon from "../../assets/images/hidden-pass-icon.png";

const Profile = ({ userDetails, cancelEditDetails }) => {
  const dispatch = useDispatch();
  const tokenAuth = localStorage.getItem(
    localStoragePrefix + "screenGeniusAuthToken"
  );
  const editUserData = userDetails?.data && userDetails?.data;
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const initialState = {
    alt: "profile",
    src: editUserData?.picture
      ? checkServerURL(API_URL, editUserData?.picture)
      : dummyUserIcon,
  };
  const [{ alt, src }, setPreview] = useState(initialState);
  const [userProfile, setUserProfile] = useState(null);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [oldShowPassword, setOldShowPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  let fileFormat = ["image/jpg", "image/jpeg", "image/png"];

  useEffect(() => {
    if (!tokenAuth) return;
    if (tokenAuth) {
      // userAuthChecker(dispatch, tokenAuth);
    }
  }, [dispatch, tokenAuth]);

  const [editDetails, setEditDetails] = useState({
    name: "",
    email: "",
    oldPassword: "",
    password: "",
    confirmPassword: "",
    stripe_acc_id: "",
  });

  useEffect(() => {
    setEditDetails({
      name: editUserData?.name,
      email: editUserData?.email,
      password: "",
      stripe_acc_id: editUserData?.stripe_acc_id,
    });
    setPreview(initialState);
    setUserProfile(initialState?.src);
  }, [editUserData]);

  const handleChangeProfile = (event) => {
    const { files } = event.target;
    let fileType = files?.[0]?.type;
    if (fileFormat.indexOf(fileType) > -1) {
      setPreview(
        files.length
          ? {
            src: URL.createObjectURL(files[0]),
            alt: files[0].name,
          }
          : initialState
      );
      setUserProfile(files[0]);
    } else {
      failureMessagePopup(
        "Unsupported Image Format",
        "Please upload a JPG, JPEG or PNG file."
      );
    }
  };

  const handleChangeUserDetails = (e) => {
    const { name, value } = e.target;
    setEditDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmitDetail = () => {
    const payload = {
      name: editDetails?.name,
      email: editDetails?.email,
      picture: userProfile,
      oldPassword: editDetails?.oldPassword,
      password: editDetails?.password,
      confirmPassword: editDetails?.confirmPassword,
      stripe_acc_id: editDetails?.stripe_acc_id,
      user_id: userID,
      access_token: tokenAuth,
    };
    const formData = editDetails;
    if (editUserSachema({ formData, setFormErrors })) {
      setLoadingStatus(true);
      dispatch(
        editUserDetails(payload, (res, type) => {
          if (type === "success") {
            dispatch(getUserDetailsByID(tokenAuth, function () { }));
            setLoadingStatus(false);
            cancelEditDetails(false);
            successMessagePopup("Edit Profile", "Updated successfully!");
          } else if (type === "fail") {
            setLoadingStatus(false);
            failureMessagePopup("Error message", res?.message);
          }
        })
      );
    }
  };

  return (
    <div id="quix-profile-content">
      <div className="quix-user-details quix-column-user-details">
        <div className="quix-user-edit-details">
          <div className="quix-form">
            <div className="quix-user-profile mb-4">
              <div className="quix-user-profile-box">
                <img className="quix-profile-img" src={src} alt={alt} />
                <input
                  onChange={(e) => handleChangeProfile(e)}
                  className="quix-choose-profile"
                  type="file"
                  name="profile"
                  accept="image/*"
                />
              </div>
              <div className="quix-edit-profile-option">
                <img className="quix-edit-icon" src={cameraIcon} />
              </div>
            </div>
            <div className="mb-3 quix-form-filed text-start">
              <div className="quix-edit-name">
                <input
                  type="text"
                  name="name"
                  className="form-control "
                  id="quix-name"
                  value={editDetails?.name}
                  aria-describedby="quix-name"
                  onChange={(e) => handleChangeUserDetails(e)}
                  maxLength={255}
                />
              </div>
              {formErrors?.nameError && (
                <p className="text-danger account-dec m-0">
                  {formErrors?.nameError}
                </p>
              )}
            </div>
            <div className="mb-3 quix-form-filed text-start">
              <div className="quix-edit-email">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  id="quix-email"
                  aria-describedby="quix-email"
                  value={editDetails?.email}
                  disabled={true}
                  readOnly
                  maxLength={255}
                />
              </div>
              {formErrors?.emailError && (
                <p className="text-danger account-dec m-0">
                  {formErrors?.emailError}
                </p>
              )}
            </div>
            {editUserData?.password && (
              <div className="mb-3 quix-form-filed text-start user-password-filed">
                <div className="password-eyes quix-edit-password">
                  <input
                    type={!oldShowPassword ? "password" : "text"}
                    name="oldPassword"
                    placeholder="Enter your old password"
                    className="form-control"
                    id="old-password"
                    value={editDetails?.oldPassword}
                    aria-describedby="old-password"
                    onChange={(e) => handleChangeUserDetails(e)}
                    maxLength={255}
                  />
                  <img
                    src={!oldShowPassword ? showPassIcon : hidePassIcon}
                    height={"100%"}
                    width={"100%"}
                    onClick={() => setOldShowPassword(!oldShowPassword)}
                  />
                </div>
                {formErrors?.oldPasswordError && (
                  <p className="text-danger account-dec m-0">
                    {formErrors?.oldPasswordError}
                  </p>
                )}
              </div>
            )}
            <div className="mb-3 quix-form-filed text-start  user-password-filed">
              <div className="password-eyes quix-edit-password">
                <input
                  type={!showPassword ? "password" : "text"}
                  name="password"
                  className="form-control"
                  id="quix-password"
                  value={editDetails?.password}
                  placeholder="Enter your new password"
                  aria-describedby="quix-password"
                  onChange={(e) => handleChangeUserDetails(e)}
                  maxLength={255}
                />
                <img
                  src={!showPassword ? showPassIcon : hidePassIcon}
                  height={"100%"}
                  width={"100%"}
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              {formErrors?.passwordError && (
                <p className="text-danger account-dec m-0">
                  {formErrors?.passwordError}
                </p>
              )}
            </div>
            <div className="mb-3 quix-form-filed text-start user-password-filed">
              <div className="password-eyes quix-edit-password">
                <input
                  type={!showConfirmPassword ? "password" : "text"}
                  name="confirmPassword"
                  className="form-control"
                  id="quix-confirm-password"
                  value={editDetails?.confirmPassword}
                  aria-describedby="confirm-password"
                  onChange={(e) => handleChangeUserDetails(e)}
                  placeholder="Confirm your new password"
                  maxLength={255}
                />
                <img
                  src={!showConfirmPassword ? showPassIcon : hidePassIcon}
                  height={"100%"}
                  width={"100%"}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              </div>
              {formErrors?.confirmPasswordError && (
                <p className="text-danger account-dec m-0">
                  {formErrors?.confirmPasswordError}
                </p>
              )}
            </div>
            <div className="quix-form-filed d-flex justify-content-center mt-2">
              <button
                className="btn quix-cancel-edit"
                onClick={() => cancelEditDetails(false)}
              >
                Cancel
              </button>
              <button
                onClick={() => handleSubmitDetail()}
                className="btn edit-user-detail-btn"
                disabled={loadingStatus}
              >
                Save
              </button>
              <img
                style={{ display: !loadingStatus ? "none" : "block" }}
                className="loader-icon"
                src={loaderIcon}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state?.auth?.user_details,
});

export default connect(mapStateToProps, {})(Profile);
