import React from 'react';
import cameraIcon from "../../../assets/images/home/ocr-cam-camra.png";
import chromeIcon from "../../../assets/images/home/chrome-icon.png";
import vidCameraIcon from "../../../assets/images/home/ocr-vid-camra.png";
import cloudIcon from "../../../assets/images/home/ocr-cloud-icon.png";
import snapSyncIcon from "../../../assets/images/home/ocr-reset-icon.png";
import { Link } from 'react-router-dom';

const ScreenCaptures = () => {
    return (
        <div className="container-fluid turn_scree_captures_section">
            <div className="container" id="screen-capture">
                <div className='section_title'>
                    <h2>Turn Screen Captures into</h2>
                </div>
                <div className='stylish-masterpieces-sec'>
                    <div className='stylish-masterpieces-column'>
                        <div className='stylish-masterpieces-column-sec'>
                            <div className='stylish-masterpieces-column-img'>
                                <img src={cameraIcon} />
                            </div>
                            <span>Dab to fab Snaps!</span>
                        </div>
                        <div className='stylish-masterpieces-column-sec'>
                            <div className='stylish-masterpieces-column-img'>
                                <img src={vidCameraIcon} />
                            </div>
                            <span>Record. Share. Wow!</span>
                        </div>
                    </div>
                    <div className='stylish-masterpieces-column'>
                        <div className='stylish-masterpieces-column-sec d-block text-center'>
                            <div className='stylish-masterpieces-title'>
                                <h2>Stylish Masterpieces.</h2>
                            </div>
                            <span className='creating-impressing'>Start Creating. Start Impressing.</span>
                            <Link
                                className="btn btn-primary chrome-thisbtn"
                                to="https://chromewebstore.google.com/detail/capios-ocr-screenshot-vid/dmfjonjeonmaoehpjaonkjgfdjanapbe"
                            >
                                <img className="chrome-icon" src={chromeIcon} />
                                Add to Chrome
                                <span>It’s free!</span>
                            </Link>
                        </div>
                    </div>
                    <div className='stylish-masterpieces-column'>
                        <div className='stylish-masterpieces-column-sec'>
                            <div className='stylish-masterpieces-column-img'>
                                <img src={cloudIcon} />
                            </div>
                            <span>Seamless Cloud Peace</span>
                        </div>
                        <div className='stylish-masterpieces-column-sec'>
                            <div className='stylish-masterpieces-column-img'>
                                <img src={snapSyncIcon} />
                            </div>
                            <span>Snap. Sync. Access</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScreenCaptures;