import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";

const Layout = ({page, children }) => {
  return (
    <div id="quix-dashboard-wrapper">
      <div id="quix-dashboard-inner">
        <div id="quix-important-message">
          <span className="quix-important-message">
            Dear Users, please note: in this Beta version, inactive Data files
            will be deleted in 180 Days. Thanks for your support.{" "}
          </span>
        </div>
        <Navbar activePage={page}/>
        <div>{children}</div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
