import React, { useEffect, useState } from "react";
import Stripe from "./Stripe";
import StripeForm from "./stripeForm";
import closeIcon from "../../../assets/images/quix-close.png";

const MakePayment = ({ showModal, closeModal, customerID, paymentAmount, handlePaymentResponse }) => {
    const paymentFailed = (message) => {
        // console.log(message);
    };
    return (
    <>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? 'none' : 'block',
        }}
      ></div>
      <div id="quix-dashboard-popup"style={{
          display: !showModal ? 'none' : 'block',
        }} className="quix-make-payment-popup">
        <div className="quix-dashboard-popup-inner">
          <div className="quix-dashboard-popup-title">
            <div className="quix-dashboard-popup-title-inner">
              <span>Make Payment</span>
            </div>
          </div>
          <div className="quix-dashboard-popup-body">
            <Stripe>
              <StripeForm customerID={customerID} paymentAmount={paymentAmount} paymentFailed={paymentFailed} handlePaymentResponse={handlePaymentResponse}/>
            </Stripe>
          </div>
          <div className="quix-dashboard-close" onClick={() => closeModal(false)}>
            <img src={closeIcon} alt="Close Icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default MakePayment;
