import React, { useEffect, useState } from "react";
import closeIcon from "../../../assets/images/quix-close.png";
import Profile from "../../../views/profile/Profile";
import ProfileDetail from "./ProfileDetail";
import { connect, useDispatch } from "react-redux";
// import { userAuthChecker } from "../../../helper/userAuthChecker";
import { localStoragePrefix } from "../../../utils/config";


const ProfileModal = ({ showModal, closeModal, userDetails }) => {
  const [showEditDetails, setShowEditDetails] = useState(false);
  const dispatch = useDispatch();
  const tokenAuth = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");


  useEffect(() => {
    if (tokenAuth) {
      // Verify user authentication status
      // userAuthChecker(dispatch, tokenAuth);
    }
  }, [dispatch, tokenAuth]);
  const editUserData = userDetails?.data && userDetails?.data;

  return (
    <div>
      <div
        id="quix-dashboard-overlay"
        style={{
          display: !showModal ? "none" : "block",
        }}
      ></div>
      <div id="quix-dashboard-popup" className="quix-user-edit-popup">
        {showEditDetails ? (
          <div className="quix-dashboard-popup-inner">
            <div className="quix-dashboard-popup-title">
              <div className="quix-dashboard-popup-title-inner">
                <span>Edit Profile</span>
              </div>
            </div>
            <div className="quix-dashboard-popup-body">
              <div className="quix-dashboard-popup-body-inner">
                <Profile cancelEditDetails={closeModal} />
              </div>
            </div>
            <div
              className="quix-dashboard-close"
              onClick={() => closeModal(false)}
            >
              <img src={closeIcon} />
            </div>
          </div>
        ) : (
          <div className="quix-dashboard-popup-inner">
            <div className="quix-dashboard-popup-title">
              <div className="quix-dashboard-popup-title-inner">
                <span>Profile</span>
              </div>
            </div>
            <div className="quix-dashboard-popup-body">
              <div className="quix-dashboard-popup-body-inner">
                <ProfileDetail
                  editDetails={editUserData}
                  editUserDetails={setShowEditDetails}
                />
              </div>
            </div>
            <div
              className="quix-dashboard-close"
              onClick={() => closeModal(false)}
            >
              <img src={closeIcon} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state?.auth?.user_details,
});

export default connect(mapStateToProps, {})(ProfileModal);
