import { LOADING_GENERAL_SETTINGS, GET_GENERAL_SETTINGS_DETAILS, UPDATE_GENERAL_SETTINGS } from "../../actions/type";

const INITIAL_STATE = {
    details: [{}],
    general_details: [{}],
    is_loading: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING_GENERAL_SETTINGS:
            return { ...state, is_loading: true };
        case GET_GENERAL_SETTINGS_DETAILS:
            return { ...state, details: action.payload, is_loading: false };
        case UPDATE_GENERAL_SETTINGS:
            return { ...state, ext_details: action.payload, is_loading: false };
        default:
            return state;
    }
};
