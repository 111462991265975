import axios from "axios";
import { API_URL } from "../../utils/config";

export const getGeneralSettingcredentials = async (callBack) => {
    try {

        let config = {
            maxBodyLength: Infinity,
            headers: {
                'Content-Type': 'application/json'
            },
        };

        const res = await axios.get(`${API_URL}/user/generalSettings`, config);
        if (res?.data?.status === true) {
            const configData = res?.data?.data;
            callBack(configData);
        }
    } catch (error) {
        console.log(error, '---error---')
    }
}