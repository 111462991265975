import React, { useEffect, useRef, useState } from "react";
import closeIcon from "../../../assets/images/quix-close.png";
import dummyUserIcon from "../../../assets/images/dumy-user.jpg";
import { checkServerURL } from "../../../../helper/helper";
import { API_URL, localStoragePrefix } from "../../../../utils/config";
import { useDispatch } from "react-redux";
import {
  errorMessageModal,
  successMessageModal,
} from "../../../components/Modal/MessagesModal";
import {
  deleteUserByIdAdmin,
  getAllUserListAdmin,
  getUserByIdAdmin,
  updateUserByIdAdmin,
} from "../../../../redux/actions/admin/users";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import trashUserIcon from "../../../assets/images/tash-user-icon.png";
import { useNavigate } from "react-router-dom";
import { adminUserEditSachema } from "./Validations";

const EditUserProfile = ({ isShowModal, closeModal, userData }) => {
  const cardRef = useRef();
  const [formErrors, setFormErrors] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        closeModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeModal]);

  let fileFormat = ["image/jpg", "image/png"];
  const adminID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const userID = userData?.id && userData?.id;

  const initialState = {
    alt: "profile",
    src:
      !userData?.picture ||
        userData?.picture === "null" ||
        userData?.picture === null
        ? dummyUserIcon
        : checkServerURL(API_URL, userData?.picture),
  };

  const [uploadedProfile, setUploadedProfile] = useState(null);
  const [{ alt, src }, setPreview] = useState(initialState);
  const navigate = useNavigate();

  const handleChangeProfile = (event) => {
    const { files } = event.target;
    let fileType = files?.[0]?.type;
    if (fileFormat.indexOf(fileType) > -1) {
      setPreview(
        files.length
          ? {
            src: URL.createObjectURL(files[0]),
            alt: files[0].name,
          }
          : initialState
      );
      setUploadedProfile(files[0]);
    } else {
      errorMessageModal(
        "Unsupported Image Format",
        "Please upload a PNG or JPG file."
      );
    }
  };

  const [editDetails, setEditDetails] = useState({
    name: "",
    email: "",
    password: "",
    stripe_acc_id: "",
    role: "",
    status: "",
  });

  useEffect(() => {
    setEditDetails({
      name: userData?.name,
      email: userData?.email,
      password: null,
      stripe_acc_id: userData?.stripe_acc_id,
      role: userData?.role,
      status: userData?.status,
    });
    setPreview(initialState);
    setUploadedProfile(userData?.picture);
  }, [userData]);

  const handleChangeUserDetails = (e) => {
    const { name, value } = e.target;
    setEditDetails((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [loadingStatus, setLoadingStatus] = useState(false);
  const dispatch = useDispatch();

  const handleSubmitDetail = () => {
    const payload = {
      name: editDetails?.name.trim(),
      email: editDetails?.email,
      picture: uploadedProfile,
      user_id: Number(userID),
      admin_id: Number(adminID),
      role: editDetails?.role,
      status: editDetails?.status,
    };
    if (adminUserEditSachema({ formData: payload, setFormErrors })) {
      setLoadingStatus(true);
      dispatch(
        updateUserByIdAdmin(payload, (type, res) => {
          if (type === "success") {
            closeModal(false);
            setLoadingStatus(false);
            successMessageModal(
              "User Edited Successfully.",
              "User details have been updated successfully."
            );
            dispatch(getAllUserListAdmin(Number(adminID)));
            const userPayload = {
              adminID: adminID,
              id: userID,
            };
            dispatch(getUserByIdAdmin(userPayload, (type, res) => { }));
          } else if (type === "fail") {
            setLoadingStatus(false);
            errorMessageModal("User Edit", res?.message);
          }
        })
      );
    }
  };

  const [loading, setLoading] = useState(false);
  const [showConfirmUserDeleteModal, setShowConfirmUserDeleteModal] =
    useState(false);

  // handle delete user
  const handleDeleteUser = (res) => {
    if (res === true) {
      setLoading(true);
      setShowConfirmUserDeleteModal(false);
      const payload = {
        id: userID,
        admin_id: Number(adminID),
      };
      dispatch(
        deleteUserByIdAdmin(payload, (type, res) => {
          if (type === "success") {
            successMessageModal(
              "User Account Deleted successful.",
              "Successful delete a user account."
            );
            dispatch(getAllUserListAdmin(Number(adminID)));
            navigate("/admin/manage-users");
            setLoading(false);
          } else if (type === "fail") {
            setLoading(false);
            errorMessageModal("Delete User", res?.message);
          }
        })
      );
    }
  };

  return (
    <div id="user-card" className="user-card admin-popup-modal" ref={cardRef}>
      <div className="close-admin-popup" onClick={() => closeModal(false)}>
        <img src={closeIcon} />
      </div>
      <div className="user-card-header d-flex justify-content-between">
        <h5 className="title">Edit User Profile</h5>
        <div className="user-card-action d-flex gap-2">
          <button
            className="btn card-action-btn theme-btn-outline"
            onClick={() => closeModal(false)}
          >
            Closed
          </button>
          <button
            className="btn card-action-btn theme-blue-btn"
            disabled={loadingStatus}
            onClick={() => handleSubmitDetail()}
          >
            Save changes
          </button>
        </div>
      </div>
      <div className="user-card-content">
        <div className="content-column">
          <p className="card-details-lable">Cover</p>
          <div className="card-details-box d-flex gap-3">
            <div className="user-profile">
              <img src={src} alt={alt} />
              <input
                onChange={(e) => handleChangeProfile(e)}
                type="file"
                className="upload-user-image"
                accept={fileFormat.join(",")}
              />
            </div>
            <div className="upload-profile-sec">
              <div className="upload-profile-sec-btns d-flex gap-2">
                <div className="position-relative">
                  <button className="upload-profile-btn btn">
                    Upload photo
                  </button>
                  <input
                    onChange={(e) => handleChangeProfile(e)}
                    type="file"
                    className="upload-user-image"
                    accept={fileFormat.join(",")}
                  />
                </div>
                <button
                  className="remove-profile-btn btn"
                  onClick={() => {
                    setPreview({ alt: "profile", src: dummyUserIcon });
                    setUploadedProfile(null);
                  }}
                >
                  Remove photo
                </button>
              </div>
              <p>at least 132 x 132px PNG or JPG file.</p>
            </div>
          </div>
        </div>
        <div className="content-column mt-2">
          <div className="row">
            <div className="mb-2 quix-form-filed col-md-6">
              <label className="col-form-label" htmlFor="name">
                Full Name
              </label>
              <input
                type="text"
                name="name"
                className="form-control mt-1"
                id="name"
                defaultValue={userData?.name}
                onChange={(e) => handleChangeUserDetails(e)}
                aria-describedby="name"
                maxLength={255}
              />
              {formErrors?.nameError && (
                <p className="text-danger account-dec m-0">
                  {formErrors?.nameError}
                </p>
              )}
            </div>
            <div className="mb-2 quix-form-filed col-md-6">
              <label className="col-form-label" htmlFor="email">
                Email ID
              </label>
              <input
                type="text"
                name="email"
                className="form-control mt-1"
                id="email"
                defaultValue={userData?.email}
                onChange={(e) => handleChangeUserDetails(e)}
                aria-describedby="email"
                readOnly
                maxLength={255}
              />
              {formErrors?.emailError && (
                <p className="text-danger account-dec m-0">
                  {formErrors?.emailError}
                </p>
              )}
            </div>
          </div>
        </div>
        <hr className="divider"></hr>
        <div className="content-column mt-3">
          <p className="title mb-2">Account details</p>
          <div className="row">
            <div className="mb-2 quix-form-filed col-md-6">
              <label className="col-form-label" htmlFor="quix-email">
                Log in Email ID
              </label>
              <input
                type="text"
                name="email"
                className="form-control mt-1"
                id="quix-email"
                value={userData?.email}
                aria-describedby="quix-email"
                readOnly
                maxLength={255}
              />
            </div>
            <div className="mb-2 quix-form-filed col-md-6">
              <label className="col-form-label" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                name="password"
                className="form-control mt-1"
                id="password"
                value={userData?.password}
                aria-describedby="quix-name"
                readOnly
                maxLength={255}
              />
            </div>
          </div>
        </div>
        <hr className="divider"></hr>
        <div className="content-column delete-account-column mt-3">
          <p className="title mb-2">Delete user account?</p>
          <span>
            Deleting user account will remove all your content access. This
            action cannot be undone.
          </span>
          <button
            onClick={() =>
              setShowConfirmUserDeleteModal(!showConfirmUserDeleteModal)
            }
            className="btn btn-danger mt-3 d-block"
            disabled={loading}
          >
            Delete user account
          </button>
        </div>
        {showConfirmUserDeleteModal && (
          <ConfirmationModal
            isShowModal={showConfirmUserDeleteModal}
            closeModal={setShowConfirmUserDeleteModal}
            callBack={handleDeleteUser}
            isLoading={false}
            isDisabled={false}
            icon={trashUserIcon}
            btnText={"Continue"}
            messageTitle={<>Are You Sure to Delete the User?</>}
            description={
              <>
                Proceed with before taking any steps
                <br /> to delete a user account.
              </>
            }
          />
        )}
      </div>
    </div>
  );
};

export default EditUserProfile;
