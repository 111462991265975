import React from "react";
import sucessMessageImg from "../../assets/images/quix-success.png";

const SuccessVerifyModal = ({
    msgImg,
    title,
    message,
    btnTitle,
    btnClass,
    btnCallBack,
    closeModal,
}) => {
    return (
        <div className="success-modal-outer">
            <></>
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: "block",
                }}
            ></div>
            <div id="quix-dashboard-popup">
                <div className="quix-dashboard-popup-inner">
                    {title && (
                        <div className="quix-dashboard-popup-title">
                            <div className="quix-dashboard-popup-title-inner">
                                <span>{title}</span>
                            </div>
                        </div>
                    )}
                    <div className="quix-dashboard-popup-body">
                        <div className="quix-dashboard-popup-body-inner">
                            {msgImg && (
                                <div className="success-message-img">
                                    <img src={msgImg} height={"100%"} width={"100%"} />
                                </div>
                            )}
                            {message && <div className="text-message-outer">{message}</div>}
                            {btnTitle && (
                                <div className="success-modal-btns">
                                    <button onClick={(e) => btnCallBack(e)} className={`btn ${btnClass}`}>{btnTitle}</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SuccessVerifyModal.defaultProps = {
    msgImg: sucessMessageImg,
    title: "",
    message: "",
    btnTitle: "",
    btnClass: "",
    closeModal: "",
    btnCallBack: function () { }
};

export default SuccessVerifyModal;
