import React from 'react';

const MediaContentCard = ({ src, title, duration }) => {
    return (
        <div id="media-content-card" className='d-flex'>
            <div className='media-card-banner'>
                <img src={src} />
            </div>
            <div className='media-content'>
                <p className='title text-clip'>{title}</p>
                <span className='duration'>{duration}</span>
            </div>
        </div>
    );
};

export default MediaContentCard;