import React, { useState } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import { ToastContainer } from "react-toastify";
import LoadingPage from "../../views/loading-page/LoadingPage";

const AdminLayout = ({ children, pageTitle }) => {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <div className="quix-admin-layout-wrapper admin-fonts">
      <ToastContainer autoClose={20000} />
      <div className="quix-admin-layout">
        <div className="quix-admin-layout-inner">
          <div className="quix-admin-sidebar-outer">
            <Sidebar pageTitle={pageTitle} isLoading={setIsLoading} />
          </div>
          {isLoading && (
            <LoadingPage />
          )}
          <div className="quix-admin-layout-content">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
