// login form validations
export const loginSchema = ({ formData, setFormErrors }) => {
    const { email, password } = formData;
    let regex =
        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let valid = true;
    const errors = {
        emailError: "",
        passwordError: "",
    };

    if (!email) {
        errors.emailError = "Email cannot be empty!";
        valid = false;
    } else if (!regex.test(email)) {
        errors.emailError = "Invalid email address!";
        valid = false;
    }

    if (!password) {
        errors.passwordError = "Password cannot be empty!";
        valid = false;
    } else if (password.length < 8 || password.length > 20) {
        errors.passwordError = "Password must be between 8 and 20 characters long!";
        valid = false;
    } else if (/\s/.test(password)) {
        errors.passwordError = "Password cannot contain spaces!";
        valid = false;
    } else if (
        !/(?=.*[A-Z])/.test(password) ||
        !/(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~])/.test(password)
    ) {
        errors.passwordError =
            "Password must include at least one uppercase letter and one special character!";
        valid = false;
    } else if (!/(?=.*\d)/.test(password)) {
        errors.passwordError =
            "Password must include at least one numeric character!";
        valid = false;
    } else if (!/(?=.*[a-zA-Z])/.test(password)) {
        errors.passwordError =
            "Password must include at least one alphabetic character!";
        valid = false;
    }
    setFormErrors(errors);
    return valid;
};

// forget password form validations
export const forgetSchema = ({ formData, setFormErrors }) => {
    const { email } = formData;
    let regex =
        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let valid = true;
    const errors = {
        emailError: "",
    };

    if (!email) {
        errors.emailError = "Email cannot be empty!";
        valid = false;
    } else if (!regex.test(email)) {
        errors.emailError = "Invalid email address!";
        valid = false;
    }

    setFormErrors(errors);
    return valid;
};

// forget password form validations
export const createNewpasswordSchema = ({ formData, setFormErrors }) => {
    const { password, confirmPassword } = formData;
    let valid = true;
    const errors = {
        passwordError: "",
        confirmPasswordError: "",
    };

    if (!password) {
        errors.passwordError = "Password cannot be empty!";
        valid = false;
    } else if (!confirmPassword) {
        errors.confirmPasswordError = "Confirm Password cannot be empty!";
        valid = false;
    } else if (password !== confirmPassword) {
        errors.confirmPasswordError = "Passwords does not match!";
        valid = false;
    } else if (password.length < 8 || password.length > 20) {
        errors.passwordError = "Password must be between 8 and 20 characters long!";
        valid = false;
    } else if (/\s/.test(password)) {
        errors.passwordError = "Password cannot contain spaces!";
        valid = false;
    } else if (
        !/(?=.*[A-Z])/.test(password) ||
        !/(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~])/.test(password)
    ) {
        errors.passwordError =
            "Password must include at least one uppercase letter and one special character!";
        valid = false;
    } else if (!/(?=.*\d)/.test(password)) {
        errors.passwordError =
            "Password must include at least one numeric character!";
        valid = false;
    } else if (!/(?=.*[a-zA-Z])/.test(password)) {
        errors.passwordError =
            "Password must include at least one alphabetic character!";
        valid = false;
    }

    setFormErrors(errors);
    return valid;
};

// signup form validations
export const signUpSchema = ({ formData, setFormErrors }) => {
    const { name, email, password, confirmPassword } = formData;
    let regex =
        /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    const passwordRegex = /^(?!.*\s).*$/;

    let valid = true;
    const errors = {
        nameError: "",
        emailError: "",
        passwordError: "",
        confirmPasswordError: "",
    };

    if (!name) {
        errors.nameError = "Name cannot be empty!";
        valid = false;
    }

    if (!email) {
        errors.emailError = "Email cannot be empty!";
        valid = false;
    } else if (!regex.test(email)) {
        errors.emailError = "Invalid email address!";
        valid = false;
    }

    if (!password) {
        errors.passwordError = "Password cannot be empty!";
        valid = false;
    }

    if (!confirmPassword) {
        errors.confirmPasswordError = "Confirm Password cannot be empty!";
        valid = false;
    } else if (password !== confirmPassword) {
        errors.confirmPasswordError = "Passwords does not match!";
        valid = false;
    } else if (password.length < 8 || password.length > 20) {
        errors.passwordError = "Password must be between 8 and 20 characters long!";
        valid = false;
    } else if (/\s/.test(password)) {
        errors.passwordError = "Password cannot contain spaces!";
        valid = false;
    } else if (
        !/(?=.*[A-Z])/.test(password) ||
        !/(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>?`~])/.test(password)
    ) {
        errors.passwordError =
            "Password must include at least one uppercase letter and one special character!";
        valid = false;
    } else if (!/(?=.*\d)/.test(password)) {
        errors.passwordError =
            "Password must include at least one numeric character!";
        valid = false;
    } else if (!/(?=.*[a-zA-Z])/.test(password)) {
        errors.passwordError =
            "Password must include at least one alphabetic character!";
        valid = false;
    }

    setFormErrors(errors);
    return valid;
};
