import {
  LOADING,
  NOT_FOUND_MEMBERSHIP,
  GET_ALL_MEMBERSHIP,
  ADD_MEMBERSHIP,
  UNSUBSCRIBE_MEMBERSHIP,
} from "../actions/type";

const INITIAL_STATE = {
  list: [],
  response: [],
  is_loading: false,
  not_found: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING:
      return { ...state, is_loading: true };
    case GET_ALL_MEMBERSHIP:
      return { ...state, list: action.payload, is_loading: true };
    case ADD_MEMBERSHIP:
      return { ...state, response: action.payload, is_loading: true };
    case UNSUBSCRIBE_MEMBERSHIP:
      return { ...state, response: action.payload, is_loading: true };
    case NOT_FOUND_MEMBERSHIP:
      return { ...state, not_found: true, is_loading: false };
    default:
      return state;
  }
};
