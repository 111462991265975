import { LOADING_STATISTICS, GET_ALL_STATISTICS } from "../../actions/type";
const INITIAL_STATE = {
    list: [{}],
    is_loading: false,
    not_found: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOADING_STATISTICS:
            return { ...state, is_loading: true };
        case GET_ALL_STATISTICS:
            return {
                ...state,
                list: action.payload,
                is_loading: false,
                not_found: false,
            };
        default:
            return state;
    }
};
