import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import CsvDownloader from "react-csv-downloader";
import downloadIcon from "../../assets/images/donwload-tbl-Icon.png";
import resetIcon from "../../assets/images/reset-icon.png";
import preIcon from "../../assets/images/pre-icon.png";
import nextIcon from "../../assets/images/next-icon.png";
import shortingIcon from "../../assets/images/arrow-swap.png";
import Loader from "../../../components/Loader/Loader";
import NotFound from "../../../components/NotFound/NotFound";

const Table = ({
  tableData,
  tableColumns,
  searchCallBack,
  resetCallBack,
  csvData,
  isLoading,
  notFound,
  setShowItems,
  searchBar,
  isShowItemsHide,
  isDownloadHide,
  tableType,
  actionBtn,
  showAction
}) => {
  const [data, setData] = useState([]);
  const [currentPageNum, setCurrentPageNum] = useState(() => {
    const storedPage = localStorage.getItem(`pageCount_${tableType}`);
    return storedPage ? parseInt(storedPage, 10) : 0;
  });
  const [showPage, setShowPage] = useState(10);

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    const storedPage = localStorage.getItem(`pageCount_${tableType}`);
    setCurrentPageNum(storedPage ? parseInt(storedPage, 10) : 0);
  }, [tableType]);

  const columns = useMemo(() => tableColumns, [tableColumns]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex, pageSize },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    setPageSize,
    pageOptions,
    pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPageNum, pageSize: showPage },
      manualPagination: false,
      pageCount: Math.ceil(data.length / showPage),
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(showPage);
  }, [showPage, setPageSize]);

  useEffect(() => {
    localStorage.setItem(`pageCount_${tableType}`, pageIndex);
    setCurrentPageNum(pageIndex);
  }, [pageIndex, tableType]);

  useEffect(() => {
    gotoPage(currentPageNum);
  }, [currentPageNum, gotoPage]);

  const [previousUsers, setPreviousUsers] = useState([]);

  useEffect(() => {
    const currentUsers = page.map((row) => row.original);
    if (JSON.stringify(currentUsers) !== JSON.stringify(previousUsers)) {
      setShowItems(currentUsers);
      setPreviousUsers(currentUsers);
    }
  }, [page, setShowItems, previousUsers]);

  const [searchText, setSearchText] = useState("");
  const handleSearch = () => {
    searchCallBack(searchText);
    gotoPage(0);
  };

  const paginationRange = () => {
    const totalPages = pageOptions.length;
    const range = [];
    const maxPagesToShow = 5;
    const start = Math.max(0, pageIndex - Math.floor(maxPagesToShow / 2));
    const end = Math.min(totalPages - 1, start + maxPagesToShow - 1);

    if (start > 0) {
      range.push(0);
      if (start > 1) range.push("...");
    }

    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    if (end < totalPages - 1) {
      if (end < totalPages - 2) range.push("...");
      range.push(totalPages - 1);
    }

    return range;
  };

  return (
    <div className="table-container">
      <div className="quix-data-table card">
        <div className="card-header justify-content-end">
          <div className="table-header d-flex align-items-center gap-2">
            {showAction && (
              <div className="action-btn d-flex gap-2">{actionBtn}</div>
            )}
            {isShowItemsHide && (
              <div className="show-table-record">
                <select
                  className="quix-select-show-record btn"
                  value={showPage}
                  onChange={(e) => setShowPage(Number(e.target.value))}
                >
                  {[10, 25, 50, 100]?.map((size) => (
                    <option key={size} value={size}>
                      Show {size}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {searchBar && (
              <div className="search-bar d-flex align-items-center">
                <input
                  type="text"
                  name="search"
                  placeholder="Search"
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" && searchText.length > 0)
                      handleSearch();
                  }}
                  maxLength={255}
                />
                <button className="btn search-users-btn" onClick={handleSearch}>
                  Search
                </button>
              </div>
            )}

            <div className="table-header-actions d-flex gap-2">
              {searchBar && (
                <button
                  className="btn table-header-action-btn"
                  title="Reset"
                  onClick={() => {
                    resetCallBack();
                    setSearchText("");
                  }}
                >
                  <img src={resetIcon} alt="Reset" />
                </button>
              )}
              {isDownloadHide && (
                <CsvDownloader
                  datas={csvData?.data}
                  filename={csvData?.filename}
                  extension=".csv"
                >
                  <button
                    className="btn table-header-action-btn"
                    title="Download"
                  >
                    <img src={downloadIcon} alt="Download" />
                  </button>
                </CsvDownloader>
              )}
            </div>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="quix-table-data-outer">
            <table {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, ind) => (
                      <th
                        key={ind}
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted ? (
                            <img
                              className="shorting-img"
                              src={shortingIcon}
                              alt="Sort"
                            />
                          ) : null}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {isLoading ? (
                  <tr>
                    <td colSpan={columns?.length}>
                      <Loader />
                    </td>
                  </tr>
                ) : notFound ? (
                  <tr>
                    <td colSpan={columns?.length}>
                      <NotFound />
                    </td>
                  </tr>
                ) : (
                  page?.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()} key={i}>
                        {row.cells.map((cell, indexCell) => (
                          <td key={indexCell} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-between align-items-center">
          <span className="page-countings">
            Page {pageIndex + 1} of {pageOptions.length}
          </span>
          <div className="table-paginations">
            <button
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
              className="quix-table-page-previus-btn quix-table-page-btn"
            >
              <img src={preIcon} alt="First" />
            </button>
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="quix-table-page-btn"
            >
              Previous
            </button>
            {paginationRange().map((item, index) =>
              item === "..." ? (
                <button
                  key={index}
                  className="quix-table-page-btn"
                  style={{ margin: "0 5px" }}
                >
                  {item}
                </button>
              ) : (
                <button
                  key={index}
                  className={`quix-table-page-btn ${pageIndex === item ? "active" : ""
                    }`}
                  onClick={() => gotoPage(item)}
                >
                  {item + 1}
                </button>
              )
            )}
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="quix-table-page-btn"
            >
              Next
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              className="quix-table-page-next-btn quix-table-page-btn"
            >
              <img src={nextIcon} alt="Last" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Table.defaultProps = {
  tableData: [],
  tableColumns: [],
  searchCallBack: () => { },
  resetCallBack: () => { },
  setShowItems: () => { },
  isShowItemsHide: true,
  isDownloadHide: true,
  csvData: {},
  isLoading: false,
  notFound: false,
  searchBar: false,
  tableType: "",
  actionBtn: "",
  showAction: false
};

export default Table;
