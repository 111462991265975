import React from "react";
import vidCameraIcon from "../../../assets/images/home/ocr-vid-camra.png";
import editIcon from "../../../assets/images/home/ocr-edit_icon.png";
import shareIcon from "../../../assets/images/home/ocr-share.png";
import menuIcon from "../../../assets/images/home/ocr-menu.png";
import activatityImg2 from "../../../assets/images/home/ocr-activatity2.png";

const ScreenRecordingGame = () => {
  return (
    <div className="container-fluid screen_recording_game">
      <div className="container" id="screen-recording-tab">
        <div className="row">
          <div className="section_title">
            <h2>Take your Screen Recording <span className="heading-badge-text">game up a notch!</span></h2>
            <p>
              Captivate, Educate Or Entertain. Make People Engage With Your
              Content Like Never Before
            </p>
          </div>
          <div className="col-lg-6 conversations-mid">
            <span className="take_your_screen">
              <img src={vidCameraIcon} />
              <div className="conversations-descriptions">
                <h5>Capture screen like a boss!</h5>
                <p>Snap Full Screen Area, Visible Area Or Select As You Wish</p>
              </div>
            </span>
            <span className="take_your_screen">
              <img src={editIcon} />
              <div className="conversations-descriptions">
                <h5>Edit &amp; Annotate your heart's content</h5>
                <p>
                  Crop It, Add Text, Highlights, Blur &amp; Other Effects With
                  Ease.
                </p>
              </div>
            </span>
            <span className="take_your_screen">
              <img src={shareIcon} />
              <div className="conversations-descriptions">
                <h5>Be ready to share a flick</h5>
                <p>Upload To Cloud, Share Via Link Or By Email</p>
              </div>
            </span>
            <span className="take_your_screen">
              <img src={menuIcon} />
              <div className="conversations-descriptions">
                <h5>All media roads lead to dashboard!</h5>
                <p>View, Access, Manage Screenshots Through Dashboard</p>
              </div>
            </span>
          </div>
          <div className="col-lg-6 d-flex flex-column justify-content-center conversations-mid screenshots">
            <img src={activatityImg2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenRecordingGame;
