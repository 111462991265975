import React, { useEffect, useMemo, useState } from "react";
import NotFound from "../../../../components/NotFound/NotFound";
import Loader from "../../../../components/Loader/Loader";
import { connect, useDispatch } from "react-redux";
import { dashboardOCRreportAdmin } from "../../../../redux/actions/admin/dashboardReport";
import preIcon from "../../../assets/images/pre-icon.png";
import nextIcon from "../../../assets/images/next-icon.png";
import { useTable, useSortBy, usePagination, useFilters } from "react-table";

const OcrReportTable = ({
    ocr_report,
    ocrReportLoading,
    ocrReportNotFound,
}) => {
    const [showOcrList, setOcrList] = useState(31);
    const ocrReportData = ocr_report?.data
        ? ocr_report?.data?.slice(0, showOcrList)
        : [];

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(dashboardOCRreportAdmin(183));
    }, []);

    const totals = useMemo(() => {
        if (!ocrReportData)
            return { 200: 0, 300: 0, 400: 0, 404: 0, 500: 0, 503: 0 };

        return ocrReportData?.reduce(
            (acc, item) => {
                const date = Object?.keys(item)[0];
                const data = item[date];
                Object?.keys(acc)?.forEach((status) => {
                    acc[status] += Number(data[status] || 0);
                });
                return acc;
            },
            { 200: 0, 300: 0, 400: 0, 404: 0, 500: 0, 503: 0 }
        );
    }, [ocrReportData]);

    const handleChartfilter = (e) => {
        let count = e?.target?.value;
        setOcrList(count);
    };

    const tableColumns = React.useMemo(
        () => [
            { Header: "#", accessor: (row, i) => i + 1 },
            { Header: "Date", accessor: (row) => Object.keys(row)[0] },
            { Header: "200", accessor: (row) => row[Object.keys(row)[0]]["200"] },
            { Header: "300", accessor: (row) => row[Object.keys(row)[0]]["300"] },
            { Header: "400", accessor: (row) => row[Object.keys(row)[0]]["400"] },
            { Header: "404", accessor: (row) => row[Object.keys(row)[0]]["404"] },
            { Header: "500", accessor: (row) => row[Object.keys(row)[0]]["500"] },
            { Header: "503", accessor: (row) => row[Object.keys(row)[0]]["503"] },
        ],
        []
    );

    const [data, setData] = useState([]);
    useEffect(() => {
        setData(ocrReportData);
    }, [ocr_report, showOcrList]);

    const columns = useMemo(() => tableColumns, [tableColumns]);
    const showPage = 10;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state: { pageIndex },
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        gotoPage,
        pageOptions,
        pageCount,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: showPage },
            manualPagination: false,
            pageCount: Math.ceil(data.length / showPage),
        },
        useFilters,
        useSortBy,
        usePagination
    );

    const paginationRange = () => {
        const totalPages = pageOptions.length;
        const range = [];
        const maxPagesToShow = 5;
        const start = Math.max(0, pageIndex - Math.floor(maxPagesToShow / 2));
        const end = Math.min(totalPages - 1, start + maxPagesToShow - 1);

        if (start > 0) {
            range.push(0);
            if (start > 1) range.push("...");
        }

        for (let i = start; i <= end; i++) {
            range.push(i);
        }

        if (end < totalPages - 1) {
            if (end < totalPages - 2) range.push("...");
            range.push(totalPages - 1);
        }
        return range;
    };

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between">
                <h5 className="m-0 p-2">30-Day OCR Analysis and Performance Summary</h5>
                <div className="filter-btn">
                    <select
                        className="form-select filter-select-btn btn"
                        onChange={(e) => handleChartfilter(e)}
                    >
                        <option value={31}>Monthly</option>
                        <option value={92}>Quarterly</option>
                        <option value={183}>Semi-Annually</option>
                    </select>
                </div>
            </div>
            <div className="card-body">
                <table {...getTableProps()} className="table table-bordered mb-0">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {ocrReportLoading ? (
                            <tr>
                                <td colSpan={8}>
                                    <Loader />
                                </td>
                            </tr>
                        ) : ocrReportNotFound ? (
                            <tr>
                                <td colSpan={8}>
                                    <NotFound />
                                </td>
                            </tr>
                        ) : (
                            <>
                                {page.map((row) => {
                                    prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map((cell) => (
                                                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                            ))}
                                        </tr>
                                    );
                                })}
                                <tr>
                                    <th scope="row">Total</th>
                                    <td></td>
                                    <td>{totals["200"]}</td>
                                    <td>{totals["300"]}</td>
                                    <td>{totals["400"]}</td>
                                    <td>{totals["404"]}</td>
                                    <td>{totals["500"]}</td>
                                    <td>{totals["503"]}</td>
                                </tr>
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="card-footer d-flex justify-content-between align-items-center">
                <span className="page-countings">
                    Page {pageIndex + 1} of {pageOptions?.length}
                </span>
                <div className="table-paginations">
                    <button
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                        className="quix-table-page-previus-btn quix-table-page-btn"
                    >
                        <img src={preIcon} alt="First" />
                    </button>
                    <button
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                        className="quix-table-page-btn"
                    >
                        Previous
                    </button>
                    {paginationRange().map((item, index) =>
                        item === "..." ? (
                            <button
                                key={index}
                                className="quix-table-page-btn"
                                style={{ margin: "0 5px" }}
                            >
                                {item}
                            </button>
                        ) : (
                            <button
                                key={index}
                                className={`quix-table-page-btn ${pageIndex === item ? "active" : ""
                                    }`}
                                onClick={() => gotoPage(item)}
                            >
                                {item + 1}
                            </button>
                        )
                    )}
                    <button
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                        className="quix-table-page-btn"
                    >
                        Next
                    </button>
                    <button
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                        className="quix-table-page-next-btn quix-table-page-btn"
                    >
                        <img src={nextIcon} alt="Last" />
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    ocrReportLoading: state?.dashboard_report?.ocr_report_loading,
    ocr_report: state?.dashboard_report?.ocr_report,
    ocrReportNotFound: state?.dashboard_report?.ocr_report_not_found,
});

export default connect(mapStateToProps, { dashboardOCRreportAdmin })(
    OcrReportTable
);
