import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  manageVideoDetails,
  manageEditVideo,
} from "../../redux/actions/manageVideos";
import { VideoEditor } from "./editor";
import { useNavigate, useParams } from "react-router-dom";
import { API_URL, localStoragePrefix } from "../../utils/config";
import { checkServerURL, failureMessagePopup, successMessagePopup } from "../../helper/helper";

const EditVideo = ({ videoDetails, userDetails }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const userID = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
  const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");

  useEffect(() => {
    if (!id) return;
    const payload = {
      id: id,
      user_id: userID,
      access_token: accessToken
    }
    dispatch(manageVideoDetails(payload, () => { }));
  }, [id, dispatch]);

  const videDetailObj = videoDetails && videoDetails;
  const videoUrl =
    videDetailObj?.modified_path && checkServerURL(API_URL, videDetailObj?.modified_path);
  const videoName = videDetailObj?.name && videDetailObj?.name;
  const videoID = videDetailObj?.id && videDetailObj?.id;
  const videoDuration =
    videDetailObj?.file_duration && videDetailObj?.file_duration;
  const videoDataJSON = videDetailObj?.json_data && videDetailObj?.json_data;
  const userDatailObj = userDetails?.data && userDetails?.data;

  const getEditedVideoDetails = (data, callBack) => {
    const payload = {
      id: videoID,
      access_token: accessToken,
      user_id: Number(userID),
      ...data,
    };
    dispatch(
      manageEditVideo(payload, (type, res) => {
        if (type === "success") {
          successMessagePopup("Success!", "Video Edited successfully.");
          if (res.gifPaths && res.gifPaths.length > 0) {
            for (var i = 0; i < res.gifPaths.length; i++) {
              let el = document.createElement("a");
              el.setAttribute("href", checkServerURL(API_URL, res.gifPaths[i]));
              el.setAttribute("download", 'download');
              el.setAttribute("target", '_blank');
              document.body.appendChild(el);
              try {
                el.click();
              }
              catch (err) {
                alert(err + "Please try to download in full screen mode as host page is retricting the pdf download.");
              }
              el.remove();
            }
          }

          setTimeout(() => {
            navigator("/dashboard#videos");
          }, 2000);
          callBack(res);
        } else if (type === "fail") {
          failureMessagePopup('Error message', res?.message)
          callBack(null);
        }
      })
    );
  };

  useEffect(() => {
    const videoParam = {
      videoElementID: "quix-video-editor",
      videoName: videoName,
      videoURL: videoUrl,
      videoDuration: videoDuration,
      videoDataJSON: videoDataJSON,
      userDetails: userDatailObj,
    };
    new VideoEditor(videoParam, getEditedVideoDetails);
  }, [videoDataJSON]);

  return (
    <div>
      <div id="quix-video-editor"></div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  videoDetails: state?.videos?.edited_video,
  userDetails: state?.auth?.user_details
});

export default connect(mapStateToProps, { manageVideoDetails })(EditVideo);
