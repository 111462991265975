import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import logoImg from "../../assets/images/home/ocr-logo.png";
import googleIcon from "../../assets/images/google-icon.png";
import faceBookIcon from "../../assets/images/facebook-icon.png";
import linkedInIcon from "../../assets/images/linkedin-icon.png";
import twitterIcon from "../../assets/images/twitter-x-icon.png";
import AdSenseAd from "../../components/Adsense/adSenseAd";
import showPassIcon from "../../assets/images/eye-pass-icon.png";
import hidePassIcon from "../../assets/images/hidden-pass-icon.png";
import {
  LoginSocialGoogle,
  LoginSocialFacebook,
  LoginSocialLinkedin,
  LoginSocialTwitter,
} from "reactjs-social-login";
import {
  loginUser,
  loginWithLinkedIn,
} from "../../redux/actions/authentication";
import { failureMessagePopup, redirectURL } from "../../helper/helper";
import { loginSchema } from "./components/Validation";
import { getGeneralSettingcredentials } from "../../redux/actions/configServices";
import { WEB_URL } from "../../utils/config";

const Login = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [configObj, setConfigObj] = useState({});
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    getGeneralSettingcredentials((res) => {
      const googleDetails = res?.google && res?.google;
      const facebookDetails = res?.facebook && res?.facebook;
      const linkedinDetails = res?.linkedin && res?.linkedin;
      const twitterDetails = res?.twitter && res?.twitter;

      setConfigObj({
        google: googleDetails,
        facebook: null,
        linkedin: linkedinDetails,
        twitter: null,
      });
    });
  }, []);

  const handleSubmit = () => {
    if (loginSchema({ formData, setFormErrors })) {
      setLoading(true);
      dispatch(
        loginUser(formData, (type, res) => {
          if (type === "success") {
            setLoading(false);
            return (window.location.href = redirectURL());
          } else if (type === "failed") {
            failureMessagePopup("Error Message", res?.message);
            setLoading(false);
          }
        })
      );
    }
  };

  //login social media
  const manageSocialResponse = (response) => {
    let userData = null;
    if (response?.provider === "google" && response?.profile) {
      const { email, name, picture } = response?.profile;
      const payload = {
        name: name,
        email: email,
        picture: picture,
      };
      if (email && name) {
        userData = payload;
      }
    } else if (response?.provider === "facebook") {
      const { email, name } = response?.profile;
      const profileUrl = response?.profile?.picture?.data?.url;
      const payload = {
        name: name,
        email: email,
        picture: profileUrl,
      };
      if (email && name) {
        userData = payload;
      }
    } else if (response?.provider === "linkedin") {
      let token = response?.profile?.access_token;
      setLoading(true);
      loginWithLinkedIn(token, (type, res) => {
        if (type === "success") {
          const payload = {
            name: res?.data?.name,
            email: res?.data?.email,
            picture: res?.data?.picture,
          };
          dispatch(
            loginUser(payload, (type, res) => {
              if (type === "success") {
                setLoading(false);
                return (window.location.href = redirectURL());
              } else if (type === "failed") {
                failureMessagePopup("Error Message", res?.message);
                setLoading(false);
              }
            })
          );
        } else if (type === "failed") {
          failureMessagePopup("Error Message", res?.message);
          setLoading(false);
        }
      });
    }

    if (userData !== null) {
      setLoading(true);
      dispatch(
        loginUser(userData, (type, res) => {
          if (type === "success") {
            setLoading(false);
            return (window.location.href = redirectURL());
          } else if (type === "failed") {
            failureMessagePopup("Error Message", res?.message);
            setLoading(false);
          }
        })
      );
    }
  };

  return (
    <div className="login-page">
      <div id="quix-dashboard-wrapper">
        <div id="quix-dashboard-wrapper">
          <div id="quix-dashboard-inner">
            <div className="login-logo-outer">
              <div className="login-logo-inner">
                <Link to="/">
                  <img src={logoImg} />
                </Link>
              </div>
            </div>
            <div className="login-form-outer">
              <div className="login-form-inner">
                <span className="form-title">Welcome to Capios</span>
                <span className="form-subtitle">
                  To get started, please sign in
                </span>
              </div>
              <div className="login-with-details">
                <div className="custom-login-details">
                  <div className="custom-details-form card">
                    <div className="card-body">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <div className="quix-edit-email">
                          <input
                            type="text"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                            defaultValue={formData?.email}
                            maxLength={255}
                          />
                        </div>
                        {formErrors?.emailError && (
                          <p className="text-danger account-dec m-0">
                            {formErrors?.emailError}
                          </p>
                        )}
                      </div>
                      <div className="mb-3 user-password-filed">
                        <label htmlFor="passwordin" className="form-label">
                          Password
                          <span className="text-danger ps-1">*</span>
                        </label>
                        <Link className="float-end" to={"/forgot-password"}>
                          Forgot password
                        </Link>
                        <div className="password-eyes quix-edit-password">
                          <input
                            type={!showPassword ? "password" : "text"}
                            className="form-control"
                            id="passwordin"
                            name="password"
                            placeholder="Enter your password"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                password: e.target.value,
                              })
                            }
                            defaultValue={formData?.password}
                            maxLength={255}
                          />
                          <img
                            src={!showPassword ? showPassIcon : hidePassIcon}
                            height={"100%"}
                            width={"100%"}
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        </div>
                        {formErrors?.passwordError && (
                          <p className="text-danger account-dec m-0">
                            {formErrors?.passwordError}
                          </p>
                        )}
                      </div>
                      <div className="form-btn text-center">
                        <button
                          type="submit"
                          className="btn custom-details-btn login-btn"
                          onClick={() => handleSubmit()}
                        >
                          Login
                        </button>
                      </div>
                      <p className="account-dec text-center">
                        If you don't have an account!{" "}
                        <Link to="/sign-up">Sign up</Link>
                      </p>
                    </div>
                  </div>
                  <div className="login-devider position-relative my-5">
                    <hr />
                    <div className="more-option">
                      <span>Or</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="login-form-block">
                {configObj?.google?.google_client_id ? (
                  <LoginSocialGoogle
                    auto_select={false}
                    client_id={configObj?.google?.google_client_id}
                    onResolve={({ provider, data }) => {
                      manageSocialResponse({ provider, profile: data });
                    }}
                    cookiePolicy={"single_host_origin"}
                    scope={
                      "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
                    }
                    onReject={(err) => {
                      console.log(err, "err===>");
                    }}
                  >
                    <div className="login-item google-login">
                      <img src={googleIcon} title="Google" />
                      <span>Google</span>
                    </div>
                  </LoginSocialGoogle>
                ) : (
                  <div
                    className="login-item google-login"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={googleIcon} title="Google" />
                    <span>Google</span>
                  </div>
                )}
                {configObj?.facebook?.facebook_client_id ? (
                  <LoginSocialFacebook
                    false
                    appId={configObj?.facebook?.facebook_client_id}
                    onResolve={({ provider, data }) => {
                      manageSocialResponse({ provider, profile: data });
                    }}
                    fields="email, picture"
                    scope="public_profile, email"
                    returnScopes={true}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <div className="login-item facebook-login">
                      <img src={faceBookIcon} title="Facebook" />
                      <span>Facebook</span>
                    </div>
                  </LoginSocialFacebook>
                ) : (
                  <div
                    className="login-item facebook-login"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={faceBookIcon} title="Coming Soon" />
                    <span>Facebook</span>
                  </div>
                )}
                {/* Linkedin login */}
                {configObj?.linkedin?.linkedin_client_id ? (
                  <LoginSocialLinkedin
                    false
                    client_id={configObj?.linkedin?.linkedin_client_id}
                    client_secret={configObj?.linkedin?.linkedin_secret_key}
                    redirect_uri={`${WEB_URL}/auth/linkedin/callback`}
                    scope="openid,profile,email,w_member_social"
                    state={"xas1xcewe322dszdasqwe"}
                    onResolve={({ provider, data }) => {
                      manageSocialResponse({ provider, profile: data });
                    }}
                    onReject={(err) => {
                      console.log(err, "err---->");
                    }}
                  >
                    <div className="login-item linkedIn-login">
                      <img src={linkedInIcon} title="LinkedIn" />
                      <span>LinkedIn</span>
                    </div>
                  </LoginSocialLinkedin>
                ) : (
                  <div
                    className="login-item linkedIn-login"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={linkedInIcon} title="LinkedIn" />
                    <span>LinkedIn</span>
                  </div>
                )}
                {/* Twitter login */}
                {configObj?.twitter?.twitter_client_id ? (
                  <LoginSocialTwitter
                    false
                    client_id={configObj?.twitter?.twitter_client_id}
                    redirect_uri={`${WEB_URL}/auth/twitter/callback`}
                    onResolve={({ provider, data }) => {
                      manageSocialResponse(provider, data);
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <div className="login-item Twitter-login">
                      <img src={twitterIcon} title="Twitter(X)" />
                      <span>Twitter(X)</span>
                    </div>
                  </LoginSocialTwitter>
                ) : (
                  <div
                    className="login-item Twitter-login"
                    style={{ opacity: "0.5" }}
                  >
                    <img src={twitterIcon} title="Coming Soon" />
                    <span>Twitter(X)</span>
                  </div>
                )}
              </div>
              <AdSenseAd
                client="ca-pub-9210273506493361"
                slot=""
                format="auto"
                width={300} // Set your desired width
                height={250} // Set your desired height
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
