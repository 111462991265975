import React from "react";
import { uploadRecordingVideo } from "../../redux/actions/manageVideos";
import { useDispatch } from "react-redux";
import {
  failureMessagePopup,
  getVideoDuration,
  successMessagePopup,
} from "../../helper/helper";
import { useNavigate } from "react-router-dom";
import { localStoragePrefix } from "../../utils/config";

const UploadVideo = () => {
  const userID = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
  const accessToken = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");

  const dispatch = useDispatch();
  const navigator = useNavigate();
  const handleUploadVideo = (e) => {
    const file = e?.target.files[0];
    const fileName = file?.name;
    const fileType = file?.type;
    const fileSize = file?.size;
    var payload = {
      name: fileName,
      user_id: userID,
      file_size: fileSize,
      file: file,
      access_token: accessToken,
    };
    if (fileType?.indexOf("video") > -1) {
      function isNotWebmOrAvi(filename) {
        return !/(?:\.webm$|\.avi$)/i.test(filename);
      }
      const dispatchUpload = (payload) => {
        dispatch(
          uploadRecordingVideo(payload, (type, res) => {
            if (type === "success") {
              successMessagePopup(
                "Uploaded Successfully",
                "Video uploaded successfully!"
              );
              setTimeout(() => {
                navigator("/dashboard");
              }, 1000);
            } else if (type === "fail") {
              failureMessagePopup("Error message", res?.message);
            }
          })
        );
      };
      if (isNotWebmOrAvi(fileName)) {
        getVideoDuration(file, (res) => {
          payload.file_duration = res;
          dispatchUpload(payload);
        });
      } else {
        payload.file_duration = null;
        dispatchUpload(payload);
      }
    } else {
      failureMessagePopup(
        "Invalid format",
        "Only MP4, WEBM, AVI, MOV files allowed."
      );
    }
  };
  return (
    <div id="quix-dashboard-content">
      <div id="quix-upload-video">
        <div id="upload-custom-video-outer" style={{ display: "table" }}>
          <span className="upload-custom-video-area">
            Drop or Choose your Video
          </span>
          <input
            id="upload-custom-video"
            type="file"
            accept=".mp4,.webm,.avi,.mov"
            onChange={(e) => handleUploadVideo(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadVideo;
