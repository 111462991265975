import React, { useState } from "react";
import AdminLayout from "../../layout/adminLayout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { localStoragePrefix } from "../../../utils/config";
import {
  errorMessageModal,
  successMessageModal,
} from "../../components/Modal/MessagesModal";
import {
  addPricingPlanAdmin,
  getAllPricingPlansListAdmin,
} from "../../../redux/actions/admin/pricingPlans";
import dummyImg from "../../assets/images/dummy-image.jpg";
import { adminCreatePricingPlanSachema } from "./components/Validations";

const AddPricingPlan = () => {
  const dispatch = useDispatch();
  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    price: "",
    price_string: "",
    duration: "",
    status: "1",
    description: "",
    features: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Untititled
  const [unlimited, setUnlimted] = useState({
    status: false,
    screenshots: -1,
    videos: -1,
    message: "",
  });

  // Watermark
  const [watermark, setWatermark] = useState({
    status: false,
    message: "",
  });

  // UnlimitedLength
  const [unlimitedLength, setUnlimitedLength] = useState({
    status: false,
    length: -1,
    message: "",
  });

  // Gradient
  const [gradient, setGradient] = useState({
    status: false,
    message: "",
  });

  // Resolution
  const [resolution, setResolution] = useState({
    status: false,
    resolutions: {
      "360p": false,
      "480p": false,
      "720p": false,
      "1080p": false,
      "4K": false,
    },
    message: "",
  });

  // Recovery
  const [recovery, setRecovery] = useState({
    status: false,
    message: "",
  });

  // UserManagement
  const [userManagement, setUserManagement] = useState({
    status: false,
    message: "",
  });

  // Integrations
  const [integrations, setIntegrations] = useState({
    status: false,
    message: "",
  });

  // Download
  const [download, setDownload] = useState({
    status: false,
    formats: {
      videos: {
        webm: false,
        mp4: false,
      },
      screenshots: {
        png: false,
        jpg: false,
        pdf: false,
      },
    },
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const fileFormat = ["image/jpg", "image/jpeg", "image/png"];

  const initialState = {
    alt: "photo",
    src: dummyImg,
  };

  const [{ alt, src }, setPreview] = useState(initialState);
  const [showPhoto, setPhoto] = useState(null);

  const handleUploadImage = (e) => {
    let file = e.target.files;
    let fileType = file?.[0]?.type;
    if (fileFormat.indexOf(fileType) > -1) {
      setPreview(
        file?.length
          ? {
            src: URL.createObjectURL(file?.[0]),
            alt: file?.[0]?.name,
          }
          : initialState
      );
      setPhoto(file?.[0]);
    } else {
      errorMessageModal(
        "Unsupported File Format",
        "Please upload an image in PNG, JPEG, or JPG format"
      );
    }
  };

  const [formErrors, setFormErrors] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    const featuredObj = {
      Unlimited: unlimited,
      Watermark: watermark,
      UnlimitedLength: unlimitedLength,
      Gradient: gradient,
      Resolution: resolution,
      Recovery: recovery,
      UserManagement: userManagement,
      Integrations: integrations,
      Download: download,
    };
    const payload = {
      admin_id: Number(userID),
      price: formData?.price,
      picture: showPhoto,
      price_string: formData?.price_string,
      duration: formData?.duration,
      status: formData?.status,
      description: formData?.description,
      features: JSON.stringify(featuredObj),
    };
    if (adminCreatePricingPlanSachema({ formData: payload, setFormErrors })) {
      setLoading(true);
      dispatch(
        addPricingPlanAdmin(payload, (type, res) => {
          if (type === "success") {
            setLoading(false);
            successMessageModal(
              "Pricing Plan Successfully Created.",
              "Pricing plan has been created successfully."
            );
            setTimeout(() => {
              navigate("/admin/manage-pricing-plans");
            }, 2000);
            dispatch(getAllPricingPlansListAdmin(Number(userID)));
          } else if (type === "fail") {
            setLoading(false);
            errorMessageModal("Create Pricing Plan", res?.message);
          }
        })
      );
    }
  };

  const resetForm = () => {
    document.getElementById("create-pricing-plan-form").reset();
    // formdetails
    setFormData({
      price: "",
      price_string: "",
      duration: "",
      description: "",
      features: "",
    });

    // unlimited
    setUnlimted({
      status: false,
      screenshots: -1,
      videos: -1,
      message: "",
    });

    // Watermark
    setWatermark({
      status: false,
      message: "",
    });

    // UnlimitedLength
    setUnlimitedLength({
      status: false,
      length: -1,
      message: "",
    });

    // Gradient
    setGradient({
      status: false,
      message: "",
    });

    // Resolution
    setResolution({
      status: false,
      resolutions: {
        "360p": false,
        "480p": false,
        "720p": false,
        "1080p": false,
        "4K": false,
      },
      message: "",
    });

    // Recovery
    setRecovery({
      status: false,
      message: "",
    });

    // UserManagement
    setUserManagement({
      status: false,
      message: "",
    });

    // Integrations
    setIntegrations({
      status: false,
      message: "",
    });

    // Download
    setDownload({
      status: false,
      formats: {
        videos: {
          webm: false,
          mp4: false,
        },
        screenshots: {
          png: false,
          jpg: false,
          pdf: false,
        },
      },
      message: "",
    });
  };

  return (
    <div>
      <AdminLayout pageTitle={"pricing-plans"}>
        <div className="pricing-plans-content">
          <div className="pricing-plans-content">
            <div className="table-management-section">
              <div className="management-header mb-5">
                <h4>Create Product Plan</h4>
                <p>You can create a pricing plan.</p>
              </div>
            </div>
          </div>
          <div className="quix-data-table card">
            <div className="card-header">
              <h5 className="title m-0">Product Plan informations</h5>
            </div>
            <div className="card-body">
              <div className="quix-form-outer">
                <div className="quix-add-pricing-plan">
                  <form onSubmit={handleSubmit} id="create-pricing-plan-form">
                    <div className="row">
                      <div className="col-md-12 product-upload-img d-flex gap-3 align-items-center">
                        <div className="card upload-card">
                          <img src={src} height={"100%"} width={"100%"} />
                          <input
                            type="file"
                            name="upload-img"
                            className="upload-pricing-img"
                            onChange={(e) => handleUploadImage(e)}
                            accept={fileFormat.join(",")}
                          />
                        </div>
                        <div className="upload-cover mt-2">
                          <div className="d-flex gap-2">
                            <div className="upload-btn upload-card">
                              <input
                                type="file"
                                name="upload-img"
                                accept={fileFormat.join(",")}
                                onChange={(e) => handleUploadImage(e)}
                                className="upload-pricing-img"
                              />
                              <button
                                type="button"
                                className="btn upload-photo-btn"
                              >
                                Upload photo
                              </button>
                            </div>
                            <button
                              type="button"
                              className="btn remove-photo-btn"
                            >
                              Remove photo
                            </button>
                          </div>
                          <p>at least 132 x 132px PNG, JPEG or JPG file.</p>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4 mb-3">
                            <label
                              htmlFor="price"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Price
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                type="text"
                                name="price"
                                id="price"
                                onChange={(e) => handleChange(e)}
                                maxLength={255}
                              />
                              {formErrors?.priceError && (
                                <p className="text-danger account-dec m-0">
                                  {formErrors?.priceError}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 mb-3">
                            <label
                              htmlFor="duration"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Duration
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                type="text"
                                name="duration"
                                id="duration"
                                onChange={(e) => handleChange(e)}
                                maxLength={255}
                              />
                              {formErrors?.durationError && (
                                <p className="text-danger account-dec m-0">
                                  {formErrors?.durationError}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 mb-3">
                            <label
                              htmlFor="status"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Status
                            </label>
                            <div className="col-sm-12">
                              <select
                                className="form-control"
                                type="text"
                                name="status"
                                id="status"
                                onChange={(e) => handleChange(e)}
                              >
                                <option value={"1"}>Active</option>
                                <option value={"0"}>Inactive</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor="price-string"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Price String
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                type="text"
                                name="price_string"
                                id="price-string"
                                onChange={(e) => handleChange(e)}
                                maxLength={255}
                              />
                              {formErrors?.priceStringError && (
                                <p className="text-danger account-dec m-0">
                                  {formErrors?.priceStringError}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label
                              htmlFor="description"
                              className="col-sm-12 col-form-label text-start"
                            >
                              Description
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                type="text"
                                name="description"
                                id="description"
                                onChange={(e) => handleChange(e)}
                                maxLength={255}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <label
                          htmlFor="staticEmail"
                          className="col-sm-12 col-form-label text-start"
                        >
                          Features
                        </label>
                        <div className="quixy-feature-row-outer">
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="unlimited"
                                onChange={(e) =>
                                  setUnlimted(() => ({
                                    ...unlimited,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="unlimited">Unlimited</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Screenshots
                                </label>
                                <input
                                  onChange={(e) =>
                                    setUnlimted(() => ({
                                      ...unlimited,
                                      screenshots: e?.target?.value,
                                    }))
                                  }
                                  name="screenshots"
                                  type="number"
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">Videos</label>
                                <input
                                  type="number"
                                  onChange={(e) =>
                                    setUnlimted(() => ({
                                      ...unlimited,
                                      videos: e?.target?.value,
                                    }))
                                  }
                                  name="videos"
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  onChange={(e) =>
                                    setUnlimted(() => ({
                                      ...unlimited,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  name="message"
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="download"
                                onChange={(e) =>
                                  setDownload(() => ({
                                    ...download,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="download">Download</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row d-block">
                              <div className="video-opt">
                                <label className="col-form-label">Video</label>
                                <div className="gap-3 d-flex">
                                  {Object.keys(download.formats.videos).map(
                                    (format) => (
                                      <div
                                        key={format}
                                        className="download-opt d-flex align-items-center gap-1"
                                      >
                                        <input
                                          type="checkbox"
                                          id={format}
                                          className="form-check-input"
                                          checked={
                                            download.formats.videos[format]
                                          }
                                          onChange={() => {
                                            setDownload((prevState) => ({
                                              ...prevState,
                                              formats: {
                                                ...prevState.formats,
                                                videos: {
                                                  ...prevState.formats.videos,
                                                  [format]:
                                                    !prevState.formats.videos[
                                                    format
                                                    ],
                                                },
                                              },
                                            }));
                                          }}
                                        />
                                        <label
                                          htmlFor={format}
                                          className="col-form-label"
                                        >
                                          {format}
                                        </label>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="screen-opt">
                                <label className="col-form-label">
                                  Screenshot
                                </label>
                                <div className="d-flex align-items-center gap-3">
                                  {Object.keys(
                                    download.formats.screenshots
                                  ).map((format) => (
                                    <div
                                      key={format}
                                      className="download-opt d-flex align-items-center gap-1"
                                    >
                                      <input
                                        id={format}
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={
                                          download.formats.screenshots[format]
                                        }
                                        onChange={() => {
                                          setDownload((prevState) => ({
                                            ...prevState,
                                            formats: {
                                              ...prevState.formats,
                                              screenshots: {
                                                ...prevState.formats
                                                  .screenshots,
                                                [format]:
                                                  !prevState.formats
                                                    .screenshots[format],
                                              },
                                            },
                                          }));
                                        }}
                                      />
                                      <label
                                        htmlFor={format}
                                        className="col-form-label"
                                      >
                                        {format}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setDownload(() => ({
                                      ...download,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="unlimititedlength"
                                onChange={(e) =>
                                  setUnlimitedLength(() => ({
                                    ...unlimitedLength,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="unlimititedlength">
                                Unlimited Length
                              </label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">Length</label>
                                <input
                                  type="number"
                                  name="length"
                                  onChange={(e) =>
                                    setUnlimitedLength(() => ({
                                      ...unlimitedLength,
                                      length: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                />
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setUnlimitedLength(() => ({
                                      ...unlimitedLength,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="resolution"
                                onChange={(e) =>
                                  setResolution(() => ({
                                    ...resolution,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="resolution">Resolution</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row d-block">
                              <label>Resolution Formats</label>
                              <div className="features-colums">
                                {Object.keys(resolution.resolutions).map(
                                  (reslsn) => (
                                    <div
                                      key={reslsn}
                                      className="resolation-opt d-flex align-items-center gap-1"
                                    >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={reslsn}
                                        name={reslsn}
                                        onChange={(e) =>
                                          setResolution((prevState) => ({
                                            ...prevState,
                                            resolutions: {
                                              ...prevState.resolutions,
                                              [reslsn]: e.target.checked,
                                            },
                                          }))
                                        }
                                      />
                                      <label
                                        htmlFor={reslsn}
                                        className="col-form-label"
                                      >
                                        {reslsn}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="message"
                                  onChange={(e) =>
                                    setResolution(() => ({
                                      ...resolution,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="watermark"
                                onChange={(e) =>
                                  setWatermark(() => ({
                                    ...watermark,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="watermark">Watermark</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setWatermark(() => ({
                                      ...watermark,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="gradient"
                                onChange={(e) =>
                                  setGradient(() => ({
                                    ...gradient,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="gradient">Gradient</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setGradient(() => ({
                                      ...gradient,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="recovery"
                                onChange={(e) =>
                                  setRecovery(() => ({
                                    ...recovery,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="recovery">Recovery</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="message"
                                  onChange={(e) =>
                                    setRecovery(() => ({
                                      ...recovery,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="usermanagement"
                                onChange={(e) =>
                                  setUserManagement(() => ({
                                    ...userManagement,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="usermanagement">
                                User Management
                              </label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setUserManagement(() => ({
                                      ...userManagement,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="quix-feature-row">
                            <div className="quix-feature-row-title d-flex gap-2">
                              <input
                                id="integrations"
                                onChange={(e) =>
                                  setIntegrations(() => ({
                                    ...integrations,
                                    status: e.target.checked,
                                  }))
                                }
                                className="form-check-input"
                                type="checkbox"
                              />
                              <label htmlFor="integrations">Integrations</label>
                            </div>
                            <div className="quixy-feature-options quix-featured-row">
                              <div className="mb-3">
                                <label className="col-form-label">
                                  Message
                                </label>
                                <input
                                  type="text"
                                  name="message"
                                  onChange={(e) =>
                                    setIntegrations(() => ({
                                      ...integrations,
                                      message: e?.target?.value,
                                    }))
                                  }
                                  className="form-control"
                                  maxLength={255}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="theme-form-btn d-flex align-items-center mt-2 gap-2">
                      <button
                        className="btn theme-blue-btn"
                        type="submit"
                        disabled={loading}
                      >
                        Save & Continue
                      </button>
                      <button
                        type="button"
                        onClick={() => resetForm()}
                        className="btn theme-cancel-btn"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AdminLayout>
    </div>
  );
};

export default AddPricingPlan;
