import React, { useState, useEffect } from "react";
import AdminLayout from "../../layout/adminLayout";
import trashIcon from "../../assets/images/trash-icon.png";
import trashMediaIcon from "../../assets/images/tash-user-icon.png";
import editVideoIcon from "../../assets/images/video-icon.png";
import editImageIcon from "../../assets/images/image-icon.png";
import actionIcon from "../../assets/images/action-icon.png";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Table from "../../components/Table/Table";
import EditActionModal from "../../components/Modal/EditActionModal";
import {
    deleteScreenshotAdmin,
    getAllScreenshotsListAdmin,
    SearchScreenshotAdmin,
} from "../../../redux/actions/admin/screenshots";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import MediaContentCard from "../../components/Cards/MediaContentCard";
import Filters from "../../components/Filters/Filters";
import FilterContent from "../../components/Filters/FilterContent";
import UserProfileCard from "../../components/Cards/UserProfileCard";
import VideoViewCard from "../../components/Cards/VideoViewCard";
import ScreenshotViewCard from "../../components/Cards/ScreenshotViewCard";
import { checkServerURL, sg_format_bytes } from "../../../helper/helper";
import {
    deleteVideoAdmin,
    getAllVideosListAdmin,
    SearchVideoAdmin,
} from "../../../redux/actions/admin/videos";
import { getStatisticsListAdmin } from "../../../redux/actions/admin/statistics";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { successMessageModal } from "../../components/Modal/MessagesModal";
import dateFormat from "dateformat";

const ManageImageAndVideo = ({
    screenshotList,
    videoList,
    statistics,
    isScreenshotLoading,
    notFoundScreenshot,
    isVideoLoading,
    notFoundVideo,
}) => {
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showScreenshotModal, setShowScreenshotModal] = useState(false);
    const [showConfirmScreenshotModal, setShowConfirmScreenshotModal] =
        useState(false);
    const [showConfirmVideoModal, setShowConfirmVideoModal] = useState(false);
    const [selectedItemsIDs, setSelectedItemsIDs] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState([]);
    const [selectedVideoItemsIDs, setSelectedVideoItemsIDs] = useState([]);
    const [isAllSelectedVideo, setIsAllSelectedVideo] = useState([]);
    const [getScreenshotdata, setScreenshotData] = useState([]);
    const [getVideodata, setVideoData] = useState([]);
    const userID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    // get all screenshots
    const screenshotListData = screenshotList?.data ? screenshotList?.data : [];
    const videoListData = videoList?.data ? videoList?.data : [];
    const statisticsData = statistics?.data ? statistics?.data : [];
    useEffect(() => {
        if (userID) {
            dispatch(getAllScreenshotsListAdmin(Number(userID)));
            dispatch(getAllVideosListAdmin(Number(userID)));
            dispatch(getStatisticsListAdmin(Number(userID)));
        }
    }, [userID]);

    // media table screenshot columns
    const screenshotColumns = [
        {
            Header: (
                <input
                    className="form-check-input check-items"
                    type="checkbox"
                    onChange={(e) => handleMultiSelectItems(e, "screenshot")}
                    checked={isAllSelected ? true : false}
                />
            ),
            accessor: "check",
            disableSortBy: true,
            Cell: (row) => (
                <>
                    <input
                        className="form-check-input check-items"
                        type="checkbox"
                        checked={
                            selectedItemsIDs?.includes(row?.row?.original?.id) ? true : false
                        }
                        onChange={(e) =>
                            handleChangeSelectedItems(e, row?.row?.original?.id)
                        }
                    />
                </>
            ),
        },
        { Header: "#", accessor: (row, i) => i + 1 },
        {
            Header: "User name",
            Cell: (row) => (
                <UserProfileCard
                    src={
                        !row?.row?.original?.userDetails?.picture ||
                            row?.row?.original?.userDetails?.picture === "null" ||
                            row?.row?.original?.userDetails?.picture === null
                            ? dummyUserIcon
                            : checkServerURL(
                                API_URL,
                                `${row?.row?.original?.userDetails?.picture}`
                            )
                    }
                    name={row?.row?.original?.userDetails?.name}
                />
            ),
        },
        {
            Header: "Content",
            accessor: "screenshot",
            Cell: (row) => (
                <>
                    <MediaContentCard
                        src={
                            !row?.row?.original?.thumbnail ||
                                row?.row?.original?.thumbnail === "null" ||
                                row?.row?.original?.thumbnail === null
                                ? dummyUserIcon
                                : checkServerURL(API_URL, `${row?.row?.original?.thumbnail}`)
                        }
                        title={row?.row?.original?.title}
                        duration={`${row?.row?.original?.file_size &&
                            sg_format_bytes(row?.row?.original?.file_size)
                            }- Screenshot`}
                    ></MediaContentCard>
                </>
            ),
        },
        {
            Header: "Date & time",
            Cell: (row) => (
                <>
                    {row?.row?.original?.created_at
                        ? dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")
                        : "N/A"}
                </>
            ),
        },
        {
            Header: "Action",
            Cell: (row) => (
                <div className="action-cell">
                    <div className="edit-action-cell">
                        <img className="action-img" src={actionIcon} />
                        <EditActionModal>
                            <div
                                className="column-action gap-2"
                                onClick={() => {
                                    setShowScreenshotModal(!showScreenshotModal);
                                    setScreenshotData(row?.row?.original);
                                }}
                            >
                                <img src={editImageIcon} />
                                <span>Screenshot details</span>
                            </div>
                            <div
                                className="column-action delete-action gap-2"
                                disabled={loading}
                                onClick={() => {
                                    setShowConfirmScreenshotModal(!showConfirmScreenshotModal);
                                    setScreenshotData(row?.row?.original);
                                }}
                            >
                                <img src={trashIcon} />
                                <span>Delete content</span>
                            </div>
                        </EditActionModal>
                    </div>
                </div>
            ),
        },
    ];

    // media table video columns
    const videoColumns = [
        {
            Header: (
                <input
                    className="form-check-input check-items"
                    type="checkbox"
                    onChange={(e) => handleMultiSelectItems(e, "video")}
                    checked={isAllSelectedVideo ? true : false}
                />
            ),
            accessor: "check",
            disableSortBy: true,
            Cell: (row) => (
                <>
                    <input
                        className="form-check-input check-items"
                        type="checkbox"
                        checked={
                            selectedVideoItemsIDs?.includes(row?.row?.original?.id)
                                ? true
                                : false
                        }
                        onChange={(e) =>
                            handleChangeSelectedItems(e, row?.row?.original?.id)
                        }
                    />
                </>
            ),
        },
        { Header: "#", accessor: (row, i) => i + 1 },
        {
            Header: "User name",
            Cell: (row) => (
                <UserProfileCard
                    src={
                        !row?.row?.original?.userDetails?.picture ||
                            row?.row?.original?.userDetails?.picture === "null" ||
                            row?.row?.original?.userDetails?.picture === null
                            ? dummyUserIcon
                            : checkServerURL(
                                API_URL,
                                `${row?.row?.original?.userDetails?.picture}`
                            )
                    }
                    name={row?.row?.original?.userDetails?.name}
                />
            ),
        },
        {
            Header: "Content",
            accessor: "video",
            Cell: (row) => (
                <>
                    <MediaContentCard
                        src={
                            !row?.row?.original?.thumbnail ||
                                row?.row?.original?.thumbnail === "null" ||
                                row?.row?.original?.thumbnail === null
                                ? dummyUserIcon
                                : checkServerURL(API_URL, `${row?.row?.original?.thumbnail}`)
                        }
                        title={row?.row?.original?.title}
                        duration={`${row?.row?.original?.file_duration} min`}
                    ></MediaContentCard>
                </>
            ),
        },
        {
            Header: "Date & time",
            Cell: (row) => (
                <>
                    {row?.row?.original?.created_at
                        ? dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")
                        : "N/A"}
                </>
            ),
        },
        {
            Header: "Action",
            Cell: (row) => (
                <div className="action-cell">
                    <div className="edit-action-cell">
                        <img className="action-img" src={actionIcon} />
                        <EditActionModal>
                            <div
                                className="column-action gap-2"
                                onClick={() => {
                                    setShowVideoModal(!showVideoModal);
                                    setVideoData(row?.row?.original);
                                }}
                            >
                                <img src={editVideoIcon} />
                                <span>Video details</span>
                            </div>
                            <div
                                className="column-action delete-action gap-2"
                                disabled={loading}
                                onClick={() => {
                                    setShowConfirmVideoModal(!showConfirmVideoModal);
                                    setVideoData(row?.row?.original);
                                }}
                            >
                                <img src={trashIcon} />
                                <span>Delete content</span>
                            </div>
                        </EditActionModal>
                    </div>
                </div>
            ),
        },
    ];

    const userCsvData = {
        filename: "user-data",
        data: [
            { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
            { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
            { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" },
        ],
    };

    const [showItemData, setShowItemData] = useState([]);
    const visibleItems = showItemData && showItemData;
    // for videos
    const [showVideoItemData, setShowVideoItemData] = useState([]);
    const visibleVideoItems = showVideoItemData && showVideoItemData;
    // handle multi select screenshot
    const handleMultiSelectItems = (e, type) => {
        let checked = e.target.checked;
        if (type === "screenshot") {
            if (checked) {
                let newSelectedIDs = visibleItems.map((user) => user.id);
                setSelectedItemsIDs((prevIDs) => [
                    ...new Set([...prevIDs, ...newSelectedIDs]),
                ]);
            } else {
                setSelectedItemsIDs([]);
            }
        } else {
            if (checked) {
                let newSelectedIDs = visibleVideoItems.map((user) => user.id);
                setSelectedVideoItemsIDs((prevIDs) => [
                    ...new Set([...prevIDs, ...newSelectedIDs]),
                ]);
            } else {
                setSelectedVideoItemsIDs([]);
            }
        }
    };

    // handle single select screenshot
    const handleChangeSelectedItems = (e, id, type) => {
        const checked = e.target.checked;
        if (type === "screenshot") {
            setSelectedItemsIDs((prevSelectedItemsIDs) => {
                if (checked) {
                    if (!prevSelectedItemsIDs.includes(id)) {
                        return [...prevSelectedItemsIDs, id];
                    }
                } else {
                    return prevSelectedItemsIDs.filter((item) => item !== id);
                }
                return prevSelectedItemsIDs;
            });
        } else {
            setSelectedVideoItemsIDs((prevSelectedItemsIDs) => {
                if (checked) {
                    if (!prevSelectedItemsIDs.includes(id)) {
                        return [...prevSelectedItemsIDs, id];
                    }
                } else {
                    return prevSelectedItemsIDs.filter((item) => item !== id);
                }
                return prevSelectedItemsIDs;
            });
        }
    };

    useEffect(() => {
        setIsAllSelected(
            visibleItems.length > 0 &&
            visibleItems.every((user) => selectedItemsIDs.includes(user.id))
        );
        setIsAllSelectedVideo(
            visibleVideoItems.length > 0 &&
            visibleVideoItems.every((user) =>
                selectedVideoItemsIDs.includes(user.id)
            )
        );
    }, [
        selectedItemsIDs,
        visibleItems,
        selectedVideoItemsIDs,
        visibleVideoItems,
    ]);

    // handle search screenshot
    const handleSearchScreenshot = (text) => {
        const payload = {
            admin_id: Number(userID),
            text: text,
        };
        dispatch(SearchScreenshotAdmin(payload));
    };

    // handle search video
    const handleSearchVideo = (text) => {
        const payload = {
            admin_id: Number(userID),
            text: text,
        };
        dispatch(SearchVideoAdmin(payload));
    };

    // reset searched video and screenshot
    const resetScreenshotAndVideo = (type) => {
        if (type === "screenshot") {
            dispatch(getAllScreenshotsListAdmin(Number(userID)));
        } else {
            dispatch(getAllVideosListAdmin(Number(userID)));
        }
    };

    // delete screenshot
    const handleDeleteScreenshot = (res) => {
        if (res === true) {
            const payload = {
                admin_id: Number(userID),
                id: Number(getScreenshotdata?.id),
            };
            dispatch(
                deleteScreenshotAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Successful Deleted screenshot.",
                            "Screenshot will permanently delete successfully."
                        );
                        setLoading(false);
                        setShowConfirmScreenshotModal(false);
                        dispatch(getAllScreenshotsListAdmin(Number(userID)));
                        dispatch(getStatisticsListAdmin(Number(userID)));
                    } else if (type === "fail") {
                        setLoading(false);
                    }
                })
            );
        }
    };

    // delete video
    const handleDeleteVideo = (res) => {
        if (res === true) {
            const payload = {
                admin_id: Number(userID),
                id: Number(getVideodata?.id),
            };
            dispatch(
                deleteVideoAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Successful Deleted video.",
                            "video will permanently delete successfully."
                        );
                        setShowConfirmVideoModal(false);
                        dispatch(getAllVideosListAdmin(Number(userID)));
                        dispatch(getStatisticsListAdmin(Number(userID)));
                        setLoading(false);
                    } else if (type === "fail") {
                        setLoading(false);
                    }
                })
            );
        }
    };

    // handle screenshot csv data
    const screenshotDataCSVFormate =
        screenshotListData &&
        screenshotListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                UserName: item?.userDetails?.name ? item?.userDetails?.name : "N/A",
                Email: item?.userDetails?.email ? item?.userDetails?.email : "N/A",
                Picture: item?.userDetails?.picture
                    ? checkServerURL(API_URL, item?.userDetails?.picture)
                    : "N/A",
                Name: item?.name ? item?.name : "N/A",
                Title: item?.title ? item?.title : "N/A",
                Summary: item?.summary ? item?.summary : "N/A",
                Thumbnail: item?.thumbnail
                    ? checkServerURL(API_URL, item?.thumbnail)
                    : "N/A",
                FileSize: item?.file_size ? item?.file_size : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let screenshotDataCSV = {
        filename: "screenshots-list",
        data: screenshotDataCSVFormate,
    };

    // handle video csv data
    const videoDataCSVFormate =
        videoListData &&
        videoListData?.map((item) => {
            return {
                ID: item?.id ? item?.id : "N/A",
                UserName: item?.userDetails?.user_name
                    ? item?.userDetails?.user_name
                    : "N/A",
                Email: item?.userDetails?.user_email
                    ? item?.userDetails?.user_email
                    : "N/A",
                Picture: item?.userDetails?.user_picture
                    ? checkServerURL(API_URL, item?.userDetails?.picture)
                    : "N/A",
                Name: item?.name ? item?.name : "N/A",
                Title: item?.title ? item?.title : "N/A",
                Summary: item?.summary ? item?.summary : "N/A",
                Thumbnail: item?.thumbnail
                    ? checkServerURL(API_URL, item?.thumbnail)
                    : "N/A",
                FileDuration: item?.file_duration ? item?.file_duration : "N/A",
                Created_at: item?.created_at,
                Updated_at: item?.updated_at,
            };
        });
    let videoDataCSV = {
        filename: "videos-list",
        data: videoDataCSVFormate,
    };

    return (
        <div className="manage-media-screenshot-and-video">
            <AdminLayout pageTitle={"manage-image-and-video"}>
                <div className="screenshot-and-video-content">
                    <div className="table-management-section">
                        <div className="management-header">
                            <h4>Image & Video Management</h4>
                            <p>
                                You can manage inappropriate content of for other reasons video
                                and screenshots.
                            </p>
                        </div>
                        <Filters
                            filterList={[
                                {
                                    id: "all-screenshot",
                                    className: "active",
                                    title: "All Screenshots",
                                },
                                {
                                    id: "all-video",
                                    className: "",
                                    title: "All Videos",
                                },
                            ]}
                        />
                    </div>
                    <div className="table-statistics">
                        <div className="statistics-header d-flex justify-content-between align-items-center">
                            <h5>Statistics</h5>
                            <Link className="nav-link" to={"/admin/dashboard"}>
                                View video & screenshots reports
                            </Link>
                        </div>
                        <div className="statistics-content">
                            <div className="statistics-card d-flex align-items-center">
                                <h6>{`${statisticsData?.total_user}`}</h6>
                                <span>Total Users</span>
                            </div>
                            <div className="statistics-card d-flex align-items-center">
                                <h6>{`${statisticsData?.total_video}`}</h6>
                                <span>Total Videos</span>
                            </div>
                            <div className="statistics-card d-flex align-items-center">
                                <h6>{`${statisticsData?.total_screenshots}`}</h6>
                                <span>Total Screenshots</span>
                            </div>
                        </div>
                    </div>
                    <FilterContent
                        filterContent={[
                            {
                                id: "all-screenshot",
                                className: "show active screenshot-tab",
                                childrenData: (
                                    <Table
                                        tableData={screenshotListData}
                                        tableColumns={screenshotColumns}
                                        isLoading={isScreenshotLoading}
                                        notFound={notFoundScreenshot}
                                        csvData={screenshotDataCSV}
                                        setShowItems={(e) => setShowItemData(e)}
                                        searchCallBack={(e) => handleSearchScreenshot(e)}
                                        resetCallBack={() => resetScreenshotAndVideo("screenshot")}
                                        searchBar={true}
                                        tableType={'screenshot'}
                                    />
                                ),
                            },
                            {
                                id: "all-video",
                                className: "all-video-tab",
                                childrenData: (
                                    <Table
                                        tableData={videoListData}
                                        tableColumns={videoColumns}
                                        csvData={videoDataCSV}
                                        isLoading={isVideoLoading}
                                        notFound={notFoundVideo}
                                        setShowItems={(e) => setShowVideoItemData(e)}
                                        searchCallBack={(e) => handleSearchVideo(e)}
                                        resetCallBack={() => resetScreenshotAndVideo("video")}
                                        searchBar={true}
                                        tableType={'video'}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
                {showVideoModal && (
                    <VideoViewCard
                        closeModal={setShowVideoModal}
                        cardData={getVideodata}
                    />
                )}
                {showScreenshotModal && (
                    <ScreenshotViewCard
                        closeModal={setShowScreenshotModal}
                        cardData={getScreenshotdata}
                    />
                )}
                {showConfirmScreenshotModal && (
                    <ConfirmationModal
                        isShowModal={showConfirmScreenshotModal}
                        closeModal={setShowConfirmScreenshotModal}
                        callBack={handleDeleteScreenshot}
                        isLoading={false}
                        isDisabled={false}
                        icon={trashMediaIcon}
                        btnText={"Continue"}
                        messageTitle={
                            <>
                                Would you like to permanently <br />
                                delete this screenshot?
                            </>
                        }
                        description={
                            <>
                                Once deleted, this screenshot will
                                <br />
                                no longer be accessible.
                            </>
                        }
                    />
                )}
                {showConfirmVideoModal && (
                    <ConfirmationModal
                        isShowModal={showConfirmVideoModal}
                        closeModal={setShowConfirmVideoModal}
                        callBack={handleDeleteVideo}
                        isLoading={false}
                        isDisabled={false}
                        icon={trashMediaIcon}
                        btnText={"Continue"}
                        messageTitle={
                            <>
                                Would you like to permanently <br />
                                delete this video?
                            </>
                        }
                        description={
                            <>
                                Once deleted, this video will
                                <br />
                                no longer be accessible.
                            </>
                        }
                    />
                )}
            </AdminLayout>
        </div>
    );
};

const mapStateToProps = (state) => ({
    screenshotList: state?.admin_screenshots?.list,
    isScreenshotLoading: state?.admin_screenshots?.is_loading,
    notFoundScreenshot: state?.admin_screenshots?.not_found,
    videoList: state?.admin_videos?.list,
    isVideoLoading: state?.admin_videos?.is_loading,
    notFoundVideo: state?.admin_videos?.not_found,
    statistics: state?.admin_statistics?.list,
});

export default connect(mapStateToProps, { getAllScreenshotsListAdmin })(
    ManageImageAndVideo
);
