import {
  REMOVE_CARD,
  GET_SCREENSHOTS,
  GET_STARRED_SCREENSHOTS,
  LOADING_SCREENSHORT,
  NOT_FOUND_SCREENSHOT,
  RENAME_CARD,
  SEND_BY_EMAIL,
  LOADING_STARRED_SCREENSHOT,
  NOT_FOUND_STARRED_SCREENSHOT,
  SHORT_SCREENSHOT,
  UPLOAD_SCREENSHOT,
  SHORT_STARRED_SCREENSHOT,
  SEARCH_SCREENSHOTS,
  SEARCH_STARRED_SCREENSHOTS
} from "../actions/type";

const INITIAL_STATE = {
  list: [],
  starred_list: [],
  is_loading: false,
  is_loading_started_screenshorts: false,
  not_found: false,
  starred_not_found: false,
  rename_screenshot: null,
  upload_screenshot: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_SCREENSHORT:
      return { ...state, is_loading: true };
    case LOADING_STARRED_SCREENSHOT:
      return { ...state, is_loading_started_screenshorts: true };
    case GET_SCREENSHOTS:
      return {
        ...state,
        list: action.payload,
        is_loading: false,
        not_found: false,
      };
    case SEARCH_SCREENSHOTS:
      return {
        ...state,
        list: action.payload,
        is_loading: false,
        not_found: false,
      };
    case GET_STARRED_SCREENSHOTS:
      return {
        ...state,
        starred_list: action.payload,
        is_loading_started_screenshorts: false,
        starred_not_found: false,
      };
    case SEARCH_STARRED_SCREENSHOTS:
      return {
        ...state,
        starred_list: action.payload,
        is_loading_started_screenshorts: false,
        starred_not_found: false,
      };
    case SHORT_SCREENSHOT:
      return { ...state, list: action.payload, is_loading: false };
    case SHORT_STARRED_SCREENSHOT:
      return { ...state, starred_list: action.payload, is_loading: false };
    case UPLOAD_SCREENSHOT:
      return { ...state, upload_screenshot: action.payload, is_loading: false };
    case RENAME_CARD:
      return { ...state, rename_screenshot: action.payload, is_loading: false };
    case SEND_BY_EMAIL:
      return { ...state, is_loading: false };
    case REMOVE_CARD:
      return { ...state, is_loading: false };
    case NOT_FOUND_SCREENSHOT:
      return { ...state, list: [], not_found: true, is_loading: false };
    case NOT_FOUND_STARRED_SCREENSHOT:
      return {
        ...state,
        starred_not_found: true,
        is_loading_started_screenshorts: false,
      };
    default:
      return state;
  }
};
