import React, { useEffect } from 'react';

const AdSenseAd = ({ client, slot, format, width, height }) => {
    useEffect(() => {

        const installGoogleAds = () => {
          const elem = document.createElement("script");
          elem.src =
            "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
          elem.async = true;
          elem.defer = true;
          document.body.insertBefore(elem, document.body.firstChild);
        };
        installGoogleAds();
        if (window.adsbygoogle && !window.adsbygoogle.loaded)
        {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }

      }, [client, slot, format, width, height]);

  return (
    <div>
      {/* Google AdSense Code */}
      <ins
        className="adsbygoogle"
        style={{ display: 'block', width, height }}
        data-ad-client={client}
        data-ad-slot={slot}
        data-ad-format={format}
      ></ins>
    </div>
  );
};

export default AdSenseAd;
