import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { localStoragePrefix } from "../utils/config";
import LoadingPage from "../views/loading-page/LoadingPage";
import { useDispatch } from "react-redux";
import { getUserDetailsByID } from "../redux/actions/authentication";

const AuthenticatedRoutes = ({ element: Component, ...rest }) => {
    const [loading, setLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const storageToken = localStorage.getItem(
        localStoragePrefix + "screenGeniusAuthToken"
    );

    const checkAuth = async () => {
        if (!storageToken) {
            setLoading(false);
            setIsAuth(false);
            navigate("/login");
            return;
        }
        try {
            await dispatch(getUserDetailsByID(storageToken, (result, type) => {
                if (result?.data?.status) {
                    setIsAuth(true);
                } else {
                    localStorage.removeItem(localStoragePrefix + "screenGeniusAuthToken");
                    localStorage.removeItem(localStoragePrefix + "screenGeniusUserID");
                    localStorage.removeItem(localStoragePrefix + "screenGeniusUserRole");
                    localStorage.clear()
                    setIsAuth(false);
                    navigate("/login");
                }
            }));
        } catch (error) {
            localStorage.removeItem(localStoragePrefix + "screenGeniusAuthToken");
            localStorage.removeItem(localStoragePrefix + "screenGeniusUserID");
            localStorage.removeItem(localStoragePrefix + "screenGeniusUserRole");
            localStorage.clear()
            setIsAuth(false);
            navigate("/login");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAuth();
    }, [storageToken]);
    const location = useLocation();

    if (loading) {
        return <LoadingPage />;
    }

    return isAuth ? <Component {...rest} /> : <Navigate to="/login" replace state={{ redirectTo: location }} />;
};

export default AuthenticatedRoutes;