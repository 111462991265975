import axios from "axios";
import { API_URL } from "../../../utils/config";
import {
  GET_ALL_ADMIN_MEMBERSHIP,
  LOADING_ADMIN_MEMBERSHIP,
  NOT_FOUND_ADMIN_MEMBERSHIP,
  UPDATE_ADMIN_MEMBERSHIP,
} from "../type";

export const getAllMembershipListAdmin = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LOADING_ADMIN_MEMBERSHIP,
      });

      let data = JSON.stringify({
        admin_id: id,
      });

      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(
        `${API_URL}/admin/memberships/list`,
        data,
        config
      );
      if (res?.data?.status === true) {
        if (res?.data?.data?.length <= 0) {
          dispatch({
            type: NOT_FOUND_ADMIN_MEMBERSHIP,
          });
        } else {
          dispatch({
            type: GET_ALL_ADMIN_MEMBERSHIP,
            payload: res?.data,
          });
        }
      }
    } catch (error) {
      console.log(error, "---error---");
    }
  };
};

export const updateMembershipAdmin = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: payload?.user_id,
        admin_id: payload?.admin_id,
        priceplan_id: payload?.priceplan_id,
        recurrence: payload?.recurrence,
        end_date: payload?.end_date,
        status: payload?.status,
      });
      let config = {
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      const res = await axios.patch(
        `${API_URL}/admin/memberships/edit/${payload?.id}`,
        data,
        config
      );
      if (res?.data?.status === true) {
        callBack("success", res?.data);
        dispatch({
          type: UPDATE_ADMIN_MEMBERSHIP,
          payload: res?.data,
        });
      }
    } catch (error) {
      const message = error?.response?.data;
      callBack("fail", message);
      console.log(error, "---error---");
    }
  };
};
