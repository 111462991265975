import React, { useState, useEffect } from "react";
import { localStoragePrefix } from "../../../../utils/config";
import { useDispatch } from "react-redux";
import { addTagAdmin, getAllTagsListAdmin } from "../../../../redux/actions/admin/tags";
import { errorMessageModal, successMessageModal } from "../../../components/Modal/MessagesModal";
import { adminCreateTagSachema } from "./Validations";

const CreateTags = () => {
    const userID = localStorage.getItem(
        localStoragePrefix + "screenGeniusUserID"
    );
    const dispatch = useDispatch();
    // add new tags
    const [tagLoading, setTagLoading] = useState(false);
    const [tagTitle, setTagTitle] = useState("");
    const [formErrors, setFormErrors] = useState("");

    const handleSubmit = () => {
        const payload = {
            admin_id: Number(userID),
            name: tagTitle.trim(),
        };
        setTagTitle("");
        if (adminCreateTagSachema({ formData: payload, setFormErrors })) {
            setTagLoading(true);
            dispatch(
                addTagAdmin(payload, (type, res) => {
                    if (type === "success") {
                        successMessageModal(
                            "Tag Successfully Created.",
                            "Tag has been created successfully."
                        );
                        dispatch(getAllTagsListAdmin(Number(userID)));
                        setTagLoading(false);
                    } else if (type === "failed") {
                        setTagLoading(false);
                        errorMessageModal("Error Message", res?.message);
                    }
                })
            );
        }
    };
    return (
        <div className="card quix-data-table mb-4">
            <div className="card-header">
                <h5 className="title m-0">Create new tag</h5>
            </div>
            <div className="card-body">
                <div className="quix-form-outer">
                    <div className="quix-add-pricing-plan">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label
                                    htmlFor="name"
                                    className="col-sm-12 col-form-label text-start"
                                >
                                    Tag name
                                </label>
                                <div className="col-sm-12">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={tagTitle}
                                        onChange={(e) => setTagTitle(e.target.value)}
                                        maxLength={255}
                                    />
                                    {formErrors?.nameError && (
                                        <p className="text-danger account-dec m-0">
                                            {formErrors?.nameError}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="quix-form-btn-outer d-flex align-items-center mt-2 gap-2">
                                <button
                                    className="btn theme-cancel-btn"
                                    onClick={() => setTagTitle("")}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn theme-blue-btn"
                                    onClick={() => handleSubmit()}
                                    disabled={tagLoading}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTags;