import React from "react";

const UserProfileCard = ({ src, name }) => {
    return (
        <div className={"table-profile d-flex align-items-center gap-2"}>
            <img src={src} />
            <h6 className="text-clip">{name}</h6>
        </div>
    );
};

export default UserProfileCard;