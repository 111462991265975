import AdminLayout from "../../layout/adminLayout";
import React, { useState, useEffect } from "react";
import TrashIcon from "../../assets/images/trash-icon.png";
import actionIcon from "../../assets/images/action-icon.png";
import profileRedirectIcon from "../../assets/images/user-profile-redirect.png";
import suspendIcon from "../../assets/images/slash-icon.png";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import trashUserIcon from "../../assets/images/tash-user-icon.png";
import suspendUserIcon from "../../assets/images/block-icon.png";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import { connect, useDispatch } from "react-redux";
import Table from "../../components/Table/Table";
import { checkServerURL } from "../../../helper/helper";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import { Link } from "react-router-dom";
import EditActionModal from "../../components/Modal/EditActionModal";
import {
  errorMessageModal,
  successMessageModal,
} from "../../components/Modal/MessagesModal";
import UserProfileCard from "../../components/Cards/UserProfileCard";
import Statistics from "../../components/Statistics/Statistics";
import Filters from "../../components/Filters/Filters";
import FilterContent from "../../components/Filters/FilterContent";
import {
  deleteUserByIdAdmin,
  getAllUserListAdmin,
  getSuspendUserListAdmin,
  SearchUserAdmin,
  updateUserByIdAdmin,
} from "../../../redux/actions/admin/users";
import { getStatisticsListAdmin } from "../../../redux/actions/admin/statistics";
import dateFormat from "dateformat";

const UsersList = ({
  usersData,
  statistics,
  suspendUserData,
  suspendUsersNotFound,
  isLoadingSuspendUser,
  isLoading,
  notFound,
}) => {
  const [getUserdata, setUserData] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showSuspendActiveModal, setShowSuspendActiveModal] = useState(false);
  const [selectedUserIDs, setSelectedUserIDs] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState([]);
  const [itemId, setItemId] = useState(null);
  const adminID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );
  const dispatch = useDispatch();
  const usersListData = usersData?.data ? usersData?.data : [];
  const suspendUsersListData = suspendUserData?.data
    ? suspendUserData?.data
    : [];
  const statisticsData = statistics?.data ? statistics?.data : [];

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (adminID) {
      dispatch(getAllUserListAdmin(Number(adminID)));
      dispatch(getStatisticsListAdmin(Number(adminID)));
      dispatch(getSuspendUserListAdmin(Number(adminID)));
    }
  }, [dispatch]);

  // manging user status
  const userStatusHandler = (status) => {
    switch (Number(status)) {
      case 1:
        return {
          status: "Active",
          class: "table-status-active table-status-btn",
        };
      case 0:
        return {
          status: "Suspended",
          class: "table-status-suspend table-status-btn",
        };
      default:
        return {
          status: "Unregistered User",
          class: "table-status-default table-status-btn",
        };
    }
  };

  const columns = [
    {
      Header: (
        <input
          className="form-check-input check-items"
          type="checkbox"
          onChange={(e) => handleMultiSelectuser(e)}
          checked={isAllSelected ? true : false}
        />
      ),
      accessor: "check",
      disableSortBy: true,
      Cell: (row) => (
        <>
          <input
            className="form-check-input check-items"
            type="checkbox"
            checked={
              selectedUserIDs?.includes(row?.row?.original?.id) ? true : false
            }
            onChange={(e) =>
              handleChangeSelectedItems(e, row?.row?.original?.id)
            }
          />
        </>
      ),
    },
    { Header: "#", accessor: (row, i) => i + 1 },
    {
      Header: "User name",
      Cell: (row) => (
        <UserProfileCard
          src={
            !row?.row?.original?.picture ||
              row?.row?.original?.picture === "null" ||
              row?.row?.original?.picture === null
              ? dummyUserIcon
              : checkServerURL(API_URL, `${row?.row?.original?.picture}`)
          }
          name={row?.row?.original?.name}
        />
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => (
        <>
          <a href={`mailto:${row?.row?.original?.email}`}>
            {row?.row?.original?.email}
          </a>
        </>
      ),
    },
    {
      Header: "Date & time",
      Cell: (row) => (
        <>
          {row?.row?.original?.created_at
            ? dateFormat(row?.row?.original?.created_at, "dd/mm/yyyy hh:MM TT")
            : "N/A"}
        </>
      ),
    },
    {
      Header: "Status user",
      Cell: (row) => (
        <>
          <div
            className={`btn ${userStatusHandler(row?.row?.original?.status).class
              }`}
          >
            {userStatusHandler(row?.row?.original?.status).status}
          </div>
        </>
      ),
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="action-cell">
          <div className="edit-action-cell">
            <img className="action-img" src={actionIcon} />
            <EditActionModal>
              <div className="column-action">
                <Link
                  className="justify-content-between"
                  to={`/admin/manage-user-profile/${row?.row?.original?.id}`}
                  state={{ data: row?.row?.original }}
                >
                  <span>User Profile</span>
                  <img src={profileRedirectIcon} />
                </Link>
              </div>
              {/* <div className="column-action justify-content-between">
                <span>
                  <label htmlFor="disabled-user">Disable User</label>
                </span>
                <div className="custom-switch form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="disabled-user"
                  />
                </div>
              </div> */}
              {row?.row?.original.status === "1" ? (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    setShowSuspendModal(!showSuspendModal);
                    setUserData(row?.row?.original);
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Suspend User</span>
                </div>
              ) : (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    setShowSuspendActiveModal(!showSuspendActiveModal);
                    setUserData(row?.row?.original);
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Activate User</span>
                </div>
              )}
              <div
                className="column-action delete-action gap-2"
                disabled={loading}
                onClick={() => {
                  setShowConfirmModal(!showConfirmModal);
                  setItemId(row?.row?.original?.id);
                }}
              >
                <img src={TrashIcon} />
                <span>Delete User</span>
              </div>
            </EditActionModal>
          </div>
        </div>
      ),
    },
  ];

  // handle delete user
  const handleDeleteUser = (res) => {
    if (res === true) {
      setLoading(true);
      setShowConfirmModal(false);
      const payload = {
        id: itemId,
        admin_id: Number(adminID),
      };
      dispatch(
        deleteUserByIdAdmin(payload, (type, res) => {
          if (type === "success") {
            successMessageModal(
              "User Account Deleted successful.",
              "Successful delete a user account."
            );
            dispatch(getAllUserListAdmin(Number(adminID)));
            dispatch(getSuspendUserListAdmin(Number(adminID)));
            dispatch(getStatisticsListAdmin(Number(adminID)));
            setLoading(false);
          } else if (type === "fail") {
            setLoading(false);
            errorMessageModal("Delete User", res?.message);
          }
        })
      );
    }
  };

  // handle suspend user
  const handleSuspendUser = (res) => {
    const payload = {
      name: getUserdata?.name,
      email: getUserdata?.email,
      picture: getUserdata?.picture,
      user_id: Number(getUserdata?.id),
      admin_id: Number(adminID),
      role: getUserdata?.role,
      status: getUserdata?.status === "1" ? "0" : "1",
    };
    if (res === true) {
      setLoading(true);
      dispatch(
        updateUserByIdAdmin(payload, (type, res) => {
          if (type === "success") {
            if (res?.data?.status === "1") {
              successMessageModal(
                "User Account Reactivated.",
                "Successful user account reactivated."
              );
            } else {
              successMessageModal(
                "User suspend successful.",
                "Successful suspend a user account."
              );
            }
            setLoading(false);
            setShowSuspendActiveModal(false);
            setShowSuspendModal(false);
            dispatch(getAllUserListAdmin(Number(adminID)));
            dispatch(getSuspendUserListAdmin(Number(adminID)));
            dispatch(getStatisticsListAdmin(Number(adminID)));
          } else if (type === "fail") {
            setShowSuspendActiveModal(false);
            setShowSuspendModal(false);
            setLoading(false);
          }
        })
      );
    }
  };

  // handle search all users csv data
  const userDataCSVFormate =
    usersListData &&
    usersListData?.map((item) => {
      return {
        ID: item?.id ? item?.id : "N/A",
        Name: item?.name ? item?.name : "N/A",
        Email: item?.email ? item?.email : "N/A",
        Picture: item?.picture ? checkServerURL(API_URL, item?.picture) : "N/A",
        Role: item?.role ? item?.role : "N/A",
        Status: item?.status ? item?.status : "N/A",
        Created_at: item?.created_at,
        Updated_at: item?.updated_at,
      };
    });
  let userDataCSV = { filename: "users-list", data: userDataCSVFormate };

  // handle search suspend users csv data
  const suspendUserDataCSVFormate =
    suspendUsersListData &&
    suspendUsersListData?.map((item) => {
      return {
        ID: item?.id ? item?.id : "N/A",
        Name: item?.name ? item?.name : "N/A",
        Email: item?.email ? item?.email : "N/A",
        Picture: item?.picture ? checkServerURL(API_URL, item?.picture) : "N/A",
        Role: item?.role ? item?.role : "N/A",
        Status: item?.status ? item?.status : "N/A",
        Created_at: item?.created_at,
        Updated_at: item?.updated_at,
      };
    });
  let suspendUserDataCSV = {
    filename: "suspend-users-list",
    data: suspendUserDataCSVFormate,
  };

  // handle search all users
  const handleSearchUsers = (data) => {
    if (data) {
      const payload = {
        text: data,
        query: "user",
        admin_id: Number(adminID),
      };
      dispatch(SearchUserAdmin(payload));
    }
  };

  // handle search suspend users
  const handleSearchSuspendUsers = (data) => {
    if (data) {
      const payload = {
        text: data,
        query: "suspendUser",
        admin_id: Number(adminID),
      };
      dispatch(SearchUserAdmin(payload));
    }
  };

  // handle reset all users and suspend users
  const handleReset = () => {
    dispatch(getAllUserListAdmin(Number(adminID)));
    dispatch(getSuspendUserListAdmin(Number(adminID)));
  };

  const [showUserData, setShowUserData] = useState([]);
  const visibleUsers = showUserData && showUserData;

  // handle multi select users
  const handleMultiSelectuser = (e) => {
    let checked = e.target.checked;
    if (checked) {
      let newSelectedIDs = visibleUsers.map((user) => user.id);
      setSelectedUserIDs((prevIDs) => [
        ...new Set([...prevIDs, ...newSelectedIDs]),
      ]);
    } else {
      setSelectedUserIDs([]);
    }
  };

  // handle single select users
  const handleChangeSelectedItems = (e, id) => {
    const checked = e.target.checked;
    setSelectedUserIDs((prevSelectedUserIDs) => {
      if (checked) {
        if (!prevSelectedUserIDs.includes(id)) {
          return [...prevSelectedUserIDs, id];
        }
      } else {
        return prevSelectedUserIDs.filter((item) => item !== id);
      }
      return prevSelectedUserIDs;
    });
  };

  useEffect(() => {
    setIsAllSelected(
      visibleUsers.length > 0 &&
      visibleUsers.every((user) => selectedUserIDs.includes(user.id))
    );
  }, [selectedUserIDs, visibleUsers]);

  return (
    <div className="quix-users-list">
      <AdminLayout pageTitle={"users"}>
        <div className="quix-users-content">
          <div className="table-management-section">
            <div className="management-header">
              <h4>User Management</h4>
              <p>You can activate, suspend, delete and update a user.</p>
            </div>
            <Filters
              filterList={[
                {
                  id: "all-user",
                  className: "all-user active",
                  title: "All User",
                },
                {
                  id: "suspend-users",
                  className: "suspend-users",
                  title: "Suspended Users",
                },
              ]}
            />
          </div>
          <Statistics
            viewTitle={"View Users reports"}
            viewURl={"/admin/dashboard"}
            statistics={[
              { label: "Total Users", value: `${statisticsData?.total_user}` },
              {
                label: "Active pro plan",
                value: `${statisticsData?.pro_active_plans}`,
              },
              {
                label: "Suspended Users",
                value: `${statisticsData?.total_suspend_user}`,
              },
            ]}
          />
          <FilterContent
            filterContent={[
              {
                id: "all-user",
                className: "show active all-user-tab",
                childrenData: (
                  <Table
                    tableData={usersListData}
                    tableColumns={columns}
                    csvData={userDataCSV}
                    isLoading={isLoading}
                    notFound={notFound}
                    searchCallBack={(e) => handleSearchUsers(e)}
                    resetCallBack={() => handleReset()}
                    setShowItems={(e) => setShowUserData(e)}
                    searchBar={true}
                    tableType={"user"}
                    actionBtn={
                      <>
                        <button className="btn delete-btn">
                          <img src={trashUserIcon} />
                          Delete
                        </button>
                        <button className="btn">
                          <img src={suspendIcon} />
                          Suspend
                        </button>
                      </>
                    }
                  />
                ),
              },
              {
                id: "suspend-users",
                className: "suspend-users-tab",
                childrenData: (
                  <Table
                    tableData={suspendUsersListData}
                    tableColumns={columns}
                    csvData={suspendUserDataCSV}
                    isLoading={isLoadingSuspendUser}
                    notFound={suspendUsersNotFound}
                    searchCallBack={(e) => handleSearchSuspendUsers(e)}
                    resetCallBack={() => handleReset()}
                    searchBar={true}
                    tableType={"suspend-user"}
                  />
                ),
              },
            ]}
          />
        </div>
        {showConfirmModal && (
          <ConfirmationModal
            isShowModal={showConfirmModal}
            closeModal={setShowConfirmModal}
            callBack={handleDeleteUser}
            isLoading={false}
            isDisabled={false}
            icon={trashUserIcon}
            btnText={"Continue"}
            messageTitle={<>Are You Sure to Delete the User?</>}
            description={
              <>
                Proceed with before taking any steps
                <br /> to delete a user account.
              </>
            }
          />
        )}
        {showSuspendModal && (
          <ConfirmationModal
            isShowModal={showSuspendModal}
            closeModal={setShowSuspendModal}
            callBack={handleSuspendUser}
            isLoading={false}
            isDisabled={false}
            icon={suspendUserIcon}
            btnText={"Continue"}
            messageTitle={<>Are You Sure to Suspend the User?</>}
            description={
              <>
                Proceed with before taking any steps
                <br /> to suspend a user account.
              </>
            }
          />
        )}
        {showSuspendActiveModal && (
          <ConfirmationModal
            isShowModal={showSuspendActiveModal}
            closeModal={setShowSuspendActiveModal}
            callBack={handleSuspendUser}
            isLoading={false}
            isDisabled={false}
            icon={suspendUserIcon}
            btnText={"Continue"}
            messageTitle={<>Reactivate User Account?</>}
            description={
              <>
                Are you sure you want to reactivate <br />
                the user account.
              </>
            }
          />
        )}
      </AdminLayout>
    </div>
  );
};

const mapStateToProps = (state) => ({
  usersData: state?.users?.list,
  isLoading: state?.users?.is_loading,
  notFound: state?.users?.not_found,
  suspendUserData: state?.users?.suspend_users_list,
  isLoadingSuspendUser: state?.users?.is_loading_suspend_users,
  suspendUsersNotFound: state?.users?.not_found_suspend_users,
  statistics: state?.admin_statistics?.list,
});

export default connect(mapStateToProps, {
  getAllUserListAdmin,
  getSuspendUserListAdmin,
})(UsersList);
