import React from 'react';
import ocrBnnerImg from '../../../assets/images/home/ocr-new-feaature-bnnr.png';
import closeIcon from '../../../assets/images/home/close-cross-icon.png';

const NewFeaturePopup = ({ isShowModal, closeModal }) => {
    return (
        <div id="ocr-popup">
            <div
                id="quix-dashboard-overlay"
                style={{
                    display: !isShowModal ? "none" : "block",
                }}
            ></div>
            <div className="ocr-popup-modal">
                <div className='ocr-banner-card'>
                    <img className='close-ocr-modal' onClick={()=>closeModal(false)} src={closeIcon}/>
                    <img src={ocrBnnerImg} />
                </div>
            </div>
        </div>
    );
};

export default NewFeaturePopup;