import axios from "axios";
import {
  GET_ALL_MEMBERSHIP,
  ADD_MEMBERSHIP,
  UNSUBSCRIBE_MEMBERSHIP,
} from "./type";
import { API_URL } from "../../utils/config";

// withCredentials
axios.withCredentials = false;

export const getAllMembership = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(`${API_URL}/membership/get`);
      if (res?.data?.status === true) {
        dispatch({
          type: GET_ALL_MEMBERSHIP,
          payload: res?.data,
        });
      }
    } catch (err) {
      console.log(err, "---Error---");
    }
  };
};

export const addMembership = (payload, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: payload?.user_id,
        priceplan_id: payload?.priceplan_id,
        recurrence: false,
        start_date: payload?.start_date,
        end_date: payload?.end_date,
        status: true,
        transaction_id: payload?.transaction_id,
        access_token: payload?.access_token
      });

      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/membership/add_membership`,
        data,
        config
      );

      if (res?.data?.status === true) {
        dispatch({
          type: ADD_MEMBERSHIP,
          payload: res?.data,
        });
        callBack("success", res?.data);
      }
    } catch (err) {
      let message = err?.response?.data;
      callBack("fail", message);
    }
  };
};

export const unsubscribeMembership = (user_id, access_token, callBack) => {
  return async (dispatch) => {
    try {
      let data = JSON.stringify({
        user_id: user_id,
        access_token: access_token,
      });

      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const res = await axios.post(
        `${API_URL}/membership/unsubscribe`,
        data,
        config
      );

      if (res?.data?.status === true) {
        dispatch({
          type: UNSUBSCRIBE_MEMBERSHIP,
          payload: res?.data,
        });
        callBack("success", res?.data);
      }
    } catch (err) {
      let message = err?.response?.data;
      callBack("fail", message);
    }
  };
};
