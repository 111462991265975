import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  addMembership,
  unsubscribeMembership,
} from "../../../redux/actions/manageMembership";
import { getAllPricingPlan } from "../../../redux/actions/managePricingPlan";
import { calculateEndDate, checkServerURL, failureMessagePopup, successMessagePopup } from "../../../helper/helper";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import dummyUserIcon from '../../../assets/images/dumy-user.jpg';

const ProfileDetail = ({ editDetails, editUserDetails }) => {
  const userID = localStorage.getItem(localStoragePrefix + "screenGeniusUserID");
  const tokenAuth = localStorage.getItem(localStoragePrefix + "screenGeniusAuthToken");

  const dispatch = useDispatch();
  let months = 12;
  let stDate = new Date();
  let endDate = calculateEndDate(months);
  const [showUnsubscribeBtn, setShowUnsucscribeBtn] = useState(false);

  useEffect(() => {
    if (
      editDetails?.pricing_plan?.price_string &&
      editDetails?.pricing_plan?.price_string !== "Free"
    ) {
      setShowUnsucscribeBtn(true);
    }
  }, [editUserDetails]);
  const userProfile = !editDetails?.picture ||
    editDetails?.picture === "null" ||
    editDetails?.picture === null
    ? dummyUserIcon
    : checkServerURL(API_URL, `${editDetails?.picture}`);
  // Handles the process of unsubscribing a user from their current membership plan.
  const handleUnsubscribeUser = () => {
    // Dispatches an action to unsubscribe the user using their userID and authentication token.
    dispatch(
      unsubscribeMembership(userID, tokenAuth, (type, res) => {
        if (type === "success") {
          successMessagePopup("Success", "Unsubscribe your active plan!");
          let data = {
            user_id: userID,
            priceplan_id: 3,
            start_date: stDate,
            end_date: endDate,
            transaction_id: "",
            access_token: tokenAuth
          };
          // Dispatches an action to add the new membership plan.
          dispatch(
            addMembership(data, (type, res) => {
              if (type === "success") {
                // Refresh pricing plans
                dispatch(getAllPricingPlan(Number(userID), tokenAuth));
                // Verify user authentication status
                // userAuthChecker(dispatch, tokenAuth);
                setShowUnsucscribeBtn(false);
              } else if (type === 'fail') {
                failureMessagePopup('Error message', res?.message)
              }
            })
          );
        } else if (type === "fail") {
          failureMessagePopup('Error', res?.message)
        }
      })
    );
  };

  return (
    <div>
      <div className="quix-user-edit-details">
        <div className="quix-form">
          <div className="quix-user-profile mb-4">
            <div className="quix-user-profile-box">
              <img
                className="quix-profile-img"
                src={userProfile}
                alt={"profile"}
              />
            </div>
            <div className="quix-profile-user-detail">
              <p className="quix-user-name" title={editDetails?.name}>{editDetails?.name}</p>
              <p
                className="quix-edit-user"
                onClick={() => editUserDetails(true)}
              >
                Edit details
              </p>
            </div>
          </div>
          <div className="mb-3 quix-form-filed">
            <div className="quix-edit-email">
              <input
                type="text"
                name="email"
                className="form-control quix-edit-email"
                id="quix-email"
                aria-describedby="quix-email"
                disabled={true}
                value={editDetails?.email}
                maxLength={255}
              />
            </div>
          </div>
          {/* <div className="quix-user-plan-details">
            {showUnsubscribeBtn && (
              <button
                className="btn d-block quix-subscribe-btn fs-6 w-100 py-2 mb-2"
                onClick={() => handleUnsubscribeUser()}
                title=""
              >
                Unsubscribe your plan
              </button>
            )}
            <p className="d-block m-0">
              Activated Plan :{" "}
              <span className="fw-bold">
                {editDetails?.pricing_plan?.price_string}
              </span>
            </p>
            <p className="d-block m-0">
              Plan Expirated on :{" "}
              <span className="fw-bold text-danger">
                {editDetails?.pricing_plan?.end_date &&
                  dateFormat(
                    editDetails?.pricing_plan?.end_date,
                    "dd/mm/yyyy hh:MM TT"
                  )}
              </span>
            </p>
          </div> */}
          {/* <div className="mb-3 quix-form-filed">
            <input
              type="password"
              name="password"
              className="form-control quix-edit-password"
              id="quix-password"
              disabled={true}
              aria-describedby="quix-password"
              value={"123456"}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default connect(null, { getAllPricingPlan, unsubscribeMembership })(
  ProfileDetail
);
