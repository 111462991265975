export const adminCreateTagSachema = ({ formData, setFormErrors }) => {
    const { name } = formData;
    let valid = true;

    const errors = {
        nameError: "",
    };

    if (!name) {
        errors.nameError = "Tag name cannot be empty!";
        valid = false;
    }

    setFormErrors(errors);
    return valid;
};
