import React, { useState, useEffect } from "react";
import Table from "../../components/Table/Table";
import AdminLayout from "../../layout/adminLayout";
import editIcon from "../../../assets/images/quix-edit-video.png";
import suspendUserIcon from "../../assets/images/block-icon.png";
import TrashIcon from "../../assets/images/trash-icon.png";
import suspendIcon from "../../assets/images/slash-icon.png";
import dateFormat from "dateformat";
import { connect, useDispatch } from "react-redux";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { checkServerURL } from "../../../helper/helper";
import { API_URL, localStoragePrefix } from "../../../utils/config";
import UserProfileCard from "../../components/Cards/UserProfileCard";
import dummyUserIcon from "../../assets/images/dumy-user.jpg";
import EditActionModal from "../../components/Modal/EditActionModal";
import actionIcon from "../../assets/images/action-icon.png";
import {
  errorMessageModal,
  successMessageModal,
} from "../../components/Modal/MessagesModal";
import {
  addTagAdmin,
  deleteTagAdmin,
  editTagAdmin,
  getAllTagsListAdmin,
  getAllTagsListAdminUsers,
  SearchTagsAdmin,
} from "../../../redux/actions/admin/tags";
import Filters from "../../components/Filters/Filters";
import FilterContent from "../../components/Filters/FilterContent";
import CreateTags from "./components/CreateTags";
import EditTags from "./components/EditTags";

const TagsList = ({
  tagsList,
  isLoading,
  notFound,
  isLoadingUsersTags,
  usersTagsList,
  notFoundUsersTags,
}) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tagCardData, setTagCardData] = useState(null);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showSuspendActiveModal, setShowSuspendActiveModal] = useState(false);
  const [getSuspendTagData, setSuspendTagData] = useState([]);
  const [showPredefinedModal, setShowPredefinedModal] = useState(false);
  const [getPreDefinedTagData, setPreDefinedTagData] = useState(null);
  const [itemId, setItemId] = useState(null);
  const [isAllSelected, setIsAllSelected] = useState([]);
  const [selectedItemsIDs, setSelectedItemsIDs] = useState([]);
  const dispatch = useDispatch();

  const userID = localStorage.getItem(
    localStoragePrefix + "screenGeniusUserID"
  );

  useEffect(() => {
    if (userID) {
      dispatch(getAllTagsListAdmin(Number(userID)));
      dispatch(getAllTagsListAdminUsers(Number(userID)));
    }
  }, [userID]);

  const [activeTab, setActiveTab] = useState("admin-tags");

  // get all admin tags
  const tagsData = tagsList?.data ? tagsList?.data : [];
  const userTagsData = usersTagsList?.data ? usersTagsList?.data : [];

  useEffect(() => {
    let firstRowAdmin = tagsData ? tagsData?.[0] : {};
    let firstRowUser = userTagsData ? userTagsData?.[0] : {};
    if (activeTab === "admin-tags") {
      setTagCardData(firstRowAdmin);
    } else {
      setTagCardData(firstRowUser);
    }
  }, [activeTab]);

  const columns = [
    {
      Header: (
        <input
          className="form-check-input check-items"
          type="checkbox"
          onChange={(e) => handleMultiSelectItems(e, "screenshot")}
          checked={isAllSelected ? true : false}
        />
      ),
      accessor: "check",
      disableSortBy: true,
      Cell: (row) => (
        <>
          <input
            className="form-check-input check-items"
            type="checkbox"
            checked={
              selectedItemsIDs?.includes(row?.row?.original?.id) ? true : false
            }
            onChange={(e) =>
              handleChangeSelectedItems(e, row?.row?.original?.id)
            }
          />
        </>
      ),
    },
    { Header: "#", accessor: (row, i) => i + 1 },
    {
      Header: "User name",
      Cell: (row) => (
        <UserProfileCard
          src={
            !row?.row?.original?.userDetails?.picture ||
              row?.row?.original?.userDetails?.picture === "null" ||
              row?.row?.original?.userDetails?.picture === null
              ? dummyUserIcon
              : checkServerURL(
                API_URL,
                row?.row?.original?.userDetails?.picture
              )
          }
          name={row?.row?.original?.userDetails?.name}
        />
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => (value ? value.slice(0, 40) : ""),
    },
    {
      Header: "Create At",
      Cell: (row) => {
        return (
          <span>
            {dateFormat(row?.row?.original?.create_at, "dd/mm/yyyy hh:MM TT")}
          </span>
        );
      },
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="action-cell">
          <div className="edit-action-cell">
            <img className="action-img" src={actionIcon} />
            <EditActionModal>
              {row?.row?.original.status === "1" ? (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    setShowSuspendModal(!showSuspendModal);
                    setSuspendTagData(row?.row?.original);
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Suspend Tag</span>
                </div>
              ) : (
                <div
                  className="column-action gap-2"
                  onClick={() => {
                    setShowSuspendActiveModal(!showSuspendActiveModal);
                    setSuspendTagData(row?.row?.original);
                    setItemId(row?.row?.original?.id);
                  }}
                >
                  <img src={suspendIcon} />
                  <span>Activate Tag</span>
                </div>
              )}
              <div
                className="column-action gap-2"
                disabled={loading}
                onClick={() => {
                  setTagCardData(row?.row?.original);
                }}
              >
                <img src={editIcon} />
                <span>Edit Tag</span>
              </div>
              <div
                className="column-action delete-action gap-2"
                disabled={loading}
                onClick={() => {
                  setShowConfirmModal(!showConfirmModal);
                  setItemId(row?.row?.original?.id);
                }}
              >
                <img src={TrashIcon} />
                <span>Delete Tag</span>
              </div>
            </EditActionModal>
          </div>
        </div>
      ),
    },
  ];

  const UserColumns = [
    {
      Header: (
        <input
          className="form-check-input check-items"
          type="checkbox"
          onChange={(e) => handleMultiSelectItems(e, "screenshot")}
          checked={isAllSelected ? true : false}
        />
      ),
      accessor: "check",
      disableSortBy: true,
      Cell: (row) => (
        <>
          <input
            className="form-check-input check-items"
            type="checkbox"
            checked={
              selectedItemsIDs?.includes(row?.row?.original?.id) ? true : false
            }
            onChange={(e) =>
              handleChangeSelectedItems(e, row?.row?.original?.id)
            }
          />
        </>
      ),
    },
    { Header: "#", accessor: (row, i) => i + 1 },
    {
      Header: "User name",
      Cell: (row) => (
        <UserProfileCard
          src={
            !row?.row?.original?.userDetails?.picture ||
              row?.row?.original?.userDetails?.picture === "null" ||
              row?.row?.original?.userDetails?.picture === null
              ? dummyUserIcon
              : checkServerURL(
                API_URL,
                row?.row?.original?.userDetails?.picture
              )
          }
          name={row?.row?.original?.userDetails?.name}
        />
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ value }) => (value ? value.slice(0, 40) : ""),
    },
    {
      Header: "Create At",
      Cell: (row) => {
        return (
          <span>
            {dateFormat(row?.row?.original?.create_at, "dd/mm/yyyy hh:MM TT")}
          </span>
        );
      },
    },
    {
      Header: "Action",
      Cell: (row) => (
        <div className="action-cell">
          <div className="edit-action-cell">
            <img className="action-img" src={actionIcon} />
            <EditActionModal>
              <div
                className="column-action gap-2"
                disabled={loading}
                onClick={() => {
                  setShowPredefinedModal(!showPredefinedModal);
                  setPreDefinedTagData(row?.row?.original);
                }}
              >
                <span>Mark as Admin Tag</span>
              </div>
              <div
                className="column-action gap-2"
                disabled={loading}
                onClick={() => {
                  setTagCardData(row?.row?.original);
                }}
              >
                <img src={editIcon} />
                <span>Edit Tag</span>
              </div>
              <div
                className="column-action delete-action gap-2"
                disabled={loading}
                onClick={() => {
                  setShowConfirmModal(!showConfirmModal);
                  setItemId(row?.row?.original?.id);
                }}
              >
                <img src={TrashIcon} />
                <span>Delete Tag</span>
              </div>
            </EditActionModal>
          </div>
        </div>
      ),
    },
  ];

  const [showItemData, setShowItemData] = useState([]);
  const visibleItems = showItemData && showItemData;
  // handle multi select screenshot
  const handleMultiSelectItems = (e, type) => {
    let checked = e.target.checked;
    if (checked) {
      let newSelectedIDs = visibleItems.map((user) => user.id);
      setSelectedItemsIDs((prevIDs) => [
        ...new Set([...prevIDs, ...newSelectedIDs]),
      ]);
    } else {
      setSelectedItemsIDs([]);
    }
  };

  // handle single select screenshot
  const handleChangeSelectedItems = (e, id, type) => {
    const checked = e.target.checked;
    setSelectedItemsIDs((prevSelectedItemsIDs) => {
      if (checked) {
        if (!prevSelectedItemsIDs.includes(id)) {
          return [...prevSelectedItemsIDs, id];
        }
      } else {
        return prevSelectedItemsIDs.filter((item) => item !== id);
      }
      return prevSelectedItemsIDs;
    });
  };

  const [loading, setLoading] = useState(false);
  const handleDeleteTags = (action) => {
    if (itemId !== null && action === true) {
      setLoading(true);
      const payload = {
        id: itemId,
        admin_id: Number(userID),
      };
      dispatch(
        deleteTagAdmin(payload, (type, res) => {
          if (type === "success") {
            setLoading(false);
            setShowConfirmModal(false);
            successMessageModal(
              "Tag Successfully Deleted.",
              "Tag has been permanently deleted."
            );
            dispatch(getAllTagsListAdmin(Number(userID)));
            dispatch(getAllTagsListAdminUsers(Number(userID)));
          } else if (type === "fail") {
            setLoading(false);
            setShowConfirmModal(false);
            errorMessageModal("Error Message", res?.message);
          }
        })
      );
    }
  };

  useEffect(() => {
    setIsAllSelected(
      visibleItems.length > 0 &&
      visibleItems.every((user) => selectedItemsIDs.includes(user.id))
    );
  }, [selectedItemsIDs, visibleItems]);

  // handle search all tags
  const handleSearchTags = (data) => {
    if (data) {
      const payload = {
        text: data,
        admin_id: Number(userID),
        query: "admin",
      };
      dispatch(SearchTagsAdmin(payload));
    }
  };

  // handle reset all users and suspend users
  const handleReset = () => {
    dispatch(getAllTagsListAdmin(Number(userID)));
  };

  // handle search all tags
  const handleSearchUsersTags = (data) => {
    if (data) {
      const payload = {
        text: data,
        admin_id: Number(userID),
        query: "user",
      };
      dispatch(SearchTagsAdmin(payload));
    }
  };

  // handle reset all users and suspend users
  const handleResetUsersTags = () => {
    dispatch(getAllTagsListAdminUsers(Number(userID)));
  };
  // handle search all users csv data
  const tagsDataCSVFormate =
    tagsData &&
    tagsData?.map((item) => {
      return {
        ID: item?.id ? item?.id : "N/A",
        User_ID: item?.user_id ? item?.user_id : "N/A",
        Name: item?.userDetails?.name ? item?.userDetails?.name : "N/A",
        Email: item?.userDetails?.email ? item?.userDetails?.email : "N/A",
        Picture: item?.userDetails?.picture
          ? checkServerURL(API_URL, item?.userDetails?.picture)
          : "N/A",
        Tag_Name: item?.name ? item?.name : "N/A",
        Created_at: item?.created_at,
        Updated_at: item?.updated_at,
      };
    });

  let tagsDataCSV = { filename: "admin-tags-list", data: tagsDataCSVFormate };
  //
  const usersTagsDataCSVFormate =
    userTagsData &&
    userTagsData?.map((item) => {
      return {
        ID: item?.id ? item?.id : "N/A",
        User_ID: item?.user_id ? item?.user_id : "N/A",
        Name: item?.userDetails?.name ? item?.userDetails?.name : "N/A",
        Email: item?.userDetails?.email ? item?.userDetails?.email : "N/A",
        Picture: item?.userDetails?.picture
          ? checkServerURL(API_URL, item?.userDetails?.picture)
          : "N/A",
        Tag_Name: item?.name ? item?.name : "N/A",
        Created_at: item?.created_at,
        Updated_at: item?.updated_at,
      };
    });

  let usersTagsDataCSV = {
    filename: "users-tags-list",
    data: usersTagsDataCSVFormate,
  };

  const handleSuspendTag = (res) => {
    if (res) {
      const payload = {
        user_id: getSuspendTagData?.user_id,
        admin_id: Number(userID),
        id: getSuspendTagData?.id,
        status: getSuspendTagData?.status === "1" ? "0" : "1",
      };
      setLoading(true);
      dispatch(
        editTagAdmin(payload, (type, res) => {
          if (type === "success") {
            if (res?.response?.[0]?.status === "1") {
              successMessageModal(
                "Tag Reactivated.",
                "Successful tag reactivated."
              );
            } else {
              successMessageModal(
                "Tag suspend successful.",
                "Successful suspend a tag."
              );
            }
            setLoading(false);
            setShowSuspendModal(false);
            setShowSuspendActiveModal(false);
            dispatch(getAllTagsListAdmin(Number(userID)));
          } else if (type === "failed") {
            errorMessageModal("Error Message", res?.message);
            setLoading(false);
            setShowSuspendModal(false);
            setShowSuspendActiveModal(false);
          }
        })
      );
    }
  };

  const handleMoveToPreDefinedTag = (res) => {
    if (res) {
      const payload = {
        user_id: Number(userID),
        admin_id: Number(userID),
        id: getPreDefinedTagData?.id,
      };

      dispatch(
        editTagAdmin(payload, (type, res) => {
          if (type === "success") {
            successMessageModal(
              "Predefined Tag Successful",
              "Successful predefined a tag."
            );
            dispatch(getAllTagsListAdmin(Number(userID)));
            setShowPredefinedModal(false);
          } else if (type === "failed") {
            setShowPredefinedModal(false);
            errorMessageModal("Error Message", res?.message);
          }
        })
      );
    }
  };

  return (
    <AdminLayout pageTitle={"tags"}>
      <div className="quix-screenshot-content">
        <div className="table-management-section">
          <div className="management-header mb-5">
            <h4>Tags Management</h4>
            <p>You can manage tags, can be create new tag and edit the tag.</p>
          </div>
          <Filters
            filterList={[
              {
                id: "admin-tags",
                className: "admin-tags active",
                title: "Admin Tags",
                onClick: () => setActiveTab("admin-tags"),
              },
              {
                id: "users-tags",
                className: "users-tags",
                title: "Users Tags",
                onClick: () => setActiveTab("users-tags"),
              },
            ]}
          />
        </div>
        <div className="row">
          <div className="col-md-8 mb-3">
            <FilterContent
              filterContent={[
                {
                  id: "admin-tags",
                  className: "show active admin-tags-tab",
                  childrenData: (
                    <Table
                      tableData={tagsData}
                      tableColumns={columns}
                      setShowItems={(e) => setShowItemData(e)}
                      searchBar={true}
                      isLoading={isLoading}
                      notFound={notFound}
                      searchCallBack={(res) => handleSearchTags(res)}
                      resetCallBack={() => handleReset()}
                      csvData={tagsDataCSV}
                      tableType={"tag"}
                    />
                  ),
                },
                {
                  id: "users-tags",
                  className: "users-tags-tab",
                  childrenData: (
                    <Table
                      tableData={userTagsData}
                      tableColumns={UserColumns}
                      setShowItems={(e) => setShowItemData(e)}
                      searchBar={true}
                      isLoading={isLoadingUsersTags}
                      notFound={notFoundUsersTags}
                      searchCallBack={(res) => handleSearchUsersTags(res)}
                      resetCallBack={() => handleResetUsersTags()}
                      csvData={usersTagsDataCSV}
                      tableType={"tag"}
                    />
                  ),
                },
              ]}
            />
          </div>
          <div className="col-md-4">
            {activeTab === "admin-tags" && <CreateTags />}
            <EditTags cardData={tagCardData} />
          </div>
        </div>

        {showConfirmModal && (
          <ConfirmationModal
            isShowModal={showConfirmModal}
            closeModal={setShowConfirmModal}
            callBack={(res) => handleDeleteTags(res)}
            isLoading={loading}
            isDisabled={loading}
            title={"Confirm Deletion"}
            btnText={"Delete"}
            messageTitle={
              <>
                Are you sure you want to <br /> permanently delete this Tag?
              </>
            }
          />
        )}
        {showSuspendModal && (
          <ConfirmationModal
            isShowModal={showSuspendModal}
            closeModal={setShowSuspendModal}
            callBack={handleSuspendTag}
            isLoading={false}
            isDisabled={false}
            icon={suspendUserIcon}
            btnText={"Continue"}
            messageTitle={<>Are You Sure to Suspend the Tag?</>}
            description={
              <>
                Proceed with before taking any steps
                <br /> to suspend a Tag.
              </>
            }
          />
        )}
        {showSuspendActiveModal && (
          <ConfirmationModal
            isShowModal={showSuspendActiveModal}
            closeModal={setShowSuspendActiveModal}
            callBack={handleSuspendTag}
            isLoading={false}
            isDisabled={false}
            icon={suspendUserIcon}
            btnText={"Continue"}
            messageTitle={<>Reactivate Tag?</>}
            description={
              <>
                Are you sure you want to reactivate <br />
                the Tag.
              </>
            }
          />
        )}
        {showPredefinedModal && (
          <ConfirmationModal
            isShowModal={showPredefinedModal}
            closeModal={setShowPredefinedModal}
            callBack={handleMoveToPreDefinedTag}
            isLoading={false}
            isDisabled={false}
            icon={suspendUserIcon}
            btnText={"Continue"}
            messageTitle={<>Mark as Admin Tag?</>}
            description={
              <>
                Are you sure you want to <br />
                mark as admin Tag.
              </>
            }
          />
        )}
      </div>
    </AdminLayout>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state?.admin_tags?.is_loading,
  tagsList: state?.admin_tags?.list,
  notFound: state?.admin_tags?.not_found,
  //
  isLoadingUsersTags: state?.admin_tags?.is_loading_users_tags,
  usersTagsList: state?.admin_tags?.users_tags_list,
  notFoundUsersTags: state?.admin_tags?.not_found_users_tags,
});

export default connect(mapStateToProps, { getAllTagsListAdmin, addTagAdmin })(
  TagsList
);
