import React from "react";
import "./style.css";
import TopBar from "../../components/TopBar/TopBar";
import Footer from "../../components/Footer/Footer";

const PrivacyPolicy = () => {
    return (
        <div className="wrapper">
            <TopBar />
            <div className="quixy-privacy-policy">
                <div className="container">
                    <div className="page-content">
                        <div className="page-heading">
                            <h1 className="main-heading">Privacy Policy</h1>
                            <p className="heading-text">
                                This document outlines our guidelines and procedures regarding
                                the collection, utilization, and disclosure of your information
                                while utilizing the Service. It also delineates your privacy
                                rights and the legal protections in place for you.
                            </p>
                        </div>
                        <div className="page-terms-and-descriptions">
                            <h2 className="page-terms-heading">
                                Interpretation and Definitions
                            </h2>
                            <h3 className="page-terms-sub-heading">Interpretation</h3>
                            <p className="page-terms-description">
                                Terms capitalized in this document hold specific meanings as
                                outlined below, regardless of whether they appear in singular or
                                plural form.
                            </p>
                            <h3 className="page-terms-sub-heading">Definitions</h3>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    For the purposes of this Privacy Policy:
                                </p>
                                <p className="page-terms-description">
                                    Account: A unique account established for accessing our
                                    Service or its components.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Application: The software program, namely Capios,
                                    provided by the Company and downloaded by users on electronic
                                    devices.
                                </p>
                            </div>
                            <div className="page-terms-description-column"></div>
                            <p className="page-terms-description">
                                Company: Referred to as "the Company," "We," "Us," or "Our" in
                                this document, refers to Vivid Minds Technologies Private
                                Limited.
                            </p>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Device: Any device capable of accessing the Service, such as
                                    computers, cellphones, or digital tablets.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Personal Data: Information related to an identified or
                                    identifiable individual.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Service: Refers to the Application.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Service Provider: A natural or legal person processing data on
                                    behalf of the Company.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Usage Data: Data collected automatically, either through
                                    Service use or the Service infrastructure itself.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    You: The individual accessing or using the Service, or the
                                    entity on whose behalf such individual is accessing or using
                                    the Service.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">
                                Collecting and Using Your Personal Data
                            </h2>
                            <h3 className="page-terms-sub-heading">
                                Types of Data Collected
                            </h3>
                            <h3 className="page-terms-sub-heading">Personal Data</h3>
                            <p className="page-terms-description">
                                Personal Data: While using Our Service, We may request
                                personally identifiable information, including but not limited
                                to:
                            </p>
                            <h3 className="page-terms-sub-heading">Usage Data</h3>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Automatically collected data during Service use, including
                                    browser type, version, visited pages, visit duration, unique
                                    device identifiers, and other diagnostic data.
                                </p>
                                <p className="page-terms-description">
                                    When accessing the Service via MacOS, certain information such
                                    as operating system details, app versions, screen recording
                                    data, and other diagnostic data may be collected.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">Use of Your Personal Data</h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Use of Your Personal Data
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    The Company may employ Personal Data for various purposes,
                                    including:
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Maintaining and monitoring the Service's usage.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Managing your Account and providing access to Service
                                    functionalities.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Performing contractual obligations, including purchase
                                    contracts for products or services.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Contacting you for updates, informative communications, or
                                    security-related notifications.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Addressing and managing your requests.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Analysing data, identifying usage trends, evaluating
                                    promotional campaigns, and enhancing Service quality and user
                                    experience.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Sharing Your Personal Information
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Your personal information may be shared in the following
                                    scenarios:
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    With Service Providers: To monitor and analyze Service usage
                                    or to contact you.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    With other users: In public interactions, shared information
                                    may be viewed and distributed.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    With Your consent: Personal information may be disclosed for
                                    other purposes with your explicit consent.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Third-Party Services: You have the option to enable or
                                    restrict Third-Party Services. These services may integrate
                                    with ours, sharing information to facilitate integration. You
                                    should review the privacy settings and notices of such
                                    services for understanding their data practices.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">
                                Retention of Your Personal Data
                            </h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    The Company retains Personal Data only as long as necessary to
                                    fulfil the purposes outlined in this Privacy Policy. We may
                                    transfer and process your information in locations where data
                                    protection laws differ from your jurisdiction, ensuring
                                    adequate controls are in place.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">
                                Transfer of Your Personal Data
                            </h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Your information, including Personal Data, is processed at the
                                    Company's operating offices and in any other places where the
                                    parties involved in the processing are located. It means that
                                    this information may be transferred to — and maintained on —
                                    computers located outside of Your state, province, country or
                                    other governmental jurisdiction where the data protection laws
                                    may differ than those from Your jurisdiction.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Your consent to this Privacy Policy followed by your
                                    submission of such information represents your agreement to
                                    that transfer.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    The Company will take all steps reasonably necessary to ensure
                                    that Your data is treated securely and in accordance with this
                                    Privacy Policy and no transfer of Your Personal Data will take
                                    place to an organization or a country unless there are
                                    adequate controls in place including the security of Your data
                                    and other personal information.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">Delete Your Personal Data</h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    You have the right to request deletion of Personal Data
                                    collected by us. You can update or delete your information via
                                    your Account settings or by contacting us directly. However,
                                    certain information may be retained due to legal obligations
                                    or lawful bases.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">
                                Disclosure of Your Personal Data
                            </h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Your Personal Data may be disclosed in certain situations,
                                    including business transactions, legal obligations, or
                                    safeguarding user rights, property, or safety.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">
                                Comply with a legal obligation
                            </h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Ensure the safeguarding of the Company's rights or assets.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Take measures to prevent or investigate any potential
                                    misconduct related to the Service.
                                </p>
                            </div>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Safeguard the personal safety of Service Users or the general
                                    public. Mitigate legal risks and liabilities.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">
                                Security of Your Personal Data
                            </h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    While we endeavor to secure your Personal Data, we cannot
                                    guarantee absolute security due to inherent risks in Internet
                                    transmission and electronic storage.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">Children's Privacy</h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    Our Service is not directed at children under 13, and we do
                                    not knowingly collect their personal information without
                                    parental consent.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">Links to Other Websites</h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    We are not responsible for the privacy practices of
                                    third-party websites linked to our Service. Users should
                                    review the privacy policies of these sites.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">
                                Changes to this Privacy Policy
                            </h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    This Privacy Policy may be updated periodically. Users will be
                                    notified of changes via the Service. It is advisable to review
                                    this policy periodically.
                                </p>
                            </div>
                            <h2 className="page-terms-heading">Contact Us</h2>
                            <div className="page-terms-description-column">
                                <p className="page-terms-description">
                                    For queries regarding this Privacy Policy, contact us via
                                    email at
                                    <a className="page-email-link" href="mailto:support@capios.ai">
                                        support@capios.ai
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
