import React from "react";
import loaderIcon from "../../assets/images/light-loader.gif";

const LoadingPage = () => {
    return (
        <div className="quix-loading-wrapper">
            <div className="quix-loading-page">
                <div className="quix-loading-spinner">
                    <img src={loaderIcon} alt="loading..." />
                </div>
            </div>
        </div>
    );
};

export default LoadingPage;