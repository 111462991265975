import React, { useState } from "react";
import uninsatllImg from "../../assets/images/home/ocr-uninstall-image.png";
import logoImg from "../../assets/images/home/ocr-logo.png";
import lightLoader from "../../assets/images/light-loader.gif";
import { sendFeedBack } from "../../redux/actions/manageScreenshots";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { failureMessagePopup, successMessagePopup } from "../../helper/helper";

const Unintall = () => {
  const dispatch = useDispatch();
  const [formErrors, setFormErrors] = useState("");
  const [formData, setFormdata] = useState({
    senderEmail: "",
    whyUninstall: "",
    otherTool: "",
    whatToImprove: "",
    userMessage: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const unInstallSchema = ({ formData, setFormErrors }) => {
    const { senderEmail, whyUninstall, otherTool, whatToImprove, userMessage } =
      formData;

    let regex =
      /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let valid = true;

    const errors = {
      emailError: "",
      feedBackError: "",
    };

    if (!senderEmail) {
      errors.emailError = "Email cannot be empty!";
      valid = false;
    } else if (!regex.test(senderEmail)) {
      errors.emailError = "Invalid email address!";
      valid = false;
    }
    if (
      whyUninstall.trim("") === "" &&
      whyUninstall.trim("") === "" &&
      otherTool.trim("") === "" &&
      whatToImprove.trim("") === "" &&
      userMessage.trim("") === ""
    ) {
      errors.feedBackError = "You must have to fill at least one field!";
      valid = false;
    }
    setFormErrors(errors);
    return valid;
  };

  const handleSubmit = () => {
    if (unInstallSchema({ formData, setFormErrors })) {
      dispatch(
        sendFeedBack(formData, (type, res) => {
          if (type === "success") {
            successMessagePopup(
              "Submited Successfully!",
              "Thank you for your feedback! It has been successfully submitted."
            );
          } else if (type === "failed") {
            failureMessagePopup("Error Message!", "Something went wrong.");
          }
        })
      );
    }
  };

  return (
    <div className="uninstall-page" id="root">
      <div className="header_section">
        <div className="container top_container">
          <header className="ScreenGenius-header">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid justify-content-center">
                <Link className="navbar-brand" to="/">
                  <img src={logoImg} height="80" alt="CoolBrand" />
                </Link>
              </div>
            </nav>
          </header>
          <div className="container-fluid">
            <div className="row">
              <div className="star_emoji col-sm-6 mx-auto pt-3">
                <span className="thankEmoji">
                  <span className="emoji">
                    <img src={uninsatllImg} />
                    <span className="emoji_content">
                      <div className="section_title">
                        <h2>
                          Capios{" "}
                          <span className="heading-badge-text">
                            successfully uninstalled
                          </span>{" "}
                          from your device now!
                        </h2>
                        <p>
                          Your experience matters to us. Would you mind taking
                          few minutes to share your feedback on our product?
                        </p>
                      </div>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="screen_recording_game">
        <div className="row m-0">
          <div className="conversations-mid extension_screen rounded-0 py-5">
            <div className="col-lg-8 mx-auto">
              <div
                className="section_title"
                style={{
                  textAlign: "center",
                  marginTop: "5%",
                  marginBottom: "2%",
                }}
              >
                <h5>Share your feedback with us</h5>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 text-start">
                  <label htmlFor="inputEmail4">Email Address</label>
                  <input
                    className="form-control mt-2"
                    id="inputEmail4"
                    type="email"
                    name="senderEmail"
                    placeholder="Enter"
                    defaultValue={formData?.senderEmail}
                    onChange={(e) => handleChange(e)}
                    maxLength={255}
                  />
                  {formErrors?.emailError && (
                    <span className="text-danger account-dec d-flex">
                      {formErrors?.emailError}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 text-start">
                  <label htmlFor="inputEmail4">
                    Why did you uninstall Capios?
                  </label>
                  <textarea
                    className="form-control mt-2"
                    id="inputEmail4"
                    type="text"
                    name="whyUninstall"
                    placeholder="Enter"
                    rows={5}
                    defaultValue={formData?.whyUninstall}
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                </div>
                <div className="form-group col-md-6 text-start">
                  <label htmlFor="inputPassword4">
                    Are you using any other tool instead of Capios?
                  </label>
                  <textarea
                    className="form-control mt-2"
                    id="inputPassword4"
                    type="text"
                    name="otherTool"
                    placeholder="Enter"
                    rows={5}
                    defaultValue={formData?.otherTool}
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-12 text-start">
                  <label htmlFor="inputEmail4">
                    What can we do to improve Capios?
                  </label>
                  <textarea
                    className="form-control mt-2"
                    id="inputEmail4"
                    type="text"
                    name="whatToImprove"
                    rows={5}
                    placeholder="Enter"
                    defaultValue={formData?.whatToImprove}
                    onChange={(e) => handleChange(e)}
                  ></textarea>
                  {formErrors?.feedBackError && (
                    <span className="text-danger account-dec d-flex">
                      {formErrors?.feedBackError}
                    </span>
                  )}
                </div>
              </div>
              <div className="btn_section_row">
                <img className="loader-image" src={lightLoader} />
                <button
                  className="btn btn-primary btn_cancel_submit submitbtn"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-12 thank-you-screen">
            <div
              className="thank-you-screen-title"
              style={{
                textAlign: "center",
                marginTop: "5%",
                marginBottom: "5%",
              }}
            >
              <span>Your Feedback is received successfully.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unintall;
