import React, { useState, useEffect, useRef } from "react";
import dropDownIcon from "../../assets/images/chevron-down-icon.svg";

const CustomSelect = ({ dropDownTitle, dropDownOptions, handleActions }) => {
  const [showDropMenu, setSowDropMenu] = useState(true);
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setSowDropMenu(true);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className="sg-custom-drop-menu"
      ref={dropdownRef}
      onClick={() => setSowDropMenu(!showDropMenu)}
    >
      <div className="sg-custom-drop-title">
        <span>{dropDownTitle}</span>
        <img id="sg-drop-icon" src={dropDownIcon} />
      </div>
      {!showDropMenu && (
        <div className="sg-drop-menu-options">
          <ul className="nav" style={{display:'block'}}>
            {dropDownOptions &&
              dropDownOptions?.map((opt,ind) => {
                return (
                  <li
                    key={ind}
                    className="nav-items"
                    onClick={() => handleActions(opt)}
                  >
                    {opt}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
